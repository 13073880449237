<template> 
    <div>

        <v-card v-if="homeData.enrollments_count === 0" class="soft-shadow mb-3">
            <v-card-text class="pa-3">
                <v-alert small text type="info" icon="mdi-book-play" color="success" class="px-3 mb-0">
                    <div>
                        <b class="text--darken-1 success--text">Account created successfully. </b>
                        <div class="medium-text font-weight-medium">
                            <div class="strong-text--text">
                                Now you should enroll in a course to get 2 FREE TRIAL classes.
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <v-btn depressed color="success" style="text-transform: none" :to="{name: 'courses'}">
                            <v-icon left>mdi-view-grid-outline</v-icon>
                            <b>Discover Courses</b>
                            <v-icon right size="18" class="ms-1" style="opacity: 0.5">mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>
                </v-alert>
            </v-card-text>
        </v-card>

        <!-- Header Slider -->
        <div v-if="slideImages && slideImages.length" class="mb-4 mb-lg-10">
            <v-carousel :show-arrows-on-hover="slideImages.length > 1" cycle :height="pcOnly ? 450 : 150" :show-arrows="slideImages.length > 1" :continuous="true" class="header-carousel" :hide-delimiters="slideImages.length <= 1" :class="{'bordered': (!slideImages || !slideImages.length)}">
                <template v-slot:prev="{ on, attrs }">
                    <v-btn large fab v-bind="attrs" v-on="on" class="transparent" depressed>
                        <v-icon large>mdi-chevron-right</v-icon>
                    </v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                    <v-btn large fab v-bind="attrs" v-on="on" class="transparent" depressed>
                        <v-icon large>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <v-carousel-item v-for="(img,i) in slideImages" :key="i">
                    <a v-if="img.href" :href="img.href" :target="capRunning ? '_system' : '_blank'">
                        <div :style="`width: 100%; height: 100%; background-image: url(${img.url}); background-size: cover; background-position: center;`" class="p-relative">
                            <v-btn fab :small="pcOnly" :x-small="phoneOnly" absolute top right depressed  style="top: 10px; background-color: rgba(0,0,0, .25);">
                                <v-icon>mdi-open-in-new</v-icon>
                            </v-btn>
                        </div>
                    </a>
                    <div v-else :style="`width: 100%; height: 100%; background-image: url(${img.url}); background-size: cover; background-position: center;`" />
                    <!-- <v-img :src="img.url" center contain></v-img> -->
                </v-carousel-item>
            </v-carousel>
        </div>

        
        <!-- Home Content -->

        <v-row>
            <v-col class="py-0" cols="12" lg="7">
                <v-card class="soft-shadow px-lg-2 py-lg-1 mb-3">
                    <v-card-title class="d-flex justify-space-between">
                        <div>Upcoming Class</div>
                        <div>
                            <v-btn :icon="phoneOnly" color="blue-grey darken-2" :loading="loading" :disabled="loading" :outlined="pcOnly" class="me-2" @click="$emit('load')">
                                <v-icon>mdi-reload</v-icon>
                            </v-btn>
                            <v-btn :icon="phoneOnly" color="blue-grey darken-2" :outlined="pcOnly" :to="{name: 'calendar'}" depressed>
                                <v-icon size="22" :left="pcOnly">mdi-calendar</v-icon>
                                <span v-if="pcOnly">Calendar</span>
                            </v-btn>
                        </div>
                    </v-card-title>
                    <v-card-subtitle v-if="pcOnly" class="mb-2">Open calendar to view more classes</v-card-subtitle>
                    <v-card-text>
                        <v-alert v-if="!nextClass" text color="blue-grey" type="info" :dense="phoneOnly">
                            <div :class="{'details-text': phoneOnly}">
                                It seems that you don't have classes...
                            </div>
                        </v-alert>

                        <upcoming-class-card v-else :class-data="nextClass" :reloading="loading" />
                    </v-card-text>
                </v-card>

            </v-col>
            <v-col class="py-0" cols="12" lg="5">
                <v-card v-if="appData.enable_app_w" class="soft-shadow px-2 py-1 mb-3">
                    <v-card-text>
                        <v-alert v-if="homeData.low_credits_alert" dense small text type="error" color="orange darken-2" class="px-3">
                            <div class="details-text font-weight-medium">
                                <b>Low Balance Alert:</b>
                                <br>
                                Please recharge as soon as possible...
                            </div>
                        </v-alert>

                        <div class="d-flex justify-space-between align-center w-full">

                            <div class="d-flex align-center">
                                <v-avatar size="60">
                                    <v-icon size="28" color="orange" class="orange lighten-5">mdi-wallet</v-icon>
                                </v-avatar>
                                <div class="ms-4">
                                    <div class="mb-1">Wallet Balance</div>
                                    <h2 class="strong-text--text font-weight-bold">{{moneyFormat(user.credits)}}</h2>
                                </div>
                            </div>
                            <div>
                                <v-btn outlined color="orange darken-1" dark depressed :to="{name: 'wallet'}">
                                    <v-icon size="22" :left="pcOnly">mdi-wallet</v-icon>
                                    <span v-if="pcOnly">Wallet</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>

                <v-card class="soft-shadow px-2 py-1 mb-3">
                    <v-card-text>
                        <div class="d-flex justify-space-between align-center w-full">

                            <div class="d-flex align-center">
                                <v-avatar size="60">
                                    <v-icon size="28" color="green" class="green lighten-5">mdi-book-play</v-icon>
                                </v-avatar>
                                <div class="ms-4">
                                    <div class="mb-1">My Courses</div>
                                    <h2 class="strong-text--text font-weight-bold">{{user.my_courses ? user.my_courses.length : 0}}</h2>
                                </div>
                            </div>
                            <div>
                                <v-btn color="green darken-1" outlined dark depressed :to="{name: 'my-courses'}">
                                    <v-icon size="22" :left="pcOnly">mdi-book-play</v-icon>
                                    <span v-if="pcOnly">My Courses</span>
                                </v-btn>
                            </div>
                    </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12">
                <v-card class="soft-shadow px-2 py-1 mb-3">
                    <v-card-title>
                        <v-icon left color="teal darken-2">mdi-calendar-clock</v-icon>
                        Reschedule Requests
                        <span v-if="rescheduleRequests.length" class="teal--text text--darken-2 ms-2">({{rescheduleRequests.length}})</span>
                    </v-card-title>
                    <!-- <v-card-subtitle class="mb-2">Open calendar to view more classes</v-card-subtitle> -->
                    <v-card-text :class="{'px-0': phoneOnly && rescheduleRequests.length}">
                        <v-alert v-if="!rescheduleRequests.length" text color="blue-grey" type="info" :dense="phoneOnly">
                            <div :class="{'details-text': phoneOnly}">
                                You don't have pending reschedule requests...
                            </div>
                        </v-alert>
                        <div v-else>
                            <reschedule-requests :requests="rescheduleRequests" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>


            <v-col cols="12">
                <v-card :flat="phoneOnly" class="px-lg-2 py-1 mb-lg-3" :class="{'transparent': phoneOnly, 'soft-shadow': pcOnly}">
                    <v-card-title>Featured Courses</v-card-title>
                    <v-card-text :class="{'px-0 pb-0': phoneOnly}">
                        <v-row v-if="homeData.featured_courses && homeData.featured_courses.length" :key="0">
                            <v-col v-for="course in homeData.featured_courses" :key="course.id" :cols="12" :md="6" :lg="4" :xl="3">
                                <course-card :course="course" style="height: 100%"/>
                            </v-col>
                            <v-col cols="12">
                                <v-btn v-if="phoneOnly" block :color="capRunning ? 'primary' : 'white'" :to="{name: 'courses'}" :depressed="capRunning">
                                    <v-icon size="20" left>mdi-view-grid</v-icon>
                                    Course Catalog
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-alert v-else text color="blue-grey" type="info">
                            No featured courses to show
                        </v-alert>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CourseCard from '@/components/custom/course-card'
import UpcomingClassCard from '@/components/custom/upcoming-class-card'
import RescheduleRequests from './reschedule-requests'

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    props: {
        loading: {type: Boolean, default: false}
    },
    data(){
        return {
            interval: null,
        }
    },
    components: {
        UpcomingClassCard,
        CourseCard,
        RescheduleRequests
    },
    computed: {
        ...GlobalComputed,
        homeData(){
            return this.$store.state.userModule.homeData;
        },
        nextClass(){
            return this.homeData ? this.homeData.next_class : null;
        },
        rescheduleRequests(){
            return this.homeData ? this.homeData.reschedule_requests : null;
        },
        slideImages(){
            return this.homeData ? this.homeData.slide_images : [];
        }
    },
    methods: {
        ...GlobalMethods
    },
    created(){
        const timeout = this.appData.home_reload_timeout || 10000;
        this.interval = window.setInterval(()=>{
            this.$emit('load');
        }, timeout)
    },
    beforeDestroy(){
        window.clearInterval(this.interval);
    }
}
</script>

<style lang="scss" scoped>
    .header-carousel{
        border-radius: 5px;
        border: 1px solid rgba(#000, 0.1);

        @media(min-width: 768px){
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25) !important;
            border-radius: 13px;
        }
    }
</style>
<style lang="scss">
.v-window__prev, .v-window__next{
    background: none!important;
}
.v-carousel__controls{
    background: none!important;
    .v-carousel__controls__item{
        margin: 0 2px!important;

        .v-icon.mdi-circle{
            font-size: 15px!important;
        }
    }
}
</style>
