export default {
    state: {
        reports: null,
        reportsNext: undefined,
    },
    mutations: {
        SET_REPORTS(state, payload){
            state.reports = payload.data;
            state.reportsNext = payload.next;
        },
        ADD_TO_REPORTS(state, payload){
            state.reports.push(...payload.data);
            state.reportsNext = payload.next;
        },
        ADD_REPORTS(state, payload){
            state.reports.push(...payload.data);
        },
        UPDATE_REPORT(state, data){
            state.reports = state.reports.map(item=>{
                if (item.id === data.id){
                    Object.assign(item, data);
                }
                return item;
            });
        },
    },
    actions: {
        loadClassReports({state, commit}, payload){
            const next = state.reportsNext;
            if (next || next === undefined || payload.initialLoad){
                let url;
                if (payload.initialLoad || !next){
                    url = '/class-reports';
                    if (payload.enrollment_id){
                        url += '?enrollment_id=' + payload.enrollment_id;
                    }
                }else{
                    url = next;
                }
                
                return new Promise((resolve, reject)=>{
                    window.axios.get(url).then(response=>{
                        const commitName = payload.initialLoad || next === undefined ? 'SET_REPORTS' : 'ADD_TO_REPORTS'
                        commit(commitName, {data: response.data.data, next: response.data.links.next});
                        resolve(response);
                    }).catch(error=>{
                        reject(error);
                    })
                });
            }
        },
        getClassReport({commit}, payload){
            let url = '/class-reports/' + payload.id;
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        updateClassReport({commit}, payload){
            let url = '/class-reports/' + payload.id;
            
            return window.axios.put(url, payload.data).then(response=>{
                commit('UPDATE_REPORT', response.data.data);
            });
        },
    }
}