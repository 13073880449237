export default {
    appData(){
        return this.$store.state.globalModule.appData;
    },
    user(){
        return this.$store.state.userModule.user;
    },
    fetchedUserCountry(){
        return this.$store.state.userModule.userCountry;
    },
    appLoadedOnce(){
        return this.$store.state.loaded;
    },
    platform(){
        return process.env.VUE_APP_PLATFORM;
    },
    isProduction(){
        return process.env.NODE_ENV === "production";
    },
    capRunning(){
        return this.platform !== 'web';
    },
    webPhone(){
        return this.platform === 'web' && this.phoneOnly;
    },
    iosSafeArea(){
        return this.platform === 'ios';
    },
    innerApp(){
        return this.isAuthed && this.$route.meta.requiresAuth && this.$route.name !== 'confirm';
    },
    pcOnly(){
        return this.$vuetify.breakpoint.mdAndUp;
    },
    xlOnly(){
        return this.$vuetify.breakpoint.xlOnly;
    },
    phoneOnly(){
        return !this.pcOnly;
    },
    isAuthed(){
        return !!this.$store.state.userModule.user;
    },
    lg6Cols(){
        return this.pcOnly ? 6 : 12;
    },
    isTeacher(){
        return this.user ? parseInt(this.user.groupId) === 1 : false;
    },
    isStudent(){
        return this.user ? parseInt(this.user.groupId) === 0 : false;
    },
    unreadInboxCount(){
        const conversations = this.$store.state.inboxModule.conversations;
        if (conversations && conversations.length){
            let count = 0;
            conversations.forEach(conversation=>{
                if (!conversation.seen){
                    count += 1;
                }
            })
            return count;
        }
        return this.user.unread_inbox_count;
    }
}