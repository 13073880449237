<template>
    <v-card :style="`${iosSafeArea ? 'padding-top: 40px!important; ' : ''}`">
        <v-progress-linear v-if="reloading" color="primary" indeterminate rounded absolute top height="3" ></v-progress-linear>

        <v-btn icon top right :large="phoneOnly" absolute style="top: 2px; right: 2px;" :style="`${iosSafeArea ? 'top: 40px!important; ' : ''}`" @click="$emit('close')">
            <v-icon :size="pcOnly ? 21 : 24">mdi-close</v-icon>
        </v-btn>
        <div class="d-flex align-center py-4 px-5">
            <div class="me-4">
                <router-link :to="isStudent ? {name: 'course', params: {id: classData.enrollment.course.id}} : ''">
                    <v-avatar tile width="60" height="50" style="border-radius: 5px!important" v-ripple="{class: 'black--text'}">
                        <v-img :src="classData.enrollment.course.image_url" />
                    </v-avatar>
                </router-link>
            </div>
            <div class="overflow-hidden">
                <router-link :to="isStudent ? {name: 'course', params: {id: classData.enrollment.course.id}} : ''">
                    <div class="strong-text--text font-weight-medium nowrap">{{classData.name || classData.enrollment.course.name}}</div>
                </router-link>
                <div class="d-flex">
                    <v-chip v-if="classData.rescheduled && classData.status === 'scheduled'" small class="font-weight-medium" dark color="teal darken-1" label>
                        <v-icon small left class="me-1">mdi-calendar-clock-outline</v-icon>
                        <span v-if="!!classData.waiting_reschedule_request">Reschedule Requested</span>
                        <span v-else>Rescheduled</span>
                    </v-chip>
                    <v-chip v-else-if="!classData.absent && !classData.canceled" small class="font-weight-medium" dark :color="handleStatus(classData.full_status).color" label>{{handleStatus(classData.full_status).text}}</v-chip>

                    <v-chip v-if="classData.makeup" small class="font-weight-medium ms-1" dark color="deep-purple accent-4" label>
                        <v-icon size="17" left class="me-1">mdi-shape-square-rounded-plus</v-icon>
                        <span>Makeup</span>
                    </v-chip>

                    <v-chip v-if="classData.absent" small class="font-weight-medium ms-1" dark color="amber darken-1" label>
                        <v-icon small left class="me-1">mdi-close-octagon-outline</v-icon>
                        Absent
                    </v-chip>
                    
                    <v-chip v-if="classData.canceled" small class="font-weight-medium ms-1" dark color="red darken-1" label>
                        <v-icon small left class="me-1">mdi-close</v-icon>
                        Canceled
                    </v-chip>

                    <div v-if="classData.payment_status" class="d-flex ms-1">
                        <v-chip v-if="classData.payment_status === 'trial'" small class="font-weight-medium" dark color="deep-purple" label>
                            <v-icon size="17" left class="me-1">mdi-check-decagram</v-icon>
                            <span>Trial</span>
                        </v-chip>
                        <v-chip v-else-if="classData.payment_status === 'credits-not-enough' && classData.status !== 'expired'" small class="font-weight-medium" dark color="red" label>
                            <v-icon size="18" left class="me-1">mdi-wallet</v-icon>
                            <span>Recharge Required</span>
                        </v-chip>
                        <v-chip v-else-if="classData.payment_status === 'paid'" small class="font-weight-medium" dark label>
                            <v-icon size="20" left class="me-1">mdi-cash-check</v-icon>
                            <span>Paid</span>
                        </v-chip>
                    </div>
                </div>

            </div>
        </div>
        <v-divider class="mx-5 mb-3"></v-divider>
        <v-card-text class="d-flex flex-column px-1 pb-2">
            <div class="px-3">
                <v-alert v-if="isSoon && !classData.canceled" dense small text dark color="blue darken-1" class="px-3 mb-2">
                    <div class="details-text font-weight-medium strong-text--text">
                        <v-icon color="blue darken-2" size="18" class="me-1">mdi-timer-sand</v-icon>
                        Starts in: <b class="strong-text--text">{{countdownTime}}</b>
                    </div>
                </v-alert>
                <v-alert v-else-if="classData.status !== 'expired' && classData.status !== 'running'" dense small text color="grey" class="px-3 mb-2">
                    <div class="details-text font-weight-medium strong-text--text">
                        <v-icon color="grey darken-1" size="18" class="me-1">mdi-clock-outline</v-icon>
                        Times are in your local time <b>{{timeNow}}</b>
                    </div>
                </v-alert>

                <v-alert v-if="classData.canceled && classData.cancelation_reason" dense small text dark  color="error darken-1" class="px-3 mb-2">
                    <div class="details-text font-weight-medium strong-text--text e-scrollbar" style="max-height: 100px; overflow-y: auto;">
                        <v-icon color="error darken-2" size="18" class="me-1">mdi-close-circle-outline</v-icon>
                        <span class="error--text text--darken-2">cancelation reason:</span>
                        <br>
                        <span class="details-text font-weight-medium" style="white-space: pre-line;">{{classData.cancelation_reason}}</span>
                    </div>
                </v-alert>
            </div>

            <div v-if="classData.status !== 'expired' && classData.payment_status === 'credits-not-enough'" class="mx-4">
                <router-link v-if="isStudent" :to="{name: 'wallet'}" style="text-decoration: none">
                    <v-alert dense small outlined text type="error" class="e-link px-3" v-ripple>
                        <div class="details-text font-weight-medium">
                            <b>Balance Not Enough:</b>
                            <br>
                            Please recharge as soon as possible..
                            <br>
                            <v-btn small class="mt-2" color="deep-purple" dark depressed style="text-transform: none" :to="{name: 'wallet', query: {borrow: '1'}}" @mousedown.stop @touchstart.stop @click.stop>
                                <v-icon left size="17">mdi-wallet-plus-outline</v-icon>
                                Or Borrow Balance
                            </v-btn>
                        </div>
                    </v-alert>
                </router-link>
                <v-alert v-else outlined dense small text type="error" color="orange darken-2" class="px-3">
                    <div class="details-text font-weight-medium">
                        <b>Student's balance is not enough</b>
                        <br>
                        Student cannot join class as his wallet is not charged.
                    </div>
                </v-alert>
            </div>

            <v-list two-line class="pa-0">

                <v-list-item v-if="classData.has_class_report">
                    <v-list-item-avatar>
                        <v-icon size="20" dark class="primary">mdi-star-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="d-flex" style="line-height: normal!important;">
                            <v-rating :value="classData.class_report_rating" color="primary" half-increments background-color="muted lighten-2" hover length="5" readonly dense size="16" half-icon="mdi-star-half-full" ></v-rating>
                            <span class="details-text ms-1">({{classData.class_report_rating}})</span>
                        </v-list-item-title>
                        <v-list-item-subtitle style="line-height: normal!important;">Class Report</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isTeacher">
                    <v-list-item-avatar>
                        <v-icon size="20" dark class="blue-grey darken-2">mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title style="line-height: normal!important;">{{classData.enrollment.student.name}}</v-list-item-title>
                        <v-list-item-subtitle style="line-height: normal!important;">Student</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-else>
                    <v-list-item-avatar>
                        <v-icon size="20" dark class="blue-grey darken-2">mdi-account-tie-voice</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title style="line-height: normal!important;">{{classData.enrollment.teacher.name}}</v-list-item-title>
                        <v-list-item-subtitle style="line-height: normal!important;">Tutor</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon size="22" dark :class="handleStatus(classData.status).color">mdi-calendar</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-2">
                        <v-list-item-title style="line-height: normal!important;">{{classDateFormated}}</v-list-item-title>
                        <v-list-item-subtitle style="line-height: normal!important;">Class Date</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon dark class="success">mdi-clock-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-2">
                        <v-list-item-title style="line-height: normal!important;">
                            {{startTime}}
                            <span class="details-text text--text">({{timeAgo}})</span>
                        </v-list-item-title>
                        <v-list-item-subtitle style="line-height: normal!important;">Start Time</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon dark class="red darken-1">mdi-clock-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-2">
                        <v-list-item-title style="line-height: normal!important;">{{endTime}}</v-list-item-title>
                        <v-list-item-subtitle style="line-height: normal!important;">End Time</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="isTeacher && (classData.url_editable || (classData.status === 'expired' && classData.join_url))" :disabled="!classData.url_editable || classData.status === 'expired' || classData.payment_status === 'credits-not-enough'" @click="joinUrl = classData.join_url; editJoinUrl = true">
                    <v-list-item-avatar>
                        <v-icon dark class="blue darken-1">mdi-link</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-2">
                        <v-list-item-title style="line-height: normal!important;">
                            <span v-if="classData.join_url">{{classData.join_url}}</span>
                            <span v-else class="muted--text">----------------------</span>
                        </v-list-item-title>
                        <v-list-item-subtitle style="line-height: normal!important;">Join Url</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="classData.url_added_at && classData.join_url" class="details-text" style="line-height: normal!important;">added at {{formatTime(classData.url_added_at, true)}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon v-if="classData.url_editable && classData.payment_status !== 'credits-not-enough'">
                        <v-icon color="grey" size="20">mdi-pencil</v-icon>
                    </v-list-item-icon>


                    <v-dialog v-model="editJoinUrl" max-width="400">
                        <v-card>
                            <v-card-title>Class Join Url</v-card-title>
                            <v-card-subtitle>Edit url that student will join using it</v-card-subtitle>
                            <v-card-text>
                                <v-textarea rows="3" color="blue darken-2" v-model="joinUrl" label="Join Url" no-resize outlined hide-details class="medium-text"></v-textarea>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="muted" @click="editJoinUrl = false">cancel</v-btn>
                                <v-btn :disabled="savingJoinUrl || joinUrl == classData.join_url" :loading="savingJoinUrl" text color="blue darken-2" @click="saveJoinUrl">Save Changes</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-list-item>

                <v-expand-transition>

                    <div v-if="showMoreInfo">
                        <v-divider inset class="my-1"></v-divider>
                        <v-list-item v-if="classData.student_age">
                            <v-list-item-avatar>
                                <v-icon dark class="indigo">mdi-calendar-account</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-2">
                                <v-list-item-title style="line-height: normal!important;">{{classData.student_age}} years old</v-list-item-title>
                                <v-list-item-subtitle style="line-height: normal!important;">Student Age</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="classData.student_timezone">
                            <v-list-item-avatar>
                                <v-icon dark class="indigo">mdi-map-clock</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-2">
                                <v-list-item-title style="line-height: normal!important;"><timezone-clock :timezone="classData.student_timezone" /></v-list-item-title>
                                <v-list-item-subtitle style="line-height: normal!important;">Student Time ({{classData.student_timezone}})</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </div>
                </v-expand-transition>

                <v-list-item v-if="classData.student_age || classData.student_timezone" @click="showMoreInfo = !showMoreInfo" dense>
                    <v-list-item-icon style="margin-top: 10px; margin-bottom: 10px;">
                        <v-icon color="text">{{showMoreInfo ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <span v-if="!showMoreInfo">Show More</span>
                        <span v-else>Show Less</span>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>
            </v-list>
        </v-card-text>
        <v-card-actions class="pa-4 pt-2" :style="`${iosSafeArea ? 'padding-bottom: 20px!important; ' : ''}`">
            <template v-if="classData.editable">
                <v-row>
                    <v-col cols="6" class="pe-1 py-0">
                        <v-btn color="teal" :loading="rescheduling" :disabled="!!classData.waiting_reschedule_request" depressed large block class="white--text" @click="rescheduleDialog = true; rescheduleDate = classData.date">
                            <v-icon left>mdi-calendar-clock-outline</v-icon>
                            reschedule
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="ps-1 py-0">
                        <v-btn :disabled="classData.canceled" :loading="canceling" color="red darken-1" depressed large block class="white--text" @click="cancelDialog = true">
                            <v-icon left>mdi-close-box-outline</v-icon>
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <div v-else-if="isStudent && !classData.canceled && !classData.absent && classData.id && classData.status !== 'expired'" class="w-100">
                
                <v-alert v-if="!classData.editable && !classData.joinable" dense small type="info" text color="grey" class="px-3">
                    <div class="details-text font-weight-medium strong-text--text">
                        You can't cancel or reschedule because less than 30 minutes left until class starts
                    </div>
                </v-alert>
                <v-alert v-if="!classData.joinable" dense small outlined text type="info" class="px-3" v-ripple>
                    <div class="details-text font-weight-medium strong-text--text">
                        <b>Wait for Teacher:</b>
                        <br>
                        The teacher hasn't joined the classroom yet, please wait..
                    </div>
                </v-alert>

                <v-btn color="blue darken-3" depressed :loading="joining" :disabled="!classData.joinable || classData.status === 'expired'" large block class="white--text" @click.prevent="joinClass(classData.id)">
                    <v-icon left class="me-3">mdi-motion-play-outline</v-icon>
                    Join Class
                </v-btn>
                <transition name="scroll-y-transition" mode="out-in">
                    <v-btn type="a" v-if="temporarilyJoinUrl" :target="capRunning ? '_system' : '_blank'" :href="temporarilyJoinUrl" small block text class="mt-2 grey lighten-4" style="text-transform: none">Not Working? Click here to join...</v-btn>
                </transition>
            </div>

            <div v-if="isTeacher && classData.can_mark_attended && classData.status !== 'scheduled' && !classData.attended && !classData.absent" class="w-100">
                <v-row>
                    <v-col v-if="!classData.has_class_report && classData.payment_status !== 'credits-not-enough'" cols="12" class="pt-0">
                        <v-btn color="primary" depressed :loading="attendedLoading" large block @click="addReportDialog = true">
                            <v-icon left class="me-3">mdi-file-document-outline</v-icon>
                            <span>Add Class Report</span>
                        </v-btn>
                    </v-col>

                    <v-col cols="6" class="pe-1 py-0">
                        <v-btn :disabled="!classData.has_class_report || classData.attended || classData.payment_status === 'credits-not-enough'" color="success" depressed :loading="attendedLoading" large block class="white--text" @click="attendedDialog = true">
                            <v-icon left class="me-3">mdi-account-check</v-icon>
                            Attended
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="ps-1 py-0">
                        <v-btn :disabled="classData.absent || classData.payment_status === 'credits-not-enough'" color="blue-grey" depressed :loading="absentLoading" large block class="white--text" @click="absentDialog = true">
                            <v-icon left class="me-3">mdi-close-octagon-outline</v-icon>
                            Absent
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card-actions>

        <v-dialog v-model="absentDialog" max-width="350">
            <v-card>
                <v-card-title>Student Absent Confirmation</v-card-title>
                <v-card-text>
                    Are you sure that student absent? you can't undo this action...
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" @click="absentDialog = false">Cancel</v-btn>
                    <v-btn text color="red" @click="markAsAbsent">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="attendedDialog" max-width="350">
            <v-card>
                <v-card-title>Attendance confirmation</v-card-title>
                <v-card-text>
                    Are you sure that student attended? after confirmation, student will be charged for this class and it'll be marked as "Attended"
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" @click="attendedDialog = false">Cancel</v-btn>
                    <v-btn text color="success" @click="markAsAttended">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addReportDialog" max-width="450" :fullscreen="phoneOnly" :fluid="phoneOnly">
            <v-card :class="{'fill-height': phoneOnly}" :style="`${iosSafeArea ? 'padding-top: 40px!important; ' : ''}`">
                <v-card-title class="mb-1">Class Report</v-card-title>
                <v-card-subtitle>fill form inputs and save class report</v-card-subtitle>
                <v-card-text>
                    <v-form ref="addReportForm">
                        <div class="mb-4 d-flex justify-center align-center">
                            <v-rating v-model="reportForm.rating" color="primary" half-increments background-color="muted lighten-2" hover length="5" size="28" half-icon="mdi-star-half-full" ></v-rating>
                            <div class="text-center primary--text font-weight-bold ms-4">
                                <v-chip small :outlined="reportForm.rating < 5" :color="!reportForm.rating || reportForm.rating < 2 ? 'muted' : 'primary'">{{reportForm.rating ? reportForm.rating.toFixed(1) : '---'}}</v-chip>
                            </div>
                        </div>
                        <v-divider class="mb-6 mb-lg-8"></v-divider>
                        <v-textarea v-model="reportForm.class_activity" label="Class Activity *" :disabled="savingClassReport" rows="4" outlined></v-textarea>
                        <v-textarea v-model="reportForm.homework" label="Homework *" :disabled="savingClassReport" no-resize rows="4" outlined></v-textarea>
                        <v-textarea v-model="reportForm.notes" label="Teacher Notes" :disabled="savingClassReport" rows="4" outlined></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" :disabled="savingClassReport" @click="addReportDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" :disabled="savingClassReport" @click="saveClassReport">Save Report</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="cancelDialog" max-width="400">
            <v-card>
                <v-card-title>Cancel Confirmation</v-card-title>
                <v-card-text>
                    <div v-if="isTeacher">
                        Are you sure that you want to cancel this class?
                    </div>
                    <div v-else>
                        <v-alert v-if="classData.available_class_changes || classData.available_class_changes === 0" dense small type="warning" icon="mdi-progress-alert" text :color="classData.available_class_changes === 0 ? 'red' : 'orange'" class="px-3">
                            <div class="deep-orange--text text--darken-1 medium-text">
                                Remaining <b>{{classData.available_class_changes}} times</b> from {{classData.monthly_class_changes}} to cancel or reschedule this month
                            </div>
                        </v-alert>
                        
                        <div>
                            if you canceled the class, You will not be charged and you'll not be able to schedule another class later.
                        </div>
                    </div>

                    <div class="mt-5">
                        <v-textarea v-model="cancelationReason" outlined placeholder="Reason of cancelation" color="error darken-1" hide-details no-resize class="medium-text"></v-textarea>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" @click="cancelDialog = false">Cancel</v-btn>
                    <v-btn text color="red" :disabled="classData.limit_exceeded" @click="cancelClass">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="rescheduleDialog" max-width="400">
            <v-card>
                <v-card-title class="mb-2">Reschedule Class</v-card-title>
                <v-card-text>
                    <template v-if="!isTeacher">
                        <v-alert v-if="classData.available_class_changes || classData.available_class_changes === 0" dense small type="warning" icon="mdi-progress-alert" text :color="classData.available_class_changes === 0 ? 'red' : 'orange'" class="px-3">
                            <div class="deep-orange--text text--darken-1 medium-text">
                                Remaining <b>{{classData.available_class_changes}} times</b> from {{classData.monthly_class_changes}} to cancel or reschedule this month
                            </div>
                        </v-alert>
                    </template>
                    <v-form v-if="!classData.limit_exceeded">
                         <v-menu ref="menu" v-model="rescheduleDateMenu" :close-on-content-click="false" :return-value.sync="rescheduleDate" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="rescheduleDate" placeholder="Class Date" color="teal" label="Class Date" outlined hide-details prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="teal" v-model="rescheduleDate" :min="minRescheduleDate" :max="maxRescheduleDate" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="muted" @click="rescheduleDateMenu = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="teal" :disabled="classData.limit_exceeded" @click="$refs.menu.save(rescheduleDate)">
                                    Confirm
                                </v-btn>
                            </v-date-picker>
                        </v-menu>

                        <div class="mt-4">
                            <time-input v-model="rescheduleTime" half-format outlined label="Start Time" placeholder="Start Time" color="teal" />
                        </div>

                        <div class="mt-4">
                            <v-switch v-model="permanentReschedule" label="Permanent Reschedule" color="teal" hide-details ></v-switch>
                        </div>

                        <div class="mt-5">
                            <v-textarea v-model="rescheduleReason" outlined placeholder="Reason of reschedule" color="teal darken-1" hide-details no-resize class="medium-text"></v-textarea>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" @click="rescheduleDialog = false">Cancel</v-btn>
                    <v-btn text color="teal" :disabled="classData.limit_exceeded" @click="rescheduleClass">Reschedule</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import TimeInput from '@/components/custom/time-input'
import TimezoneClock from '@/components/default/timezone-clock'


export default {
    components: {TimeInput, TimezoneClock},
    data(){
        const min = new Date();
        min.setDate(min.getDate()+1);

        const max = new Date();
        max.setDate(max.getDate()+7);

        return {
            showMoreInfo: false,
            joining: false,
            temporarilyJoinUrl: '',
            
            joinUrl: '',
            editJoinUrl: false,
            savingJoinUrl: false,

            absentDialog: false,
            absentLoading: false,
            
            attendedDialog: false,
            attendedLoading: false,

            cancelDialog: false,
            canceling: false,
            cancelationReason: '',

            addReportDialog: false,
            savingClassReport: false,
            reportForm: {
                rating: '',
                class_activity: '',
                homework: '',
                notes: '',
            },

            rescheduleDialog: false,
            rescheduling: false,
            rescheduleDateMenu: false,
            rescheduleDate: '' ,
            rescheduleTime: '' ,
            permanentReschedule: false,
            rescheduleReason: '',

            minRescheduleDate: min.toISOString().substr(0, 10),
            maxRescheduleDate: max.toISOString().substr(0, 10),

            intervalid: null,
            timeAgo: '',
            timeNow: '',
            countdownTime: '',
            startsInSeconds: null,
        }
    },
    props: {
        classData: {},
        reloading: {type: Boolean, default: false}
    },
    computed: {
        startTime(){
            // const d = new Date(this.classData.start);
            // const timeString = d.toLocaleTimeString();
            // const times = timeString.split(' ')[0].split(':');
            // return `${times[0]}:${times[1]} ${timeString.split(' ')[1]}`

            const t = new Date(this.classData.start);
            return this.formatTime(t);
        },
        endTime(){
            const t = new Date(this.classData.end);
            return this.formatTime(t);
        },
        classDateFormated(){
            const t = new Date(this.classData.start)
            const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
            return t.toLocaleDateString("en-US", options);
        },
        isSoon(){
            if (!this.startsInSeconds){
                return false;
            }
            const hours = this.startsInSeconds / 3600;
            return hours < 12;
        },
        ...GlobalComputed
    },
    methods: {
        joinClass(id){
            if (!this.joining){
                this.joining = true;
                this.$store.dispatch('joinClass', {id}).then(response=>{
                    this.joining = false;

                    const url = response.data.join_url;
                    window.setTimeout(()=>{
                        // window.open(url, '_system');
                        this.openLink(url);
                        this.setTemporarilyJoinUrl(url);
                    }, 300);

                    this.showClassDialog = false;

                }).catch(e=>{
                    this.joining = false;
                    this.showMsg('Error occurred during joining, please contact support', 'error');
                })
            }
        },
        setTemporarilyJoinUrl(url){
            this.temporarilyJoinUrl = url;
            window.setTimeout(()=>{
                this.temporarilyJoinUrl = '';
            }, 4000);
        },
        formatTime(t, isTimestamp=false){
            if (isTimestamp){
                t = new Date(t);
            }
            let hours = t.getHours(), 
                minutes = t.getMinutes() + '', 
                ampm = 'AM';

            if(hours > 11){
                hours = hours - 12;
                ampm = 'PM';
            }

            if(hours == 0) hours = 12;
            if(minutes.length == 1) minutes = '0' + minutes;

            return `${hours}:${minutes} ${ampm}`;
        },
        openLink(url){
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.setAttribute('target', '_system');
            a.click();
            document.body.removeChild(a);
        },
        saveJoinUrl(){
            const id = this.classData.id;
            if (!this.savingJoinUrl){
                this.savingJoinUrl = true;
                this.$store.dispatch('saveJoinUrl', {id, url: this.joinUrl}).then(response=>{
                    this.savingJoinUrl = false;
                    this.editJoinUrl = false;

                }).catch(e=>{
                    this.savingJoinUrl = false;
                    console.error(e);
                    this.showMsg('Error occurred during preforming your action', 'error');
                })
            }
        },
        markAsAbsent(){
            const id = this.classData.id;
            if (!this.absentLoading){
                this.absentDialog = false;
                this.absentLoading = true;
                this.$store.dispatch('markAsAbsent', {id}).then(response=>{
                    this.absentLoading = false;
                }).catch(e=>{
                    this.absentLoading = false;
                    this.showMsg('Error occurred during preforming your action', 'error');
                })
            }
        },
        markAsAttended(){
            const id = this.classData.id;
            if (!this.attendedLoading){
                this.attendedDialog = false;
                this.attendedLoading = true;
                this.$store.dispatch('markAsAttended', {id}).then(response=>{
                    this.attendedLoading = false;
                }).catch(e=>{
                    this.attendedLoading = false;
                    this.showMsg('Error occurred during preforming your action', 'error');
                })
            }
        },
        saveClassReport(){
            const id = this.classData.id;
            if (!this.savingClassReport){
                this.savingClassReport = true;
                const data = this.reportForm;
                this.$store.dispatch('saveClassReport', {id, data}).then(response=>{
                    this.savingClassReport = false;
                    this.addReportDialog = false;
                    this.showMsg('Class Report Saved Successfully', 'success');
                }).catch(e=>{
                    this.savingClassReport = false;
                    this.showMsg('Error occurred during preforming your action', 'error');
                })
            }
        },
        cancelClass(){
            const id = this.classData.id;
            this.cancelDialog = false;
            if (!this.canceling){
                this.canceling = true;
                const data = {
                    cancelation_reason: this.cancelationReason
                };

                this.$store.dispatch('cancelClass', {id, data}).then(response=>{
                    this.canceling = false;
                }).catch(e=>{
                    this.canceling = false;
                    if (e && e.response && e.response.data === 'LIMIT_EXCEEDED'){
                        this.showMsg('Monthly limit exceeded', 'error');
                    }else if (e && e.response && e.response.data && e.response.data.message){
                        this.showMsg(e.response.data.message, 'error');
                    }else{
                        this.showMsg('Error occurred during preforming your action', 'error');
                    }
                })
            }
        },
        rescheduleClass(){
            if (this.rescheduleTime && this.rescheduleDate){
                const id = this.classData.id;
                this.rescheduleDialog = false;
                if (!this.rescheduling){
                    this.rescheduling = true;
                    this.$store.dispatch('rescheduleClass', {id, date: this.rescheduleDate, time: this.rescheduleTime, permanent: this.permanentReschedule, reason: this.rescheduleReason}).then(response=>{
                        this.rescheduling = false;
                        this.showMsg('Reschedule request submitted', 'success');
                    }).catch(e=>{
                        this.rescheduling = false;
                        
                        if (e && e.response && e.response.data === 'LIMIT_EXCEEDED'){
                            this.showMsg('Monthly limit exceeded', 'error');
                        }else if (e && e.response && e.response.data && e.response.data.message){
                            this.showMsg(e.response.data.message, 'error');
                        }else{
                            this.showMsg('Error occurred during preforming your action', 'error');
                        }
                    })
                }
            }else{
                this.showMsg('Fill required inputs', 'error');
            }
        },
        setTimeIntervals(time) {
            const self = this;
            self.setDowntimer(time);
            self.timeAgo = self.timeSince(time, true);

            // update time every 1s
            this.intervalid = setInterval(function () {
                self.timeAgo = self.timeSince(time, true);
                self.setDowntimer(time);
            }, 1000);
        },
        timeNowHandler(){
            var date = new Date();
            var h = date.getHours(); // 0 - 23
            var m = date.getMinutes(); // 0 - 59
            var s = date.getSeconds(); // 0 - 59
            var session = "AM";
            
            if(h == 0){
                h = 12;
            }
            
            if(h >= 12){
                session = "PM";
                if (h > 12){
                    h = h - 12;
                }
            }
            
            h = (h < 10) ? "0" + h : h;
            m = (m < 10) ? "0" + m : m;
            s = (s < 10) ? "0" + s : s;
            
            this.timeNow = h + ":" + m + ":" + s + " " + session;
            
            window.setTimeout(this.timeNowHandler, 1000);
        },
        setDowntimer(time){
            let now = + new Date(), // + new Date() = date.valueOf() | it is a timestamp
                start = new Date(time),
                difference = start.valueOf() - now;
            if (difference > 0){
                let days = Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds = Math.floor((difference % (1000 * 60)) / 1000);
    
                // this.countdownTime = `${days ? days + ' ' : ''}${hours<10 ? '0' + hours : hours}:${minutes<10 ? '0'+minutes : minutes}:${seconds<10 ? '0' + seconds : seconds}`
                
                let countdownTime = '';
                if (days) countdownTime += `${days} days, `;
                if (hours) countdownTime += `${hours} hours and `;
                if (minutes){
                    if (hours){
                        countdownTime += `${minutes} minutes`
                    }else{
                        countdownTime += `${minutes} minutes and `
                    }
                }
                if (!days && !hours){
                    if (seconds >= 0) countdownTime += `${seconds} seconds`;
                }
                this.countdownTime = countdownTime;
                this.startsInSeconds = Math.floor(difference / 1000);
            }
        },
        ...GlobalMethods
    },
    watch: {
        classData(){
            if (this.intervalid){
                window.clearInterval(this.intervalid);
            }
            this.setTimeIntervals(this.classData.start);
        }
    },
    mounted(){
        this.setTimeIntervals(this.classData.start);
        this.timeNowHandler();
    },
    beforeDestroy(){
        if (this.intervalid){
            window.clearInterval(this.intervalid);
        }
    }
};
</script>