import Vue from "vue";
import Vuetify from "vuetify/lib";
import Colors from '../helpers/colors'

Vue.use(Vuetify);

export default new Vuetify({
    rtl: false,
    theme: {
        themes: {
            ...Colors
        }
    }
});
