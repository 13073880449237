<template> 
    <v-dialog v-model="show" max-width="350">
        <v-card class="px-2 pb-1">
            <v-card-title class="headline">Logout</v-card-title>
            <v-card-text class="grey--text mt-3">Are you sure that you want to logout?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="grey darken-1" text @click="show = false">cancel</v-btn>
                <v-btn color="red darken-1" :loading="loading" text @click="show = false; logout()">Logout</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'

export default {
    props: ['value'],
    data(){
        return {
            show: false,
            loading: false,
        }
    },
    watch :{
        show(show){
            if (!show){
                this.$emit('input', false); // close
            }
        },
        value(show){
            this.show = show;
        }
    },
    computed: {
        ...GlobalComputed,
    },
    methods: {

        logout(){
            // remove any intervals or realtime connections
            this.loading = true;
            const isTeacher = this.isTeacher;
            window.setTimeout(()=>{
                this.$store.dispatch('logout');
                window.setTimeout(()=>{
                    const route = isTeacher ? 'login-teachers' : 'login';
                    this.$router.push({name: route});
                    // reload to reset vuex store
                    window.location.reload(true);
                }, 250);
            }, 500);
        },
    }
}
</script>

<style>

</style>