export default {
    state: {
        salaryClasses: {
            classes: null,
            salary: null,
            classes_count: null,
        },
        previousSalaryClasses: {
            classes: null,
            salary: null,
            classes_count: null,
        },
        paymentHistoryData: {
            recharges: null,
            class_payments: null,
            wallet_logs: null,
        },
    },
    mutations: {
        SET_SALARY_CLASSESS(state, payload){
            state.salaryClasses = payload.data;
        },
        ADD_SALARY_CLASSESS(state, payload){
            state.salaryClasses.push(...payload.data);
        },
        SET_PREVIOUS_SALARY_CLASSESS(state, payload){
            state.previousSalaryClasses = payload.data;
        },
        SET_PAYMENT_HISTORY(state, payload){
            state.paymentHistoryData = payload.data;
        },
    },
    actions: {
        loadSalaryClasses({commit}){
            let url = '/history/salary';
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    commit('SET_SALARY_CLASSESS', {data: response.data});
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        loadPreviousSalaryClasses({commit}){
            let url = '/history/salary/previous';
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    commit('SET_PREVIOUS_SALARY_CLASSESS', {data: response.data});
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        loadTransactionsData({commit}){
            let url = '/history/transactions';
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    commit('SET_PAYMENT_HISTORY', {data: response.data});
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
    }
}