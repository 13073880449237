<template>
    <v-avatar class="v-avatar" v-ripple="!!to" :size="size" :color="src ? undefined : ''" @click="to ? $router.push(to) : ''" :class="{'e-link': !!to}">
        <img v-if="src" :src="src">
        <v-icon v-else :color="color ? color : 'grey'" :size="iconSize ? iconSize : 30">mdi-account-circle</v-icon>
    </v-avatar>
</template>

<script>
export default {
    props: ['src', 'size', 'iconSize', 'to', 'color'],
}
</script>

<style lang="scss">
    .v-application .v-avatar.grey{
        border: 1px solid rgb(200, 200, 200)!important;
    }
</style>