export default {
    state: {
        appData: null,
        snackMsg: {show: false, text: '', msgBar: true},
        loading: false,

        linearLoading: false,
    },
    mutations: {
        SET_APP_DATA(state, payload){
            state.appData = payload.data;
        },
        SET_SNACK_MSG(state, msg){
            state.snackMsg = msg;
        },
        SET_LOADING(state, loading){
            state.loading = loading;
        },
        SET_LINEAR_LOADING(state, loading){
            state.linearLoading = loading;
        },
    },
    actions: {
        loadAppData({commit}){
            let url = '/app';
            return window.axios.get(url).then(response=>{
                commit('SET_APP_DATA', {data: response.data});
            });
        },
        setLoading({commit}, payload){
            commit('SET_LOADING', payload.loading);
        },
        addSnackMsg({commit}, msg){
            commit('SET_SNACK_MSG', msg);
        },

    }
}