<template>
    <div class="page">
        <v-container :class="{'px-0': phoneOnly}">
            <!-- <h1 class="mb-2">childs</h1>
            <p>Hello, welcome to Riwaq Al Quran Portal</p> -->

            <v-card :flat="phoneOnly" class="px-1 px-lg-3" :class="{'transparent': phoneOnly, 'soft-shadow': pcOnly}">
                <v-card-title class="d-flex justify-space-between">
                    <div>
                        Family Accounts
                    </div>
                    <div v-if="!capRunning" class="px-3">
                        <v-btn color="success" depressed @click="newChildDialog = true; hideRegisterForm = false">
                            <v-icon size="20" left>mdi-account-plus</v-icon>
                            New Account
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-subtitle>You can create accounts for your family and transfer money to their wallets</v-card-subtitle>
                <v-card-text v-if="!loading">
                    <v-simple-table v-if="childs && childs.length">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Name
                                    </th>
                                    <th class="text-left">
                                        Email
                                    </th>
                                    <th class="text-left">
                                        Balance
                                    </th>
                                    <th class="text-left">
                                        Borrowed Balance
                                    </th>
                                    <th class="text-right">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="child in childs" :key="child.id">
                                    <td class="font-weight-bold">{{child.name}}</td>
                                    <td>{{child.email}}</td>
                                    <td>
                                        <v-icon size="22" :color="child.credits < 2 ? 'red' : 'orange darken-2'">mdi-wallet</v-icon>
                                        <span class="font-weight-bold ms-2" :class="{'error--text': child.credits < 2}">{{moneyFormat(child.credits)}}</span>
                                    </td>
                                    <td>
                                        <span class="font-weight-medium" :class="{'error--text': child.loan_credits > 0}">{{moneyFormat(child.loan_credits)}}</span>
                                    </td>
                                    <td class="text-right" style="white-space: nowrap;">
                                        <v-btn color="blue darken-2" depressed small dark style="text-transform: none" @click="transferDialog = true; transferToChild = child.id;">
                                            <v-icon left small>mdi-wallet-plus</v-icon>
                                            Transfer
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div v-else-if="!loading">
                        <v-alert type="info" color="blue-grey" text>
                            No family accounts found, add a new account now!
                        </v-alert>
                    </div>
                </v-card-text>
            </v-card>

            <v-btn color="primary" fab fixed bottom right @click="newChildDialog = true; hideRegisterForm = false" style="bottom: 80px;">
                <v-icon size="26">mdi-account-plus</v-icon>
            </v-btn>
        </v-container>

        <v-dialog v-model="transferDialog" max-width="350">
            <v-card>
                <v-card-title class="mb-2">Transfer Credits</v-card-title>
                <v-card-subtitle>transfer credits directly from your wallet to family member's wallet</v-card-subtitle>
                <v-card-text class="py-5">
                    <v-text-field v-model="transferAmount" autofocus :loading="transferring" :disabled="transferring" color="orange" hide-details class="transfer-input font-weight-medium" type="number" outlined label="Amount" placeholder="Enter Amount" suffix="$" min="1"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" :disabled="transferring" @click="transferDialog = false">Cancel</v-btn>
                    <v-btn :loading="transferring" :disabled="transferring" text color="success" @click="transferAction">Transfer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="newChildDialog" max-width="600" persistent :fullscreen="capRunning" scrollable>
            <v-card :style="`${iosSafeArea ? 'padding-top: 40px!important; ' : ''}`">
                <v-card-title>New Family Account</v-card-title>
                <v-card-text class="py-5">
                    <register-form v-if="!hideRegisterForm" button-text="Create Account" :loading="creating" :dialog-opened="newChildDialog" @register="addNewChild" />
                </v-card-text>
                <v-btn icon absolute top right @click="newChildDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" :disabled="creating" @click="newChildDialog = false">Cancel</v-btn>
                    <v-btn :loading="creating" :disabled="creating" text color="success" @click="addNewChild">Confirm</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import RegisterForm from '@/components/custom/register-form'

export default {
    name: "childs",
    components: {
        RegisterForm
    },
    data(){
        return {
            searchQuery: '',
            loading: false,

            newChildDialog: false,
            hideRegisterForm: false,
            creating: false,

            transferDialog: false,
            transferToChild: null,
            transferAmount: '',
            transferring: false,
        }
    },
    computed: {
        childs(){
            return this.$store.state.childsModule.childs;
        },
        visiblechilds(){
            const query = this.searchQuery.toLowerCase();
            let childs = this.childs;

            if (query){
                return childs.filter(child=>child.name.toLowerCase().search(query) !== -1)
            }

            return childs;
        },
        ...GlobalComputed
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
    },
    methods: {
        addNewChild(data){
            if (!this.creating){
                this.creating = true;
                this.$store.dispatch('newChild', data).then(()=>{
                    this.creating = false;
                    this.newChildDialog = false;
                    window.setTimeout(()=>{
                        this.hideRegisterForm = true; // this will destroy register-form component to reset form
                    }, 600);
                    this.showMsg('Account created successfully', 'success');
                    
                }).catch(()=>{
                    this.creating = false;
                    this.showMsg('Error occurred during preforming the action', 'error');
                })
            }
        },
        transferAction(){
            if (this.transferAmount){
                if (!this.transferring){
                    this.transferring = true;
                    this.$store.dispatch('transferCreditsToChild', {child_id: this.transferToChild, amount: this.transferAmount}).then(()=>{
                        this.transferring = false;
                        this.transferDialog = false;
                        this.transferAmount = '';
                        this.showMsg('Credits transferred successfully', 'success');
                    }).catch(e=>{
                        this.transferring = false;

                        if (e.response && e.response.data === 'NO_ENOUGH_CREDITS'){
                            this.showMsg('No Enough Credits', 'error');
                        }else{
                            this.showMsg('Error occurred during preforming the action', 'error');
                        }
                    })
                }
            }
        },
        loadData(){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('loadChilds').then(()=>{
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        ...GlobalMethods
    },
    mounted(){
        if (this.appData && !this.appData.enable_app_w){
            this.$router.push('/not-found');
        }else{
            if (!this.childs || this.childs.length === 0){
                this.loadData();
            }
        }
    }
};
</script>
