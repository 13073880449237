<template>
    <v-navigation-drawer width="335" overlay-color="#000" overlay-opacity="0.65" app fixed v-model="show" temporary> <!-- :permanent="pcOnly" -->
        <div class="drawer-header" @click="watermarkOpacity = 0.02">
            <span class="watermark small-text" :style="'opacity: ' + watermarkOpacity" @click.stop="showWatermark">[Developed by: Ebrahim Maher]</span>
            <div class="logo-wrapper d-flex align-center justify-space-between px-5 mb-5">
                <logo />

                <v-btn large class="ml-2 mb-2" icon text @click.prevent="show=false">
                    <v-icon size="24">mdi-menu</v-icon>
                </v-btn>
            </div>
            
            <!-- <div v-if="isAuthed" class="d-flex align-center py-2 px-7">
                <div class="text-center">
                    <avatar :src="user.avatar" :size="50" :icon-size="40"/>
                </div>
                <div class="mr-2">
                    <div class="user-name ms-2" style="line-height: 1rem">{{user.name}}</div>
                    <v-btn dense small v-if="isAuthed" text @click="logoutDialog = true; show = false">Logout</v-btn>
                </div>
                
            </div> -->
        </div>
        
        <v-divider class="my-3" />

        <v-list nav class="mt-4 pa-0">
            <!-- <v-divider class="mb-3" /> -->
            <v-list-item dense v-for="(item, i) in drawerRoutes" v-show="!item.pcOnly || pcOnly" color="primary" :key="i" link :to="item.to" :href="item.href" exact class="my-0 px-8">
                <v-list-item-icon class="ml-4">
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title style="font-size: 0.95rem; line-height: 1.4rem">{{item.name}}</v-list-item-title>
                </v-list-item-content>

            </v-list-item>
            
        </v-list>
    
        <logout-dialog v-model="logoutDialog" />
    </v-navigation-drawer>
    
</template>

<script>
import GlobalMethods from '@/helpers/global-methods'
import GlobalComputed from '@/helpers/global-computed'
import Logo from './logo'
import drawerRoutes from '@/helpers/links'
import LogoutDialog from '../default/dialogs/logout'

export default {
    name: 'app-menu',
    props: ['value'],
    data(){
        return {
            show: false,
            watermarkOpacity: 0.02,
            logoutDialog: false,
        }
    },
    components: {
        Logo,
        LogoutDialog,
        // StoreBadge
    },
    watch :{
        show(show){
            if (!show){
                this.$emit('input', false); // close
            }
        },
        value(show){
            this.show = show;
        }
    },
    computed: {
        drawerRoutes(){
            return this.isAuthed ? drawerRoutes.auth : drawerRoutes.guest;
        },
        ...GlobalComputed
    },
    methods: {
        openRoute(route){
            this.show = false;
            this.$router.push(route);
        },
        showWatermark(){
            this.watermarkOpacity += 0.01;
        },
        openProfile(){
            let isProfile = this.$route.name === 'profile' && this.$route.params.id == this.user.id;
            if (!isProfile){
                this.$router.push({name: 'profile', params: {id: this.user.id}});
            }
        },
        ...GlobalMethods
    }
}
</script>

<style>

</style>