import Vue from 'vue'
import Vuex from 'vuex'
import globalModule from './modules/global/'
import userModule from './modules/user/'
import coursesModule from './modules/courses/'
import childsModule from './modules/childs'
import historyModule from './modules/history'
import inboxModule from './modules/inbox'
import classReportsModule from './modules/class-reports'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loaded: false, // app loaded once so "loading spinner work just at the begining"
    },
    mutations: {
        SET_LOADED(state, loaded){
            state.loaded = !!loaded;
        },
    },
    actions: {
        appLoaded({commit}){
            commit('SET_LOADED', true);
        },
        fastGetAction(context, payload){
            let url = payload.url;
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        fastPostAction(context, payload){
            let url = payload.url;
            
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        fastPutAction(context, payload){
            let url = payload.url;
            
            return new Promise((resolve, reject)=>{
                window.axios.put(url, payload.data).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
    },
    modules: {
        globalModule,
        userModule,
        coursesModule,
        childsModule,
        historyModule,
        inboxModule,
        classReportsModule
    }
})
