<template>
    <v-btn icon color="text" class="ml-lg-5" :to="{name: 'inbox'}">
        <v-badge class="inbox-badge" :value="!!unreadInboxCount" color="ebadge" bordered :content="unreadInboxCount" overlap right>
            <v-icon size="24">{{unreadInboxCount ? 'mdi-email' : 'mdi-email-outline' }}</v-icon>
        </v-badge>
    </v-btn>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'
export default {
    props: {},
    data(){
        return {
            connectedChannel: null,
        }
    },
    computed: {
        ...GlobalComputed,
        
    },
    watch: {
        unreadInboxCount(unread, old){
            if (unread > old){ /*  && old !== null */
                this.playSound('new-message', 'ogg');
            }
        },
    },
    methods: {

        setRealtimeChat(){
            if (!this.connectedChannel){
                let channel = `User.${this.user.id}.Inbox`;
                this.connectedChannel = channel;
                window.Echo.private(channel).listen('InboxUpdated', (response) => {
                    let data = response.data;
                    this.$store.commit('UPDATE_CONVERSATION', data.data);
                    this.$store.commit('SET_USER_UNREAD_INBOX_COUNT', data.unread_inbox_count); // if no conversation loaded yet, this will be used as unread_inbox_count
                });
            }
        },
        leaveRealtimeChat(){
            window.Echo.leave(this.connectedChannel);
            window.console.log('leaved: ' + this.connectedChannel);
        },
        playSound(filename, ext = 'mp3', volume = .6) {
            var path = '/sounds/' + filename + '.' + ext,
                soundFile = new window.Audio(path);

            soundFile.volume = volume;
            soundFile.play();
        },
    },
    mounted(){
        window.setTimeout(()=>{
            this.setRealtimeChat();
        }, 1000);
    },
    beforeDestroy(){
        this.leaveRealtimeChat();
    }
}
</script>