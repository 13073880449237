import Vue from "vue";
import VueRouter from "vue-router";
import routes from './routes'
Vue.use(VueRouter);


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


// Auth logic

router.beforeEach((to, from, next) => {
    // keep referral query while navigating
    if(from.query && from.query.referral && !to.query.referral){
        next({name: to.name, query: from.query});
    }else{
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged in
            // if not, redirect to login page.
            
            if ( !window.localStorage.getItem('tK') ) {
            // next({ name: 'login' })
            next({ name: 'welcome', query: from.query})
            } else {
            next() // go to wherever I'm going
            }
        }else if (to.matched.some(record => record.meta.requiresGuest)){
            if (window.localStorage.getItem('tK')) {
            next({ name: 'home', query: from.query})
            } else {
            next() // go to wherever I'm going
            }
        } else {
            next() // does not require auth or guest, make sure to always call next()!
        }
    }
})

export default router;
