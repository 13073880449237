<template>
    <div class="d-flex align-center">
        <img @click="navigate" :src="asset('logo.png', 'img') + '?1'" class="logo" :class="{link}" :style="style">
    </div>
</template>
<script>
import GlobalMethods from '@/helpers/global-methods'
import GlobalComputed from '@/helpers/global-computed'

export default {
    props: {
        height: {
            
        },
        link: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        ...GlobalComputed,
        style(){
            let style = '';
            const height = this.height ? this.height : (this.pcOnly ? 57 : 50) ;
            style += 'height: ' + height + 'px';
            return style;
        },
    },
    methods: {
        ...GlobalMethods,
        navigate(){
            if (this.link){
                if (this.isAuthed){
                    if (this.$route.name !== 'home'){
                        this.$router.push({name: 'home'});
                    }
                }else{
                    if (this.$route.name !== 'welcome'){
                        this.$router.push({name: 'welcome'});
                    }
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    
    .logo{
        transition: all 0.2s ease-in-out;
        &.link{
            cursor: pointer;
        }
    }
</style>