<template>
    <div class="page">
        <v-container :class="{'px-0': phoneOnly}">
            <div>
                <v-card :flat="phoneOnly" class="px-2 px-lg-3 soft-shadow">
                    <v-card-title class="d-flex justify-space-between">
                        <div>
                            Transactions History
                        </div>
                        <div class="px-3">
                            
                        </div>
                    </v-card-title>
                    <v-card-subtitle>List of recharges, class payments and other transactions of your account</v-card-subtitle>
                    <v-card-text v-if="!loading" :class="{'px-0': phoneOnly}">
                        <v-simple-table v-if="items && items.length" class="e-data-table">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Serial
                                        </th>
                                        <th class="text-left">
                                            Transaction Details
                                        </th>
                                        <th class="text-left">
                                            Amount
                                        </th>
                                        <th class="text-right">
                                            Created At
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in items" :key="i">
                                        <td>#{{item.serial}}</td>
                                        <td>
                                            <div v-if="item.type === 'recharge'">
                                                <v-icon color="success" size="19" class="me-2">mdi-wallet-plus</v-icon>
                                                Wallet Recharge Transaction
                                            </div>
                                            <div class="details-text" v-else-if="item.type === 'class_payment'">
                                                <v-icon color="text" size="20" class="me-2">mdi-cash-check</v-icon>
                                                Class: <v-btn class="px-1" color="strong-text" style="text-decoration: underline; text-transform: none;" :to="{name: 'course', params: {id: item.class.enrollment.course.id}}" text small>
                                                    {{item.class.enrollment.course.name}}
                                                </v-btn>
                                            </div>
                                            <div class="details-text" v-else-if="item.type === 'family_balance_transfer'">
                                                <v-icon color="blue darken-3" size="20" class="me-2">mdi-account-supervisor-circle</v-icon>
                                                <span>Family: </span> <span class="strong-text--text">{{item.comment}}</span>
                                            </div>
                                            <div class="details-text d-flex" v-else-if="item.type === 'admin_credits_update'" style="white-space: normal;">
                                                <v-icon color="primary" size="20" class="me-2">mdi-lifebuoy</v-icon>
                                                <div><span>Support: </span> <span class="strong-text--text">{{item.comment}}</span></div>
                                            </div>
                                            <div class="details-text" v-else-if="item.type === 'borrow_balance'">
                                                <v-icon color="deep-purple darken-1" size="20" class="me-2">mdi-cash-plus</v-icon>
                                                <span class="strong-text--text">{{item.comment}}</span>
                                            </div>
                                            <div class="details-text" v-else-if="item.type === 'borrow_balance_deduct'">
                                                <v-icon color="deep-purple darken-1" size="20" class="me-2">mdi-cash-minus</v-icon>
                                                <span class="strong-text--text">{{item.comment}}</span>
                                            </div>
                                            <div v-else-if="item.type === 'referral_reward'">
                                                <v-icon color="indigo darken-3" size="19" class="me-2">mdi-wallet-giftcard</v-icon>
                                                Referral Reward
                                            </div>
                                            <div v-else-if="item.type === 'wise_reward'">
                                                <v-icon color="indigo darken-3" size="19" class="me-2">mdi-creation-outline</v-icon>
                                                {{item.comment}}
                                            </div>
                                        </td>
                                        <td>
                                            <span v-if="item.type === 'recharge'" class="font-weight-bold green--text text--darken-1">+ {{moneyFormat(item.amount)}}</span>
                                            <span v-else-if="item.type === 'class_payment'" class="font-weight-bold red--text">- {{moneyFormat(item.value)}}</span>
                                            <span v-else-if="item.type === 'borrow_balance'" class="font-weight-bold deep-purple--text text--darken-1">+ {{moneyFormat(item.value)}}</span>
                                            <span v-else-if="item.type === 'borrow_balance_deduct'" class="font-weight-bold error--text">- {{moneyFormat(item.value)}}</span>
                                            <span v-else-if="item.type === 'referral_reward' || item.type === 'wise_reward'" class="font-weight-bold indigo--text text--darken-3">+ {{moneyFormat(item.value)}}</span>
                                            <span v-else-if="item.isWalletLog">
                                                <span v-if="item.value >= 0" class="font-weight-bold blue--text text--darken-3">+ {{moneyFormat(item.value)}}</span>
                                                <span v-if="item.value < 0" class="font-weight-bold red--text">- {{moneyFormat(-1 * item.value)}}</span>
                                            </span>
                                            <span v-if="item.credits_after" class="details-text ms-2 muted--text">(Balance: {{moneyFormat(item.credits_after)}})</span>
                                        </td>
                                        <td class="text-right">{{dateTimeFormat(item.timestamp, true)}} <span class="muted--text">({{item.created_at_readable}})</span></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <div v-else-if="!loading">
                            <v-alert type="info" color="blue-grey" text>
                                No transactions were found
                            </v-alert>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </v-container>
        
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'


export default {
    name: "transactions-history",
    components: {
        
    },
    data(){
        return {
            searchQuery: '',
            loading: false,
        }
    },
    computed: {
        paymentHistoryData(){
            return this.$store.state.historyModule.paymentHistoryData;
        },
        items(){
            if (!this.paymentHistoryData){
                return [];
            }
            
            let recharges = this.paymentHistoryData.recharges || [];
            recharges = recharges.map(recharge=>{recharge.type = 'recharge'; return recharge;});
            
            let class_payments = this.paymentHistoryData.class_payments || [];
            class_payments = class_payments.map(payment=>{payment.type = 'class_payment'; return payment;});

            let wallet_logs = this.paymentHistoryData.wallet_logs || []; // it has it's type property, as logs not the same type
            wallet_logs = wallet_logs.map(log=>{log.isWalletLog = true; return log;});

            let items = [...wallet_logs, ...recharges, ...class_payments];
            return items.sort((a, b)=>{
                if (a.timestamp - b.timestamp !== 0){
                    return a.timestamp - b.timestamp;
                }else{
                    return a.serial - b.serial;
                }
            }).reverse(); // reverse means 'Desc' not 'Asc'
        },
        ...GlobalComputed
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
    },
    methods: {
        loadData(){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('loadTransactionsData').then(()=>{
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        ...GlobalMethods
    },
    mounted(){
        if (this.appData && !this.appData.enable_app_w){
            this.$router.push('/not-found');
        }else {
            if (!this.paymentHistoryData.recharges || !this.paymentHistoryData.class_payments){
                this.loadData();
            }
        }
    }
};
</script>
