<template>
    <div>
        <v-container :class="{'pa-0': phoneOnly}">
            <v-card flat class="inbox-wrapper soft-shadow d-flex align-stretch">
                <v-row class="pa-0 ma-0" style="max-width: 100%;">
                    <v-col cols="12" md="5" lg="4" xl="3" class="navigate-aside overflow-y-auto e-scrollbar pa-0 ma-0">
                        <v-toolbar flat class="w-full pa-0" color="white" height="55">
                            <!-- <v-toolbar-title class="medium-text">Inbox</v-toolbar-title> -->
                            <div class="w-100">
                                <v-text-field v-model="searchQuery" solo flat label="Search..." hide-details dense background-color="grey lighten-4" class="inbox-search"></v-text-field>
                            </div>
                        </v-toolbar>
                        <v-list two-line>
                            <template v-for="conversation in sortedConversations" >
                                <conversation-list-item :key="conversation.id" :conversation="conversation" :active="activeConversation === conversation.id" :disabled="conversationLoading && !(activeConversation === conversation.id)" @open="openConversation(conversation)" />
                                <v-divider inset :key="'d-' + conversation.id" style="border-color: rgba(0,0,0, 0.05)"></v-divider>
                            </template>
                        </v-list>
                        <div v-if="!sortedConversations.length && searchQuery" class="px-3">
                            <v-alert type="info" dense color="grey lighten-1" text outlined>
                                <span class="text--text medium-text">No Conversation Found</span>
                            </v-alert>
                        </div>
                    </v-col>
                    <v-col v-if="pcOnly" cols="12" md="7" lg="8" xl="9" class="pa-0 ma-0">
                        <conversation v-if="activeConversationItem" :conversation="activeConversationItem" style="height: 100%" @loading="updateConversationLoading" />
                    </v-col>
                </v-row>
            </v-card>
            <v-dialog v-if="phoneOnly" v-model="showConversationDialog" transition="slide-left" fullscreen fluid scrollable>
                <v-card flat style="height: 100%" :style="`${iosSafeArea ? 'padding-top: 40px!important; ' : ''}`">
                    <v-toolbar flat style="border-bottom: 1px solid rgba(0,0,0, .1)">

                        <v-btn icon @click="closeConversationDialog" class="ms-0">
                            <v-icon :size="pcOnly ? 21 : 24">mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="activeConversationItem" class="px-3 medium-text">{{activeConversationItem.title}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <conversation v-if="activeConversationItem" :conversation="activeConversationItem" style="height: 100%" @loading="updateConversationLoading" />
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import ConversationListItem from './conversation-list-item'
import Conversation from './Conversation'

export default {
    name: 'Inbox',
    components: {
        ConversationListItem,
        Conversation,

    },
    data(){
        return {
            activeConversation: null,
            conversationLoading: false,

            showConversationDialog: false,

            newMessage: '',
            loading: false,

            searchQuery: '',

            /* conversations: [
                {
                    id: 1,
                    title: 'Shaikh Ahmed dsg dsg sdgdsg sdg',
                    avatar: 'http://riwaqalazhar.api/storage/courses/Understanding_the_Quran_mmCv9.jpg',
                    last_message: {
                        id: 1,
                        text: "Ok That's great!",
                        created_at: '',
                        created_at_timestamp: 1636391319799,
                    },
                },
                {
                    id: 2,
                    title: 'Shaikh Ahmed',
                    avatar: 'http://riwaqalazhar.api/storage/courses/Understanding_the_Quran_mmCv9.jpg',
                    last_message: {
                        id: 1,
                        text: "Ok That's great!",
                        created_at: '',
                        created_at_timestamp: 1636391319799,
                    },
                },
                {
                    id: 3,
                    title: 'Shaikh Ahmed',
                    avatar: 'http://riwaqalazhar.api/storage/courses/Understanding_the_Quran_mmCv9.jpg',
                    last_message: {
                        id: 1,
                        text: "Ok That's great!",
                        created_at: '',
                        created_at_timestamp: 1636391319799,
                    },
                },
                {
                    id: 4,
                    title: 'Shaikh Ahmed',
                    avatar: 'http://riwaqalazhar.api/storage/courses/Understanding_the_Quran_mmCv9.jpg',
                    last_message: {
                        id: 1,
                        text: "Ok That's great!",
                        created_at: '',
                        created_at_timestamp: 1636391319799,
                    },
                },
                {
                    id: 5,
                    title: 'Shaikh Ahmed',
                    avatar: 'http://riwaqalazhar.api/storage/courses/Understanding_the_Quran_mmCv9.jpg',
                    last_message: {
                        id: 1,
                        text: "Ok That's great!",
                        created_at: '',
                        created_at_timestamp: 1636391319799,
                    },
                },
                {
                    id: 6,
                    title: 'Shaikh Ahmed',
                    avatar: 'http://riwaqalazhar.api/storage/courses/Understanding_the_Quran_mmCv9.jpg',
                    last_message: {
                        id: 1,
                        text: "Ok That's great!",
                        created_at: '',
                        created_at_timestamp: 1636391319799,
                    },
                },
                {
                    id: 7,
                    title: 'Shaikh Ahmed',
                    avatar: 'http://riwaqalazhar.api/storage/courses/Understanding_the_Quran_mmCv9.jpg',
                    last_message: {
                        id: 1,
                        text: "Ok That's great!",
                        created_at: '',
                        created_at_timestamp: 1636391319799,
                    },
                },
                {
                    id: 8,
                    title: 'Shaikh Ahmed',
                    avatar: 'http://riwaqalazhar.api/storage/courses/Understanding_the_Quran_mmCv9.jpg',
                    last_message: {
                        id: 1,
                        text: "Ok That's great!",
                        created_at: '',
                        created_at_timestamp: 1636391319799,
                    },
                },
            ], */
        };
    },
    computed:{
        ...GlobalComputed,
        activeConversationItem(){
            return this.activeConversation ? this.conversations.find(c=>c.id === this.activeConversation) : null;
        },
        conversations(){
            const conversations = this.$store.state.inboxModule.conversations;
            if (this.searchQuery){
                return conversations.filter(conversation=>{
                    return conversation.title.toLowerCase().search(this.searchQuery.toLowerCase()) !== -1;
                });
            }else{
                return conversations;
            }
        },
        sortedConversations(){
            let conversations = this.conversations;
            conversations = conversations.sort((a, b)=>{
                let aTimestamp = a.last_message ? a.last_message.created_at_timestamp : a.created_at_timestamp;
                let bTimestamp = b.last_message ? b.last_message.created_at_timestamp : b.created_at_timestamp;
                return bTimestamp - aTimestamp;
            });

            return conversations;
        }
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
        activeConversation(active){
            if (active){
                this.showConversationDialog = true;
            }
        }
    },
    methods:{
        ...GlobalMethods,
        loadConversations(){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('getConversations').then(()=>{
                    this.loading = false;
                }).catch(e=>{
                    this.showMsg('Error occurred during preforming your action', 'error');
                    this.loading = false;
                })
            }
        },
        openConversation(conversation){
            if (this.activeConversation !== conversation.id){
                this.activeConversation = conversation.id;
            }else{
                // if clicked on active conversation, mark it as seen
                this.updateSeen(conversation);
            }
        },
        updateConversationLoading(loading){
            this.conversationLoading = loading;
        },
        updateSeen(conversation){
            if (!conversation.seen){
                this.$store.commit('UPDATE_CONVERSATION_DATA', {id: conversation.id, seen: true});
            }
        },
        closeConversationDialog(){
            this.showConversationDialog = false;
            window.setTimeout(()=>{
                this.activeConversation = null;
            }, 500);
        },
        backButtonHandler(){
            if (this.showConversationDialog){
                this.closeConversationDialog();
            }else{
                this.$router.go(-1);
            }
        }
    },
    mounted(){
        if (!this.conversations || this.conversations.length === 0){
            this.loadConversations();
        }

        if (this.capRunning){
            document.addEventListener('backbuttonclicked', this.backButtonHandler);
        }
    },
    beforeDestroy(){
        document.removeEventListener('backbuttonclicked', this.backButtonHandler);
    }

}
</script>

<style lang="scss" scoped>
    .inbox-wrapper{
        overflow: hidden;
        @media(min-width: 768px){
            min-height: 80vh;
            max-height: 80vh;
            // box-shadow: 0 1px 1px 0 rgba(#000,.06),0 2px 5px 0 rgba(#000,.2)!important;
        }
        @media(max-width: 768px){
            min-height: 90vh;
        }
        .navigate-aside{
            height: 100%;
            border-right: 1px solid rgba(#000, 0.1);
        }
        .v-toolbar{
            border-bottom: 1px solid rgba(#000, 0.05)!important;
        }
    }
</style>
<style lang="scss">
.inbox-search{
    input{
        padding-top: 5px!important;
        padding-bottom: 5px!important;
    }
}
</style>