<template>
    <v-tooltip bottom transition="fade-transition" color="primary">
        <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs" color="orange lighten-5" class="py-1 px-4 orange--text text--darken-3" v-if="isStudent" :to="{name: 'wallet'}">
                <v-icon size="19" left>mdi-wallet</v-icon>
                <span class="font-weight-medium">{{moneyFormat(creditsValue, true)}}</span>
            </v-chip>
        </template>
        <div>
            <!-- <v-icon color="white" size="20" left>mdi-wallet-outline</v-icon> -->
            <span>Account Balance</span>
        </div>
    </v-tooltip>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    props: {
        credits: {default: null}
    },
    data(){
        return {

        }
    },
    computed: {
        creditsValue(){
            return this.credits ? this.credits : this.user.credits;    
        },
        ...GlobalComputed
    },
    methods: {
        ...GlobalMethods
    }
};
</script>
