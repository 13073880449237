<template>  
<v-snackbar v-if="appData.announcement" v-model="announcement" transition="slide-y-transition" color="grey darken-4" :timeout="15000" :top="pcOnly" :bottom="!pcOnly" class="px-6">
    <div class="d-flex align-center">
        <v-avatar class="me-2" style="background-color: rgba(0,0,0, .15)">
            <v-icon color="primary">mdi-bullhorn-outline</v-icon>
        </v-avatar>
        <div v-if="appData" style="white-space: pre-line;" v-html="urlify(escapeHtmlText(appData.announcement))"></div>
    </div>
    <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="announcement = false" >Close</v-btn>
    </template>
</v-snackbar>
</template>

<script>
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';
import GlobalComputed from '../../helpers/global-computed';

export default {
    props: {},

    data(){
        return {
            announcement: false,
        }
    },
    computed: {
        ...GlobalComputed,

    },
    methods: {
        escapeHtmlText(text){
            // to prevent XSS attacks
            return text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
        },
        urlify(text){
            const options = { defaultProtocol: 'https' , target: '_system'};
            return linkifyHtml(text, options);
        },
    },
    mounted(){
        if (this.appData.announcement){
            this.announcement = true;
        }
    }
}
</script>
