<template>
    <v-autocomplete v-model="selected" :items="itemsComputed" :placeholder="isTeacher ? 'Select Student' : 'Select Course'" outlined hide-details />
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    data(){
        return {
            selected: '',
            items: null,
        }
    },
    props: {
        value: {},
    },
    computed: {
        ...GlobalComputed,
        itemsComputed(){
            return this.items ? this.items.map(item=>{
                return {text: `${this.isTeacher ? item.student.name + ' - ' : ''}${item.course.name}`, value: item.id};
            }) : [];
        }
    },
    watch: {
        value(value){
            if (value !== this.selected){
                this.selected = value;
            }
        },
        selected(selected){
            if (selected !== this.value){
                this.$emit('input', selected);
            }
        }
    },
    methods: {
        loadItems(){
            this.$store.dispatch('fastGetAction', {url: '/my-enrollments'}).then(response=>{
                this.items = response.data.data;
            })
        },
        ...GlobalMethods
    },
    mounted(){
        if (!this.items || !this.items.length){
            this.loadItems();
        }
    }
};
</script>

<style lang="scss" scoped>

</style>