import Vue from 'vue';
import $router from '@/router/index'

import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import Notifications from './notificatons'

import Colors from '@/helpers/colors'

Vue.directive('haptic', {})

export default {
    Notifications,
    async changeStatusbar(dark=false, primary=false){
        const bg = dark ? '#000000': (primary ? Colors.primary : '#e79007');
        await StatusBar.setBackgroundColor({color: bg})
        
        return StatusBar.setStyle({
            style: dark || primary ? Style.Dark : Style.Light
        });
    },
    async getAppInfo(){
        const info = await App.getInfo();
        return info;
    },
    async getAppVersion(){
        const info = await this.getAppInfo();
        return info.version;
    },
    hapticsImpact(style = ImpactStyle.Heavy) {
        Haptics.impact({style: style} );
    },
    hapticsImpactMedium() {
        this.hapticsImpact(ImpactStyle.Medium);
    },
    
    hapticsImpactLight() {
        this.hapticsImpact(ImpactStyle.Light);
    },
    
    hapticsVibrate() {
        Haptics.vibrate();
    },
    async fireTouchHaptic(){
        // this.hapticsImpactLight();
        if (window.nativeclick){
            window.nativeclick.trigger();
        }
    },
    async setHapticsDirective(){
        const self = this;
        // setting "v-haptic" directive to apply it to any element we need "haptics" sound ..
        Vue.directive('haptic', {
            bind(el, binding, vnode){
                el.addEventListener('click', ()=>{
                    self.fireTouchHaptic();
                });
            }
        });

        // fire touch haptic with "v-ripple" directive so buttons, cards .. etc that have applied "v-ripple" directive will auto fire touch haptic
        /* Vue.directive('ripple', {
            bind(el, binding, vnode){
                el.addEventListener('touchstart', ()=>{
                    self.fireTouchHaptic();
                });
            }
        }); */
        return true;
    },
    setBackButtonListener($self){

        App.addListener('backButton', () => {

            // dispatch event to document... so js code can catch it!
            var event = new window.CustomEvent("backbuttonclicked", {detail: {} });
            // Dispatch/Trigger/Fire the event
            window.document.dispatchEvent(event);
            
            if (!['home', 'calendar', 'inbox'].includes($self.$route.name)){
                $self.$router.go(-1);
            }
        });
    },
    async init($self){
        window.setTimeout(async ()=>{
            SplashScreen.hide();
            // console.log('Hiding Splashscreen ...')
        }, 200)

        this.setBackButtonListener($self);
        this.setHapticsDirective();
    }
}