<template>
    <div>
        <v-dialog ref="dialog" v-model="modal" :return-value.sync="time" :persistent="persistent" width="290px">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field :color="color" :value="timeValue" :dense="dense" :disabled="disabled" :outlined="outlined" :label="label" :placeholder="placeholder" :prepend-inner-icon="!hideIcon ? 'mdi-clock-time-four-outline' : ''" readonly hide-details v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-time-picker v-if="modal" v-model="time" scrollable :min="min" :max="max" :color="color" ampm-in-title full-width>
                <v-spacer></v-spacer>
                <v-btn text color="muted" @click="modal = false">
                    Cancel
                </v-btn>
                <v-btn text :color="color" @click="$refs.dialog.save(time)">
                    Confirm
                </v-btn>
            </v-time-picker>
        </v-dialog>
    </div>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    data(){
        return {
            time: '',
            modal: false,
        }
    },
    props: {
        label: {},
        value: {},
        color: {type: String, default: 'primary'},
        hideIcon: {type: Boolean, default: false},
        outlined: {type: Boolean, default: false},
        dense: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        persistent: {type: Boolean, default: false},
        halfFormat: {type: Boolean, default: false},
        min: {},
        max: {},
        placeholder: {},
        replaces: Array,
    },
    computed: {
        timeValue(){
            const time = this.time,
                timeArr = time.split(':');
            if (!time){
                return '';
            }
            
            if (timeArr[0] < 12){
                const hours = parseInt(timeArr[0]) === 0 ? '12' : timeArr[0];
                return `${hours}:${timeArr[1]} AM`;
            }else{
                let hours = parseInt(timeArr[0]) - 12;
                if (hours === 0){
                    hours = 12;
                }
                const hoursValue = hours < 10 ? '0' + hours : hours;
                return `${hoursValue}:${timeArr[1]} PM`
            }
        },
        ...GlobalComputed
    },
    watch: {
        time(time, old){
            if (time === ':00'){
                this.time = old;
            }else{
                if (time){
                    if (this.replaces && this.replaces.length){
                        this.applyReplaces(time);
                    }
                    if (this.halfFormat){
                        this.halfFormatTime();
                    }
                }

                if (time !== this.value){
                    this.$emit('input', time); // close
                }
            }
        },
        value(time){
            if (time !== this.time){
                this.time = time;
            }
        }
    },
    methods: {
        halfFormatTime(){
            let time = this.time,
                timeArr = time.split(':');
            if (!['00', '15', '30', '45'].includes(timeArr[1]) && time !== '23:59'){
                // minutes are not allowed

                const nearMintes = parseInt(timeArr[1]) > 30 ? '30' : '00';
                const newTime = `${timeArr[0]}:${nearMintes}`;
                this.time = newTime;

                this.showMsg(`${newTime} used instead of ${time}`, 'muted', false, false, 2000);
            }
        },
        applyReplaces(time){
            const replaces = this.replaces;
            if (time){
                replaces.forEach(replace=>{
                    if (time === replace[0]){
                        this.time = replace[1];
                    }
                })
            }
        },
        ...GlobalMethods
    },
    mounted(){
        if (this.value){
            this.time = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>

</style>