<template>
    <div class="page">
        <v-container :class="{'px-0': phoneOnly}">
            <!-- <h1 class="mb-2">classes</h1>
            <p>Hello, welcome to Riwaq Al Quran Portal</p> -->

            <salary-table :classes="salaryReport.classes" :salary="salaryReport.salary" :classes-count="salaryReport.classes_count" :loading="loading" />
        </v-container>
        
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import SalaryTable from './salary-table'

export default {
    name: "salary-report",
    components: {
        SalaryTable
    },
    data(){
        return {
            searchQuery: '',
            loading: false,
        }
    },
    computed: {
        salaryReport(){
            return this.$store.state.historyModule.salaryClasses;
        },
        ...GlobalComputed
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
    },
    methods: {
        loadData(){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('loadSalaryClasses').then(()=>{
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        ...GlobalMethods
    },
    mounted(){
        if (this.appData && !this.appData.enable_app_w){
            this.$router.push('/not-found');
        }else{
            if (!this.salaryReport.classes || this.salaryReport.classes.length === 0){
                this.loadData();
            }
        }
    }
};
</script>
