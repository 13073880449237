<template>
    <v-menu v-model="notificationsMenu" :close-on-content-click="false" :offset-y="true" :left="pcOnly" transition="slide-y-transition"> <!-- fade-up -->
        <template v-slot:activator="{}">
            <v-btn icon color="text" class="icon-wrapper" @click.prevent="openNotifications">
                <v-badge class="notification-badge" :value="!!newNotifications" color="ebadge" bordered :content="newNotifications" overlap right>
                    
                    <svg v-if="!newNotifications" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M10,21H14A2,2 0 0,1 12,23A2,2 0 0,1 10,21M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M17,11A5,5 0 0,0 12,6A5,5 0 0,0 7,11V18H17V11M19.75,3.19L18.33,4.61C20.04,6.3 21,8.6 21,11H23C23,8.07 21.84,5.25 19.75,3.19M1,11H3C3,8.6 3.96,6.3 5.67,4.61L4.25,3.19C2.16,5.25 1,8.07 1,11Z"></path></svg>
                    <svg v-else class="v-icon__svg strong-text--text" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path></svg>
                    
                    <!-- <v-icon v-else size="24">{{newNotifications ? 'mdi-bell' : 'mdi-bell-outline' }}</v-icon> -->
                </v-badge>
            </v-btn>
        </template>
        <v-list width="400" max-height="600" style="max-width: 100%;">
            <div class="d-flex justify-space-between pb-2 px-4 align-center">
                <div class="medium-text">Notifications</div>
                <div class="details-text" :class="{'primary--text': unseenCount, 'grey--text': !unseenCount}">{{unseenText}}</div>
            </div>

            <v-divider />
            
            <vue-custom-scrollbar class="scroll-area" :wheelPropagation="false" :scrollingThreshold="500">
                <div class="notifications-wrapper">
                    <div v-for="notification in notifications" :key="notification.id" class="notification-wrapper">
                        <notification :notification="notification" @navigated="notificationsMenu = false" />
                    </div>
                </div>

                <div class="d-flex justify-center align-center mt-2">
                    <v-btn text small :disabled="!existsMore" color="primary darken-2" @click="showMore = true">show more notifications</v-btn>
                </div>
            </vue-custom-scrollbar>
            
        </v-list>
    </v-menu>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'
import Notification from '../../default/notification'
import vueCustomScrollbar from 'vue-custom-scrollbar'

// import GlobalMethods from '@/helpers/global-methods'

export default {
    data(){
        return {
            notificationsMenu: false,
            showMore: false,
            limit: 10,
        }
    },
    components: {
        Notification,
        vueCustomScrollbar
    },
    computed: {
        ...GlobalComputed,
        existsMore(){
            return this.allNotifications.length > this.notifications.length;
        },
        notifications(){
            let notifications = this.allNotifications;
            if (!notifications){
                return [];
            }
            return this.showMore ? notifications : notifications.slice(0, this.limit);
        },
        allNotifications(){
            return this.user.notifications;
        },
        unseenCount(){
            let unseen = this.allNotifications.filter(n=>{
                return !n.read;
            });
            return unseen.length;
        },
        unseenText(){
            let count = this.unseenCount;
            if (count){
                if (count){
                    return '1 New Notification';
                }else if (count){
                    return count + ' New Notifications'
                }
            }
            return 'No New Notifications';
        },
        newNotifications(){
            let unread = this.user.notifications_count;
            // let unread = 122;
            return unread > 99 ? '+99' : unread;
        },
    },
    methods: {
        markAllAsRead(){
            if (this.user.notifications_count > 0){
                this.$store.dispatch('markAllAsRead');
            }
        },
        openNotifications(){
            if (this.notifications.length){
                this.notificationsMenu= !this.notificationsMenu;
                window.setTimeout(()=>{
                    this.markAllAsRead();
                }, 2000);
            }
        }
    }
}
</script>

<style lang="scss">

    .scroll-area {
        position: relative;
        margin: auto;
        max-height: 500px!important;
    }

    .notification-badge.v-badge--bordered .v-badge__badge{
        // font-weight: bold;
        &::after{
            // right: 2px!important;
        }
    }
</style>