<template>
    <v-dialog class="force-update-dialog" v-model="forceUpdate" persistent no-click-animation transition="none" fullscreen fluid>
        <v-container fluid fill-height class="d-flex align-center justify-space-between flex-column force-update-overlay">
            <div class="pa-6">
                <logo :link="false" />
            </div>
            <div class="px-6 pb-12">
                <h2 class="font-weight-bold text-center primary--text mb-6">Update Required</h2>
                <p class="text-center grey--text medium-text">
                    The version you have of our app is old, please update to get latest features.
                </p>
            </div>
            <div class="w-100 pa-4">
                <v-btn color="primary" block depressed large class="font-weight-bold mt-6" @click="openAppStore">
                    <v-icon left>mdi-cellphone-arrow-down</v-icon>
                    Update
                </v-btn>
            </div>
            <!-- <div class="primary--text medium-text" style="font-weight: 600"></div> -->
            <!-- <div class="icon-wrapper primary">
                <v-icon color="white" size="40">mdi-download</v-icon>
            </div> -->
        </v-container>
    </v-dialog>    
</template>
<script>
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import Logo from '@/components/main/logo'
import AppAPI from '@/app-api'
import semverLt from 'semver/functions/lt'

export default {
    data(){
        return {
            forceUpdate: false,
        }    
    },
    components: {Logo},
    computed: {
        ...GlobalComputed,
        url(){
            if (this.appData){
                let platform = this.platform;
                if (platform === 'android'){
                    return this.appData.play_store_url;
                }else if (platform === 'ios'){
                    return this.appData.apple_store_url;
                }
            }
        
            return null;
        },
    },
    methods: {
        ...GlobalMethods,
        openAppStore(){
            return window.open(this.url, '_system');
        },
        async checkVersionForceUpdate(){
            if (this.capRunning){
                const version = await AppAPI.getAppVersion();
                let stableVersion = this.appData.stable_version;
                if (this.platform === 'android'){
                    stableVersion = this.appData.android_stable_version;
                }else if (this.platform === 'ios'){
                    stableVersion = this.appData.ios_stable_version;
                }

                if (semverLt(version, stableVersion)){
                    // if current version is little than stable version
                    // must update
                    console.log('must update');
                    this.forceUpdate = true;
                }
            }
        },
    },
    watch: {
        appLoadedOnce(){
            this.checkVersionForceUpdate();
        }
    },
    mounted(){
        this.checkVersionForceUpdate();
    }
}
</script>
<style lang="scss" scoped>
    .force-update-overlay{
        background-color: rgba(255, 255, 255, 1);
        z-index: 1000;
    }
    .icon-wrapper{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 3rem auto;
    }
</style>
