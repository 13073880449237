import Default from './default'
const state = {
    ...Default.state
};
const mutations = {
    ...Default.mutations
};
const actions = {
    ...Default.actions,

};

export default {state, mutations, actions};