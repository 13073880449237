export default {
    state: {
        conversations: [],
    },
    mutations: {
        SET_CONVERSATIONS(state, payload){
            state.conversations = payload.conversations;
        },
        UPDATE_CONVERSATION_DATA(state, data){
            state.conversations = state.conversations.map(item=>{
                if (item.id === data.id){
                    Object.assign(item, data);
                }
                return item;
            });
        },
        UPDATE_CONVERSATION(state, conversation){
            let conversations = state.conversations;
            if (conversations && conversations.length){
                conversations = conversations.filter(item=>{
                    return item.id !== conversation.id;
                });
                conversations.unshift(conversation);
                state.conversations = conversations;
            }
        },
        
    },
    actions: {
        getConversations({commit}){
            let url = `/conversations`;
            return new Promise((resolve, reject)=>{
    
                window.axios.get(url).then(response=>{
                    let conversations = response.data.data;
                    if (conversations && conversations.length){
                        commit('SET_CONVERSATIONS', {conversations}); 
                    }
                    resolve();
                }).catch(error=>{
                    reject(error);
                });
            })
        },

        getConversationMessages({commit}, payload){
            let url = payload.next ? payload.next : `/conversations/${payload.id}`;
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                });
            });
        },
        newChatMsg({commit}, payload){
            let url = `/conversations/${payload.id}`;
            return new Promise((resolve, reject)=>{
                window.axios.post(url, {text: payload.text, type: 'text'}).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        newChatImageMsg({commit}, payload){
            let url = `/conversations/${payload.id}`;
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.formData, {headers: {'Content-Type': 'multipart/form-data', 'Accept': 'application/x-www-form-urlencoded'}}).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        
    }
}