<template>
        <!-- image viewer -->
        <viewer :options="options" :images="[src]" @inited="inited" class="viewer" ref="viewer">
            <img v-show="false" :src="src" :key="src">
        </viewer>
</template>

<script>
import Vue from 'vue'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)

export default {
    props: ['src'],
    data(){
        return {
            
            options: {
                movable: false,
                title: false,
                navbar: false,
                toolbar: {
                    zoomOut: true,
                    rotateLeft: true,
                    reset: true,
                    rotateRight: true,
                    zoomIn: true,
                    
                    oneToOne: false,
                    prev: false,
                    play: false,
                    next: false,
                    flipHorizontal: false,
                    flipVertical: false,
                },
            }
        }
    },
    methods: {
        inited(viewer){
            this.$emit('inited', viewer);
        }
    }
}
</script>


<style lang="scss">
.viewer-backdrop{
    background-color: rgba(0,0,0, .95)!important;
}
.viewer-toolbar {
    margin-bottom: 1.5rem;
    ul li{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        width: 42px;
        margin: 0 0.35rem;
    }
}
</style>