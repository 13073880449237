<template>
    <v-card flat class="course-card bordered d-flex flex-column">
        <div class="course-image">
            <div class="floating-badges">
                <v-chip v-if="course.featured" dark small color="primary" class="font-weight-medium me-1">
                    <v-icon size="18" left class="me-1">mdi-star-circle-outline</v-icon>
                    Featured
                </v-chip>
                <v-chip v-if="course.status && isStudent" dark small :color="statusColor">{{handleStatus(course.status).text}}</v-chip>
            </div>
            <router-link :to="to">
                <v-img v-ripple height="160" :src="course.image_url" />
            </router-link>
        </div>
        <div class="course-details d-flex flex-column align-stretch py-4 px-4 flex-grow-1">
            <div class="d-flex justify-space-between">
                <div class="category">
                    <v-chip small label color="">{{course.category_name}}</v-chip>
                </div>
            </div>
            <div class="course-name mt-3 mb-1">
                <router-link class="strong-text--text" :to="to">
                    {{course.name}}
                </router-link>
            </div>
            <div class="course-description details-text muted--text flex-grow-1">
                {{course.description}}
            </div>
            <div v-if="isStudent && pcOnly" class="action mt-4">
                <v-btn dark :to="to" :exact="false" block depressed :color="statusColor || 'primary'">{{enrolled ? 'Open Course Page' : 'Course Details'}}</v-btn>
            </div>
        </div>
    </v-card>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    data(){
        return {

        }
    },
    props: {
        course: Object,
        enrolled: {type: Boolean, default: false}
    },
    computed: {
        statusColor(){
            if (this.course.status){
                return this.handleStatus(this.course.status).color;
            }
            return null;
        },
        to(){
            if (this.isStudent){
                return {name: 'course', params: {id: this.course.id}};
            }
            return {};
        },
        ...GlobalComputed
    },
    methods: {
        ...GlobalMethods
    }
};
</script>

<style lang="scss" scoped>
.course-card{
    overflow: hidden;
    border-radius: 6px;

    transition: 0.2s ease-in-out all;

    &:hover{
        transform: translateY(-4px);
        box-shadow: 0 4px 13px -1px rgba(0,0,0, .1)!important;
    }

    .course-name{
        font-size: 1rem;
        font-weight: 500;

        a{
            text-decoration: none;

            &:hover{
                opacity: 0.8;
            }
        }
    }
    .course-description{
        
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    .floating-badges{
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.6rem 0.8rem;
        z-index: 2;
    }
}
</style>