<template>
    <v-card flat class="bordered d-flex flex-column flex-lg-row justify-space-between align-center py-4 px-5">
        <div class="d-flex align-center">
            <div class="me-4">
                <v-avatar tile width="70" height="55" style="border-radius: 5px!important">
                    <v-img :src="classData.enrollment.course.image_url" />
                </v-avatar>
            </div>
            <div>
                <h3 class="strong-text--text mb-1 font-weight-medium nowrap">
                    <span v-if="isTeacher">{{classData.enrollment.student.name}} - </span>
                    <span :class="{'medium-text muted--text': isTeacher}">{{classData.enrollment.course.name}}</span>
                </h3>
                <div class="d-flex">
                    <v-chip v-if="running" small class="font-weight-medium" dark color="blue darken-1" label>Running</v-chip>
                    <div v-else>
                        <v-chip class="font-weight-medium" text small>
                            <v-icon small left>mdi-clock-outline</v-icon>
                            <!-- {{classData.start_readable}} -->
                            {{timeAgo}}
                        </v-chip>
                    </div>
                </div>

            </div>
        </div>
        <div class="mt-4 mt-lg-0" :class="{'w-full': phoneOnly}">
            <v-btn v-if="running" :block="phoneOnly" depressed color="blue darken-1" dark @click="detailsDialog = true">
                <v-icon left size="20">mdi-motion-play-outline</v-icon>
                Join Class
            </v-btn>
            <v-btn v-else depressed :block="phoneOnly" color="primary" @click="detailsDialog = true">
                <v-icon left size="20">mdi-text</v-icon>
                Class Details
            </v-btn>
        </div>

        <v-dialog v-model="detailsDialog" scrollable :fullscreen="phoneOnly" :fluid="phoneOnly" full max-width="375" overlay-color="#000000">
            <class-card :class-data="classData" @close="detailsDialog = false" :reloading="reloading" />
        </v-dialog>
    </v-card>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import ClassCard from '@/components/custom/class-card'


export default {
    components: {ClassCard},
    data(){
        return {
            detailsDialog: false,
            
            intervalid: null,
            timeAgo: '',
        }
    },
    props: {
        classData: {},
        reloading: {type: Boolean, default: false}
    },
    computed: {
        running(){
            return this.classData.status === 'running';
        },
        ...GlobalComputed
    },
    methods: {
        ...GlobalMethods,

        timeAgoFormat(time) {
            const self = this;
            self.timeAgo = self.timeSince(time, true);

            // update time every 1s
            this.intervalid = setInterval(function () {
                self.timeAgo = self.timeSince(time, true);
            }, 1000);
        },
        backButtonHandler(){
            if (this.detailsDialog){
                this.detailsDialog = false;
            }else{
                this.$router.go(-1);
            }
        }
    },
    watch: {
        classData(){
            this.timeAgoFormat(this.classData.start);
        }
    },
    mounted(){
        this.timeAgoFormat(this.classData.start);

        if (this.capRunning){
            document.addEventListener('backbuttonclicked', this.backButtonHandler)
        }
    },
    beforeDestroy(){
        if (this.intervalid){
            window.clearInterval(this.intervalid);
        }

        if (this.capRunning){
            document.removeEventListener('backbuttonclicked', this.backButtonHandler)
        }
    }
};
</script>
