<template>
    <div class="page">
        <transition name="fade-transition">
            <v-container v-if="walletData">
                <v-card v-if="appData" class="soft-shadow px-3 pb-1 mb-4" style="max-width: 625px">
                    <v-card-text>
                        <div class="d-flex w-100 justify-space-between">
                            <div class="text-left flex-grow-1">
                                <div class="mb-4">
                                    <div class="mb-2">Account Balance</div>
                                    <h1 class="strong-text--text font-weight-bold">{{moneyFormat(user.credits)}}</h1>
                                </div>
                                <div>
                                    <div class="details-text">Borrowed Balance</div>
                                    <h3 class="font-weight-medium text--darken-1" :class="{'error--text': !!user.loan_credits}">{{moneyFormat(user.loan_credits)}}</h3>
                                </div>
                            </div>
                            <div>
                                <v-btn color="deep-purple" :dark="user.can_borrow && maxBorrowAmount !== 0" outlined depressed small @click="borrowDialog = true;" :disabled="!user.can_borrow || maxBorrowAmount === 0">
                                    <v-icon size="20" left>mdi-cash-plus</v-icon>
                                    <span>Borrow</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
                
                <v-card flat style="max-width: 625px" :class="{'soft-shadow px-3': pcOnly}">
                    <v-card-title>Recharge</v-card-title>
                    <v-card-subtitle>Recharge your wallet using securely supported payment methods</v-card-subtitle>
                    <v-card-text class="mt-2">

                        <v-alert v-if="success" text dense color="success">
                            <div class="strong-text--text details-text">
                                <v-icon color="success" class="me-2">mdi-check-circle</v-icon>
                                <span>You've successfully recharged your wallet.</span>
                            </div>
                        </v-alert>
                        <v-alert v-if="walletData.pending_payments_count" text dense color="primary">
                            <div class="strong-text--text details-text">
                                <v-icon color="primary" class="me-2">mdi-progress-clock</v-icon>
                                <span>We are currently reviewing <b>{{walletData.pending_payments_count}} {{ walletData.pending_payments_count > 1 ? 'Payments' : 'Payment'}}</b>.</span>
                            </div>
                        </v-alert>

                        <v-form @submit.prevent class="mb-1">
                            <v-text-field v-model="rechargeAmount" :loading="recharging" :disabled="recharging" color="orange" hide-details class="recharge-input font-weight-medium" type="number" outlined label="Amount" placeholder="Enter amount of recharge" suffix="$" :min="minRechargeAmount" @blur="checkAmount"></v-text-field>
                        </v-form>
                        <v-list>
                            <v-list-item v-for="method in paymentMethods" :key="method.value" :disabled="recharging || rechargeAmount < minRechargeAmount" :class="{'v-list-item--active': selectedMethod === method.value, 'bordered': selectedMethod !== method.value, [`grey--text`]: selectedMethod === method.value}" class="payment-method-item rounded py-0 mb-2 overflow-hidden" v-ripple="phoneOnly" @click="selectedMethod = method.value">
                                <v-list-item-icon class="me-3 my-auto">
                                    <!-- <v-radio v-model="selectedMethod" :color="method.color || 'primary'" :value="method.value" /> -->
                                    <v-btn icon :color="selectedMethod === method.value ? 'text' : 'text'" style="margin-top: -4px">
                                            <svg v-if="selectedMethod === method.value" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M12,7C9.24,7 7,9.24 7,12C7,14.76 9.24,17 12,17C14.76,17 17,14.76 17,12C17,9.24 14.76,7 12,7Z"></path></svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M12,20C7.58,20 4,16.42 4,12C4,7.58 7.58,4 12,4C16.42,4 20,7.58 20,12C20,16.42 16.42,20 12,20M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2Z"></path></svg>
                                    </v-btn>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="d-flex justify-space-between align-center mb-0" style="line-height: 0!important;">
                                        <div class="flex-grow-1">
                                            <img v-if="method.img" :src="method.img" style="width: auto" :style="`height: ${method.height}; max-width: ${method.maxWidth}`" />
                                            <v-icon v-else-if="method.icon" size="28" :color="'primary'" class="ms-1">{{method.icon}}</v-icon>
                                        </div>
                                        <div v-if="method.reward" class="me-3">
                                            <v-chip label small color="primary">
                                                <b v-if="pcOnly">GET {{method.reward}} REWARD</b>
                                                <b v-else>{{method.reward}} REWARD</b>
                                            </v-chip>
                                        </div>
                                    </v-list-item-title>
                                    <!-- <v-list-item-subtitle class="details-text mt-1" style="line-height: 1.4!important">{{method.subtitle}}</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="capRunning" @click="openWeb" dense class="payment-method-item rounded py-0 mt-3 overflow-hidden grey lighten-3">
                                <v-list-item-icon class="me-3">
                                    <v-icon color="indigo" style="transform: rotate(-90deg)">mdi-open-in-new</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="d-flex justify-space-between align-center mb-0">
                                        <div class="flex-grow-1 details-text">
                                            More methods are available on <strong>Web</strong>
                                        </div>
                                    </v-list-item-title>
                                    <!-- <v-list-item-subtitle class="details-text mt-1" style="line-height: 1.4!important">{{method.subtitle}}</v-list-item-subtitle> -->
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-expand-transition appear>
                            <div v-if="selectedMethod" class="mb-2 mt-6">
                                <div v-if="selectedMethod === 'paypal'" >
                                    <v-btn text small color="muted" class="mt-2 mb-2" dense @click="coursesPricesDialog = true" style="text-transform: none">Don't know how much to recharge?</v-btn>

                                    <div class="mt-4">
                                        <paypal-buttons :disabled="recharging || rechargeAmount < minRechargeAmount" :amount="rechargeAmount" @captured="completeRecharge" @error="showMsg('Error occurred during processing your payment, plase contact support.')" />
                                    </div>
                                </div>
                                <div v-else-if="selectedMethod === 'stripe'" class="mb-4">
                                    <stripe-form :disabled="recharging || rechargeAmount < minRechargeAmount" :amount="rechargeAmount" :email="user.email" />
                                </div>
                                <div v-else-if="selectedMethod === 'wise'">
                                    <!-- Manual Payments -->
                                    
                                    <div class="payment-gateway-buttons">
                                        <transition name="slide-x-transition" mode="out-in">
                                            <div v-if="ebmGatewayStep === 0" class="px-md-4" key="0">
                                                <div class="mb-2 text-left">
                                                    <img v-if="selectedPaymentMethod.img" :src="selectedPaymentMethod.img" style="max-height: 40px; max-width: 105px; width: auto" />
                                                </div>
                                                <div class="my-4">
                                                    <h3 class="strong-text--text mb-3">Follow these instructions:</h3>
                                                    <div class="manual-steps black--text text-left" style="font-size: 15px; line-height: 1.8rem; white-space: pre-line;" v-html="parseSteps(selectedPaymentMethod.steps)"></div>
                                                </div>
                                                
                                                <v-btn block depressed color="primary" large @click="ebmGatewayStep = 1;" class="mt-6">
                                                    <strong>Payment Done ?</strong>
                                                    <v-icon right style="opacity: 0.6">mdi-chevron-right</v-icon>
                                                </v-btn>
                                            </div>
                                            <div v-else-if="ebmGatewayStep === 1" key="1">
                                                <v-form ref="form">
                                                    <v-textarea v-model="payMessage" no-resize rows="4" placeholder="Enter Required Info about transaction or Leave us a message" outlined :disabled="recharging"></v-textarea>
                                                    <v-file-input v-model="payAttachment" accept="image/*" outlined placeholder="Attach a screenshot or an receipt (optional)" prepend-icon="" prepend-inner-icon="mdi-attachment" :disabled="recharging"></v-file-input>
                                                </v-form>

                                                <div class="w-100 mt-5">
                                                    <v-btn block :disabled="recharging || !payMessage" :loading="recharging" depressed color="primary" large @click="rechargeViaEbmGateway">
                                                        <v-icon size="24" left>mdi-cash-check</v-icon>
                                                        <strong>Submit</strong>
                                                    </v-btn>
                                                    <v-btn block :disabled="recharging" depressed text @click="ebmGatewayStep = 0;" class="mt-2">
                                                        <v-icon size="24" left>mdi-chevron-left</v-icon>
                                                        <span>Back</span>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </transition>
                                        
                                    </div>

                                </div>
                            </div>
                        </v-expand-transition>
                    </v-card-text>
                </v-card>
            </v-container>
        </transition>
        <v-dialog v-model="borrowDialog" scrollable max-width="350" overlay-color="#000" overlay-opacity="0.75">
            <v-card>
                <v-card-title class="mb-2">Borrow Balance</v-card-title>
                <v-card-subtitle>Borrow balance up to {{moneyFormat(user.max_borrow_amount)}}, you cannot exceed  this amount before next recharge.</v-card-subtitle>
                <v-card-text class="py-1">
                        <v-text-field v-model="borrowAmount" color="deep-purple" type="number" :disabled="maxBorrowAmount === 0" outlined prepend-inner-icon="mdi-wallet" min="1" :max="maxBorrowAmount" label="Amount" placeholder="Amount to borrow" persistent-hint :hint="`You can borrow up to ${moneyFormat(maxBorrowAmount)}`" suffix="$" ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" @click="borrowDialog = false" :disabled="borrowLoading">cancel</v-btn>
                    <v-btn text color="deep-purple" :disabled="borrowLoading || !borrowAmount" :loading="borrowLoading" @click="borrowBalanceAction">confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="coursesPricesDialog" scrollable max-width="350">
            <v-card>
                <v-card-title class="mb-2">How much to recharge?</v-card-title>
                <v-card-subtitle>Info about your courses may help you decide how much to recharge. A recharge of 4, 5 weeks or more is preferred.</v-card-subtitle>
                <v-card-text class="px-3">
                    <v-list two-line class="pa-0">
                        <v-list-item v-for="course in myCourses" :key="course.id">
                            <v-list-item-avatar tile width="50">
                                <v-img :src="course.image_url"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title style="line-height: normal!important">{{moneyFormat(course.weekly_price)}} / week</v-list-item-title>
                                <v-list-item-subtitle>Weekly Cost</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import PaypalButtons from '@/components/custom/paypal-buttons'
import StripeForm from '@/components/custom/stripe-form'
import linkifyHtml from 'linkifyjs/lib/linkify-html';

export default {
    name: "Wallet",
    components: {
        PaypalButtons,
        StripeForm
    },
    data(){
        return {
            loading: false,
            walletData: null,

            success: false,

            rechargeAmount: '',
            recharging: false,

            coursesPricesDialog: false,

            selectedMethod: '',
            ebmGatewayStep: 0,

            payMessage: '',
            payAttachment: null,

            borrowDialog: false,
            borrowAmount: '',
            borrowLoading: false,
        }
    },
    computed: {
        ...GlobalComputed,
        paymentMethods(){
            let methods = this.walletData.payment_methods;
            if (this.capRunning){
                methods = methods.filter(method=>method.name !== 'paypal'); // paypal not supported on apps
            }
            return methods.map(method=>{
                return {value: method.name, subtitle: method.text, img: method.logo, icon: method.icon, reward: method.reward, steps: method.steps, height: method.height || '35px', maxWidth: method.maxWidth || '80px'};
            });
        },
        selectedPaymentMethod(){
            return this.paymentMethods.find(method=>method.value === this.selectedMethod);
        },
        myCourses(){
            return this.user.my_courses;
        },
        maxBorrowAmount(){
            const max_amount = this.user.max_borrow_amount;

            const borrowed = this.user.loan_credits;
            const max = borrowed ? max_amount - borrowed : max_amount;

            return max > 0 ? max : 0;
        },
        minRechargeAmount(){
            return parseFloat(this.walletData.min_recharge_amount);
        },
    },
    methods: {
        openWeb(){
            return window.open('https://portal.riwaqalquran.com', '_system');
        },
        loadData(){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('fastGetAction', {url: '/wallet'}).then(response=>{
                    this.loading = false;
                    const data = response.data.data;
                    this.walletData = data;

                    window.setTimeout(()=>{
                        if (this.$route.query.borrow == '1' && this.user.can_borrow){
                            this.borrowDialog = true;
                        }
                    }, 600);

                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        checkAmount(){
            if (this.rechargeAmount && this.rechargeAmount < this.minRechargeAmount){
                this.showMsg(`Cannot recharge less than ${this.minRechargeAmount}$`, 'error');
            }
        },
        completeRecharge(captureResponse){
            const data = {
                order_id: captureResponse.id
            };
            this.recharging = true;
            this.$store.dispatch('completeRecharge', {data}).then(()=>{
                this.rechargeAmount = '';
                this.recharging = false;
                this.showMsg('You have successfully recharged your wallet', 'success');
                this.$store.dispatch('updateNotifications');
            }).catch(e=>{
                this.showMsg('Error occurred during completing your payment, please contact support');
                this.recharging = false;
            });
        },
        rechargeViaEbmGateway(){

            let data = new FormData();
            data.append('amount', this.rechargeAmount);
            data.append('payment_method', this.selectedMethod);
            data.append('message', this.payMessage);
            if (this.payAttachment){
                data.append('attachment', this.payAttachment);
            }

            this.recharging = true;
            this.$store.dispatch('rechargeViaEbmGateway', {data}).then((response)=>{
                this.recharging = false;
                this.rechargeReset();

                if (response.data.pending_payments_count){
                    this.walletData.pending_payments_count = response.data.pending_payments_count;
                }

                this.showMsg('We are reviewing your payment and you will be notified that balance added.', 'success');
                this.$store.dispatch('updateNotifications');

            }).catch(e=>{
                this.showMsg('Error occurred during completing your payment, please contact support');
                this.recharging = false;
            });
        },
        rechargeReset(){
            this.rechargeAmount = '';
            this.payMessage = null;
            this.payAttachment = null;
            this.selectedMethod = '';
            this.ebmGatewayStep = 0;
        },
        borrowBalanceAction(){
            const data = {
                amount: this.borrowAmount
            };
            this.borrowLoading = true;
            this.$store.dispatch('borrowBalance', {data}).then(()=>{
                this.borrowAmount = '';
                this.borrowDialog = false;
                this.borrowLoading = false;
                this.showMsg('Borrow balance transaction done successfully', 'success');
                this.$store.dispatch('updateNotifications');
            }).catch(e=>{
                this.borrowLoading = false;
                
                const msg = e && e.response && e.response.data;
                if (e.response && e.response.data === 'MAX_BORROW_AMOUNT_EXCEEDED'){
                    this.showMsg('You have excceeded max borrow amount');

                }else if (e.response && e.response.data === 'BORROW_NOT_ALLOWED'){
                    this.showMsg('Borrow is not allowed for you right now');

                }else{
                    this.showMsg('Error occurred during performing request, please contact support');
                }
                this.recharging = false;
            });
        },
        escapeHtmlText(text){
            // to prevent XSS attacks
            return text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
        },
        urlify(text){
            const options = { defaultProtocol: 'https' , target: '_system', validate: {email: value=>false}};
            return linkifyHtml(text, options);
        },
        parseSteps(text){
            let html = this.escapeHtmlText(text);
            html = text.replaceAll(/\*\*.*?\*\*?/ig, match=>{
                return `<strong class="steps-strong">${match.replaceAll('**', '')}</strong>`;
            });
            html = html.replaceAll('{AMOUNT}', `<strong class="amount-strong">$${this.rechargeAmount}</strong>`);

            html = this.urlify(html);
            return html
        },
        ...GlobalMethods,
    },
    mounted(){
        if (this.appData && !this.appData.enable_app_w){
            this.$router.push('/not-found');
        }else{
            if (this.$route.query && this.$route.query.success){
                this.success = true;
            }
            this.loadData();
        }
    }
};
</script>

<style lang="scss" scoped>
.payment-method-item{
    &.v-list-item--disabled{
        opacity: 0.6;
        filter: grayscale(0.8);
    }
}
</style>
<style lang="scss">
    .manual-steps{
        a{
            font-weight: bold;
        }
        .steps-strong, .amount-strong{
            padding: 2px 6px;
            border-radius: 8px;
        }
        .steps-strong{
            background: #eee;
        }
        .amount-strong{
            background: #f7f0df;
            color: #e67d00;
        }
    }
    .recharge-input{
        /* Chrome, Safari, Edge, Opera */
        input{
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
    
            /* Firefox */
            &[type=number] {
                -moz-appearance: textfield;
            }
        }
    }
</style>