<template>
    <v-dialog class="loading-dialog fill-height" v-model="loading" persistent no-click-animation transition="fade-transition" fullscreen fluid>
        <v-container fluid fill-height class="d-flex justify-center align-center flex-column loading-overlay" :style="background" :class="{'bg-img': !!background}">
            <div class="logo-wrapper">
                <logo :link="false" />
            </div>
            <div class="mx-auto mt-10">
                <v-progress-linear color="primary" indeterminate rounded height="5" style="width: 160px" ></v-progress-linear>
                <!-- <v-progress-circular indeterminate transition="fade-transition" :color="color ? color : 'primary lighten-1'" size="75" width="5"></v-progress-circular> -->
                <!-- <svg-loader /> -->
            </div>
        </v-container>
    </v-dialog>    
</template>
<script>
// import Logo from '../main/logo'
// import SvgLoader from './svg-loader'
export default {
    props: ['loading', 'background', 'color', 'noSpinner'],
    components: {},
}
</script>
<style lang="scss" scoped>
    .loading-dialog{
        z-index: 100;
    }
    .loading-overlay{
        background-color: rgba(255, 255, 255, 1);
        &.bg-img{
            background-size: cover;
            background-position: bottom center;
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-blend-mode: overlay;
        }
        z-index: 100;
    }
    .logo-wrapper{
        // height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .logo{
            max-width: 200px;
            min-width: 150px;
            height: auto;
        }
    }
</style>