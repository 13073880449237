const primary = '#e79007';
export default {
    primary,
    light: {
        'strong-text': '#101010',
        surface: '#ffffff',
        muted: '#7c7c7c',
        appbg: '#fafafa',
        ebadge: '#ed5757',
        text: '#313131',

        primary,
        ramadanprimary: '#630e90',
        secondary: '#994C26',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
    }
}