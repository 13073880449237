import Auth from './actions/auth'
import Settings from './actions/settings'
import Notifications from './notifications'
import Calendar from './calendar'
import Wallet from './wallet'
const state = {
    user: null,
    userCountry: null,
    homeData: null,

    ...Calendar.state,
};
const mutations = {
    SET_USER(state, user){
        state.user = user;
        if (user){
            let token = window.localStorage.getItem('tK');
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }
    },
    SET_USER_COUNTRY(state, country){
        state.userCountry = country;
    },
    SET_USER_UNREAD_INBOX_COUNT(state, count){
        Object.assign(state.user, {
            unread_inbox_count: count
        });
    },
    SET_HOME_DATA(state, payload){
        state.homeData = payload.data;
    },
    UPDATE_HOME_DATA(state, payload){
        if (state.homeData){
            Object.assign(state.homeData, payload.data);
        }
    },
    UPDATE_USER_CREDITS(state, credits){
        Object.assign(state.user, {credits});
    },
    UPDATE_USER_LOAN_CREDITS(state, loan_credits){
        Object.assign(state.user, {loan_credits});
    },
    ...Calendar.mutations,
    ...Wallet.mutations,
    ...Notifications.mutations
};
const actions = {
    loadHomeData({commit}, payload){
        const url = '/home';   
        return window.axios.get(url).then(response=>{
            commit('SET_HOME_DATA', {data: response.data});
        });
    },
    ...Auth,
    ...Settings,
    ...Calendar.actions,
    ...Wallet.actions,
    ...Notifications.actions
};

export default {state, mutations, actions};