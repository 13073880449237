import {PushNotifications} from '@capacitor/push-notifications'

export default {
    getDeviceToken(successCallback, errorCallback){
        try{
            PushNotifications.requestPermissions().then(repsonse=>{
                if (repsonse.receive === 'granted'){
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();
    
                    // Listen for success.. and get token
                    PushNotifications.addListener('registration', token=>{
                        successCallback({token: token.value})
                    });
    
                    // Some issue with our setup and push will not work
                    PushNotifications.addListener('registrationError', error=>{
                        errorCallback(error);
                    });
                }else{
                    errorCallback('NOT_GRANTED');
                }
            })
        }
        catch(err){
            errorCallback(err);
        }
    },
}