import ResetPassword from './reset-password'
import Verify from './verify'

export default {
    ...ResetPassword,
    ...Verify,
    
    checkAuth({commit}){
        let url = `/token/valid`,
            token = window.localStorage.getItem('tK');
        return new Promise((resolve, reject)=>{
            if (token){
                window.axios.post(url, {token}).then(response=>{
                    if (response.data.valid){
                        const user = response.data.user;
                        commit('SET_USER', user);

                        // set auth header on axios
                        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                        resolve({authed: true});
                    }else{
                        commit('SET_USER', null);
                        
                        window.localStorage.removeItem('tK');
                        resolve({authed: false, redirect: 'login'});
                    }
                }).catch(error=>{
                    window.console.log(error);
                    reject(error);
                });
            }else{
                // no token, don't request validation so no user in state so not authed!
                resolve({authed: false});
            }
        })
    },
    registerAction({commit}, payload){
        let url = `/register`;
        return new Promise((resolve, reject)=>{
            window.axios.post(url, payload).then(response=>{
                let token = response.data.token;
                if (token){
                    
                    window.localStorage.setItem('tK', token);
                    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                    commit('SET_USER', response.data.user);
                    let redirectToVerify = response.data.verification === 'SENT';
                    resolve({redirect: redirectToVerify});
                }
            }).catch(error=>{
                reject(error);
            })
        });
    },
    loginAction({commit}, payload){
        let url = `/login`;
        return new Promise((resolve, reject)=>{
            
            window.axios.post(url, payload).then(response=>{
                let token = response.data.token;
                
                if (token){        
                    window.localStorage.setItem('tK', token);
                    window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                    commit('SET_USER', response.data.user);
                    resolve();
                }else{
                    reject({});
                }
            }).catch(error=>{
                reject(error);
            })
        });
    },
    logout({commit}){
        window.localStorage.removeItem('tK');
        commit('SET_USER', null);
    }
}