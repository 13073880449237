export default {

    sendCodeAction(context, payload){
        let url = `/password/reset`;
        return new Promise((resolve, reject)=>{
            window.axios.post(url, {email: payload.email}).then(response=>{
                resolve(response);
            }).catch(error=>{
                reject(error);
            })
        });
    },
    validateCodeAction(context, payload){
        let url = `/password/reset/validate`;
        return new Promise((resolve, reject)=>{
            window.axios.post(url, {email: payload.email, code: payload.code}).then(response=>{
                resolve(response);
            }).catch(error=>{
                reject(error);
            })
        });
    },
    newPasswordAction(context, payload){
        let url = payload.url;
        return new Promise((resolve, reject)=>{
            window.axios.post(url, {password: payload.password}).then(response=>{
                resolve(response);
            }).catch(error=>{
                reject(error);
            })
        });
    },
}