<template>
    <div>
        <v-btn icon large :disabled="uploading" :loading="uploading" @click="openUploadDialog">
            <v-icon size="24" color="text">mdi-image</v-icon>
        </v-btn>
        <input class="hidden-input" type="file" @change="handleFileUpload" ref="imageInput" hidden accept="image/*" >
    </div>
</template>
<script>
export default {
    data(){
        return {
            imageFile: null,
            uploading: false,
        }
    },
    props: ['chatId', 'latest'],
    methods: {
        openUploadDialog(){
            this.$refs.imageInput.click();
        },
        sendImage(){
            let imageFile = this.imageFile;
            
            if (imageFile){
                this.uploading = true;
                let formData = new FormData();
                formData.append('type', 'image');
                formData.append('latest', this.latest);
                formData.append('image', imageFile);

                this.$store.dispatch('newChatImageMsg', {id: this.chatId, formData}).then(()=>{
                    this.uploading = false;
                    this.resetFile();
                }).catch(error=>{
                    this.uploading = false;
                    if (error && error.response && error.response.status === 422 && error.response.data.errors && error.response.data.errors.image){
                        this.showMsg(error.response.data.errors.image[0]);
                    }else{
                        console.error(error);
                        this.showMsg('Error during uploading the image', 'error');
                    }
                    this.resetFile();
                    
                })
                

            }else{
                // handle it   
            }
        },
        handleFileUpload(event){
            this.imageFile = event.target.files[0];
        },
        resetFile(){
            this.imageFile = null;
            this.$refs.imageInput.value = '';
        },
        
        showMsg(text, color = 'error', timeout = 4000, msgBar = false){
            this.$store.dispatch('addSnackMsg', {show: true, text: text, color: color, timeout: timeout, msgBar: msgBar});
        },
    },
    watch: {
        imageFile(file){
            if (file){
                this.sendImage();
            }
        }
    },
    computed:{
        
    },
}
</script>
<style lang="scss" scoped>
.user-avatar{
    position: relative;
    overflow: visible!important;
    .edit-avatar{
        position: absolute;
        z-index: 3;
        bottom: -5px;
        right: -4px;
    }
}
.hidden-input{
    z-index: -1;
    position: absolute;
    bottom: -999px;
    opacity: 0;
}
</style>