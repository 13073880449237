<template>
    <v-slide-y-transition name="slide-down" appear>
        <div v-if="!!text" class="msg-bar e-link text-center white--text" :class="classes" @click="$emit('close')" :style="`top: ${margin ? margin : 0}px`">
            <span>{{text}}</span>
        </div>
    </v-slide-y-transition>
</template>

<script>
export default {
    props: ['text', 'color', 'timeout', 'margin'],
    computed: {
        classes(){
            let classes = this.color ? this.color : 'error';
            return classes;
        }
    },
    mounted(){
        if (this.timeout){
            window.setTimeout(()=>{
                this.$emit('close');
            }, this.timeout);
        }
    }
}
</script>

<style lang="scss" scoped>
    .msg-bar{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 6px 15px;
        // z-index: 6;
        z-index: 4; // to be bottom of
        font-size: 0.9rem;
        font-weight: 600;
        
    }
</style>