<template>
    <div>
        <v-btn icon color="text" class="icon-wrapper" @click.prevent="openNotifications">
            <v-badge class="notification-badge" :value="!!newNotifications" color="ebadge" bordered :content="newNotifications" overlap right>
                
                <v-icon v-if="!newNotifications" color="muted">mdi-bell-outline</v-icon>
                <v-icon color="strong-text--text" v-else>mdi-bell</v-icon>
                
            </v-badge>
        </v-btn>

        <v-dialog v-model="notificationsDialog" transition="slide-left" fullscreen fluid scrollable>
            <v-card :style="`${iosSafeArea ? 'padding-top: 40px!important; ' : ''}`">
                <v-card-title class="pa-0">
                    <v-toolbar flat style="border-bottom: 1px solid rgba(0,0,0, .1)">

                        <v-btn icon @click="notificationsDialog = false;" class="ms-0">
                            <v-icon :size="pcOnly ? 21 : 24">mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-toolbar-title class="px-3 medium-text">
                            Notifications
                            <span v-if="newNotifications">({{newNotifications}})</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-list style="max-width: 100%;">
                        <div class="notifications-wrapper">
                            <div v-for="notification in notifications" :key="notification.id" class="notification-wrapper">
                                <notification :notification="notification" @navigated="notificationsDialog = false" />
                            </div>
                        </div>

                        <div class="d-flex justify-center align-center mt-2">
                            <v-btn text small :disabled="!existsMore" color="primary darken-2" @click="showMore = true">show more notifications</v-btn>
                        </div>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'
import Notification from '../../default/notification'

// import GlobalMethods from '@/helpers/global-methods'

export default {
    data(){
        return {
            notificationsDialog: false,
            showMore: false,
            limit: 10,
        }
    },
    components: {
        Notification
    },
    computed: {
        ...GlobalComputed,
        existsMore(){
            return this.allNotifications.length > this.notifications.length;
        },
        notifications(){
            let notifications = this.allNotifications;
            if (!notifications){
                return [];
            }
            return this.showMore ? notifications : notifications.slice(0, this.limit);
        },
        allNotifications(){
            return this.user.notifications;
        },
        unseenCount(){
            let unseen = this.allNotifications.filter(n=>{
                return !n.read;
            });
            return unseen.length;
        },
        unseenText(){
            let count = this.unseenCount;
            if (count){
                if (count){
                    return '1 New Notification';
                }else if (count){
                    return count + ' New Notifications'
                }
            }
            return 'No New Notifications';
        },
        newNotifications(){
            let unread = this.user.notifications_count;
            // let unread = 122;
            return unread > 99 ? '+99' : unread;
        },
    },
    methods: {
        markAllAsRead(){
            if (this.user.notifications_count > 0){
                this.$store.dispatch('markAllAsRead');
            }
        },
        openNotifications(){
            if (this.notifications.length){
                this.notificationsDialog= !this.notificationsDialog;
                window.setTimeout(()=>{
                    this.markAllAsRead();
                }, 2000);
            }
        }
    }
}
</script>

<style lang="scss">

    .scroll-area {
        position: relative;
        margin: auto;
        max-height: 500px!important;
    }

    .notification-badge.v-badge--bordered .v-badge__badge{
        // font-weight: bold;
        &::after{
            // right: 2px!important;
        }
    }
</style>