<template>
    <div class="page">
        <v-container>
            <div v-if="course" class="mx-auto overflow-hidden pa-lg-2" style="max-width: 1050px">
                <v-card :flat="phoneOnly" :class="{'soft-shadow': pcOnly}">
                    <v-card-text class="pa-3 pa-lg-6">
                        <div class="d-flex flex-column flex-lg-row">
                            <div class="mr-lg-10">
                                <div>
                                    <v-img :width="pcOnly ? 300 : undefined" height="180" :src="course.image_url" style="border-radius: 7px" />
                                </div>
                                <!-- <div class="mt-3">
                                    <v-btn large depressed color="primary" block outlined style="font-size: 1rem">
                                        <span>{{moneyFormat(course.hourly_price)}} / hour</span>
                                    </v-btn>
                                </div> -->
                            </div>
                            <div class="py-3">
                                <h1 v-if="pcOnly" class="course-title strong-text--text mb-1">
                                    <span>{{course.name}}</span>
                                </h1>
                                <h2 v-else class="course-title strong-text--text mb-1">
                                    <span>{{course.name}}</span>
                                </h2>
                                <div class="d-flex mb-4">
                                    <v-chip small label>{{course.category_name}}</v-chip>
                                    <v-chip v-if="status" dark small label :color="handleStatus(status).color" class="ms-2">{{handleStatus(status).text}}</v-chip>
                                </div>
                                <p class="description">{{course.description}}</p>
                                <div class="mt-6">
                                    <v-btn outlined depressed color="muted" class="me-3" exact :to="{name: 'courses'}">
                                        <v-icon left>mdi-arrow-left</v-icon>
                                        <span>Catalog</span>
                                    </v-btn>
                                    <v-btn v-if="course.details_url" target="_system" :href="course.details_url" outlined color="primary" depressed class="px-5">
                                        <v-icon left class="me-3">mdi-book-open-outline</v-icon>
                                        <span>Details</span>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>

                <template v-if="approved">
                    <v-card :flat="phoneOnly" class="mt-4 px-2" :class="{'soft-shadow': pcOnly, 'transparent': phoneOnly}">
                        <v-card-title>Cancel Subscription</v-card-title>
                        <v-card-subtitle>
                            If you canceled your subscription, all next classes of this course would be removed from your calender.
                        </v-card-subtitle>
                        <div v-if="course.finish_requested" class="px-4 pb-3">
                            <v-alert text type="info" icon="mdi-book-remove-outline" dense color="error darken-1">
                                <span class="medium-text font-weight-semibold">You requested to cancel this course, we'll approve your request ASAP.</span>
                            </v-alert>
                        </div>
                        <v-card-actions class="pb-3 px-4">
                            <v-btn :loading="finishing" :disabled="finishing || course.finish_requested" depressed color="red" :block="phoneOnly" class="white--text px-lg-3" @click="finishDialog = true">
                                <v-icon left>mdi-book-cancel-outline</v-icon>
                                Cancel Subscription
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>

                <template v-else>
                    <v-card class="mt-4 px-2" :class="{'soft-shadow': pcOnly, 'transparent': phoneOnly}">
                        <v-card-title class="d-flex justify-space-between" :class="{'px-1 mb-2': phoneOnly}">
                            <div>
                                <v-icon color="primary" left>mdi-book-{{course.enrolled ? 'clock' : 'arrow-left'}}</v-icon>
                                <span>{{course.enrollment ? 'Enrollment Request' : 'Enroll Course'}}</span>
                            </div>
                            <div>
                                <v-btn :disabled="editable" color="blue-grey" outlined :dark="!editable" depressed @click="allowEdit = true">
                                    <v-icon size="20" left>mdi-pencil</v-icon>
                                    <span>Edit</span>
                                </v-btn>
                            </div>
                        </v-card-title>
                        <v-card-subtitle v-if="!course.enrollment" class="mb-2" :class="{'px-1': phoneOnly}">Let your children start their learning journey <b class="primary--text">NOW</b>, you'll get <b class="primary--text">TWO FREE Trial</b> classes.</v-card-subtitle>
                        <v-card-subtitle v-else class="mb-2">You've requested to enroll in this course but your enrollment is still in progress.</v-card-subtitle>
                        <v-card-text :class="{'px-0': phoneOnly}">
                            <div v-if="course.enrolled" class="alerts">
                                <v-alert v-if="status === 'action-needed'" type="warning" color="error" icon="mdi-progress-alert" text>
                                    <div class="medium-text text--text">
                                        <div class="red--text font-weight-semibold mb-1">Change Needed:</div>
                                        <div class="multiline red--text text--darken-4">
                                            <span>{{course.enrollment.admin_comment}}</span>
                                        </div>
                                    </div>
                                </v-alert>

                                <v-alert v-else type="info" color="blue-grey" icon="mdi-progress-clock" text>
                                    <div class="medium-text text--text">
                                    <b>Thank you for enrolling!</b> <br>
                                    One of our support team will accept your request within few hours..
                                    </div>
                                </v-alert>
                            </div>
                            <v-form>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <v-select :readonly="!editable" :items="courseDurationItems" v-model="classDuration" outlined label="Class Duration" placeholder="Choose Class Duration" hide-details></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <v-select :readonly="!editable" :items="classesPerWeekItems" v-model="classesPerWeek" :disabled="!classDuration" outlined label="Classes / Week" placeholder="How Many Classes Weekly" hide-details></v-select>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <v-select :readonly="!editable" v-model="preferredDays" :items="preferredDaysItems" multiple outlined label="Preferred Days" placeholder="Preferred Days For Classes" hide-details></v-select>
                                    </v-col>

                                    <v-col cols="12" lg="6" class="py-1">
                                        <v-menu v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field :disabled="!preferredDays.length || !editable" outlined v-model="startDate" placeholder="Preferred Start Day" label="Start Day" prepend-inner-icon="mdi-calendar" readonly hide-details v-bind="attrs" v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker :allowed-dates="allowedDates" :min="minStartDate" v-model="startDate" @input="startDateMenu = false" color="primary"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    
                                    <v-col cols="12" class="pa-0">
                                        <v-divider class="ma-3"></v-divider>
                                        <v-subheader>
                                            <v-icon size="20" class="me-1">mdi-clock-outline</v-icon>
                                            <span class="strong-text--text me-1">Schedule</span> <span v-if="pcOnly">(Provide us your preferred schedule)</span>
                                        </v-subheader>

                                        <v-alert v-if="!course.enrolled" type="info" dense text class="medium-text mx-3" icon="mdi-information-outline" color="grey">
                                            <span class="muted--text">
                                                We suggest setting <b class="primary--text">fixed times</b> as this will help us to arrange your classes as soon as possible
                                            </span>
                                        </v-alert>

                                        <v-alert v-if="!course.enrolled" type="info" dense text class="medium-text mx-3" icon="mdi-map-clock" color="grey">
                                            <span class="text--text">
                                                Enter times in <b>your timezone</b> ({{user.timezone}})
                                            </span>
                                        </v-alert>
                                    </v-col>

                                    <template v-for="(entry, i) in timeEntries">
                                        <v-col cols="5" :key="'1-' + i" :class="{'px-1': phoneOnly}">
                                            <v-select :dense="phoneOnly" :readonly="!editable" :disabled="!preferredDays.length" v-model="timeEntries[i]['day']" :items="selectedPreferredDays" outlined label="Day" placeholder="Choose Class Day" hide-details></v-select>
                                        </v-col>
                                        <v-col cols="6" :key="'2-' + i" :class="{'px-1': phoneOnly}">
                                            <time-input v-model="timeEntries[i]['from']" :dense="phoneOnly" :hide-icon="phoneOnly" half-format :disabled="(!timeEntries[i]['day'] && timeEntries[i]['day'] !== 0) || !editable" outlined label="From" placeholder="From Time" /> <!-- :max="timeEntries[i]['to']" -->
                                        </v-col>
                                        <!-- <v-col cols="3" :key="'3-' + i" :class="{'px-1': phoneOnly}">
                                            <time-input v-model="timeEntries[i]['to']" :replaces="[['00:00', '23:59']]" :dense="phoneOnly" :hide-icon="phoneOnly" half-format :disabled="!timeEntries[i]['from'] || !editable" outlined label="To" placeholder="To Time" />
                                        </v-col> -->
                                        <v-col cols="1" :key="'4-' + i" class="d-flex justify-start align-center" :class="{'px-0': phoneOnly}">
                                            <v-btn :disabled="!editable" v-if="i > 0" color="error" icon @click="removeTimeEntry(i)">
                                                <v-icon size="20">mdi-close</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </template>
                                    <v-col cols="12" class="py-0">
                                        <v-btn :disabled="!editable" color="primary" dense small text @click="addTimeEntry">
                                            <v-icon left>mdi-plus</v-icon>
                                            Additional Time Entry
                                        </v-btn>
                                    </v-col>

                                    
                                    <v-col cols="12" class="pa-0">
                                        <v-divider class="mx-3 mt-3 mb-5"></v-divider>
                                    </v-col>
                                    <v-col cols="12" class="py-1">
                                        <v-textarea v-model="comment" :disabled="!editable" rows="4" no-resize outlined label="Comment" placeholder="Leave us a message (optional)" hide-details></v-textarea>
                                    </v-col>
                                </v-row>
                                

                                <div class="actions mt-3">
                                    <v-btn v-if="!course.enrolled" :loading="enrolling" :block="phoneOnly" :disabled="enrolling" large depressed color="primary" style="min-width: 180px" @click="enrollCourse">
                                        <v-icon left>mdi-book-arrow-left-outline</v-icon>
                                        <span>Enroll Now</span>
                                    </v-btn>
                                    <v-btn v-else :loading="updating" :disabled="updating || !editable" :dark="!updating && editable" large depressed color="blue-grey" style="min-width: 180px" @click="updateEnrollment">
                                        <v-icon left>mdi-book-edit-outline</v-icon>
                                        <span>Save Changes</span>
                                    </v-btn>
                                </div>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </template>

            </div>
        </v-container>

        <v-dialog v-model="finishDialog" max-width="375">
            <v-card>
                <v-card-title>Cancel Subscription Request</v-card-title>
                <v-card-text>
                    <div class="mb-6">
                        Are you sure that you want to cancel your subscription? You can enroll again later.
                    </div>

                    <div>
                        <v-textarea v-model="finishReason" outlined rows="4" placeholder="Reason of cancelation" no-resize hide-details></v-textarea>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="muted" @click="finishDialog = false">cancel</v-btn>
                    <v-btn text color="error" @click="finishCourse">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

import {isEqual} from 'lodash'

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import TimeInput from '@/components/custom/time-input'
export default {
    name: "Course",
    components: {
        TimeInput
    },
    data(){
        const d = new Date();
        d.setDate(d.getDate()+1); // min start_date is tomorrow
        return {
            course: null,
            courseMeta: null,
            timeEntries: [
                {day: '', from: '', to: ''},
                {day: '', from: '', to: ''},
                {day: '', from: '', to: ''},
            ],

            loading: false,
            enrolling: false,
            startDateMenu: false,
            minStartDate: d.toISOString().substr(0, 10),

            classDuration: '',
            classesPerWeek: '',
            startDate: '',
            comment: '',

            updating: false,
            finishing: false,
            allowEdit: false,

            preferredDaysItems: [
                {text: 'Saturday', value: 6},
                {text: 'Sunday', value: 0},
                {text: 'Monday', value: 1},
                {text: 'Tuesday', value: 2},
                {text: 'Wednesday', value: 3},
                {text: 'Thursday', value: 4},
                {text: 'Friday', value: 5},
            ],
            preferredDays: [],

            finishDialog: false,
            finishReason: '',
        }
    },
    computed: {
        id(){
            return this.$route.params.id;
        },
        editable(){
            if (this.status === 'action-needed' || !this.course.enrolled){
                return true;
            }
            return this.allowEdit;
        },
        approved(){
            // user enrollment approved
            return this.course.enrolled && this.course.enrollment.status === 'approved';
        },
        status(){
            return this.course.enrolled ? this.course.enrollment.status : null;
        },
        data(){
            return {
                class_duration: this.classDuration,
                weekly_classes: this.classesPerWeek,
                start_day: this.startDate,
                comment: this.comment,
                preferred_days: this.preferredDays,
                schedule: this.timeEntries.filter(item=>!!item.day || item.day === 0)
            }
        },
        courseDurationItems(){
            if (!this.courseMeta){
                return [];
            }
            const items = this.courseMeta.class_duration_items;
            return items.map(item=>{
                const hourlyPrice = this.course.enrollment && this.course.enrollment.hourly_price ? this.course.enrollment.hourly_price : this.course.hourly_price;
                const classPrice = hourlyPrice * (item.value/60); // hourly price * minutes/60
                return {
                    text: this.appData.enable_app_w ? `${item.text} (${this.moneyFormat(classPrice)} / class)` : item.text,
                    value: item.value,
                }
            })
        },
        classesPerWeekItems(){
            let items = [
                {text: '2 classes / week', value: 2},
                {text: '3 classes / week', value: 3},
                {text: '4 classes / week', value: 4},
                {text: '5 classes / week', value: 5},
                {text: '6 classes / week', value: 6},
            ];
            // if one class duration not less than 1 hour, add one class to items
            if (this.classDuration && this.classDuration / 60 >= 1){
                items.unshift({text: '1 class / week', value: 1});
            }
            return items.map(item=>{
                const weeklyPrice = item.value * this.classPrice;
                if (this.appData.enable_app_w){
                    item.text += ` (${this.moneyFormat(weeklyPrice)} / week)`
                }
                
                return item;
            });
        },
        selectedPreferredDays(){
            return this.preferredDaysItems.filter(day=>{
                return this.preferredDays.includes(day.value);
            })
        },
        classPrice(){
            const hourlyPrice = this.course.enrollment && this.course.enrollment.hourly_price ? this.course.enrollment.hourly_price : this.course.hourly_price;
            const classPrice = hourlyPrice * (this.classDuration/60); // hourly price * minutes/60

            return classPrice;
        },
        ...GlobalComputed
    },
    watch: {
        id(){
            this.loadData();
        }
    },
    methods: {
        loadData(){
            if (!this.loading){
                this.loading = true;
                return this.$store.dispatch('getCourse', {id: this.id}).then(response=>{
                    this.course = response.data.data;
                    this.courseMeta = response.data.meta;
                    this.loading = false;

                    if (this.course.enrolled){
                        this.fillForm();
                    }

                }).catch(()=>{
                    this.$router.push({name: 'courses'});
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        enrollCourse(){
            if (!this.enrolling){
                if (this.validate()){
                    this.enrolling = true;
                    return this.$store.dispatch('enrollCourse', {id: this.id, data: this.data}).then(response=>{
                        this.course = response.data.data;
                        this.enrolling = false;
                        this.showMsg('You\'ve succesfully enrolled the course', 'success');
                        this.$store.dispatch('updateNotifications');

                    }).catch(error=>{
                        this.enrolling = false;
                            if (error.response && error.response.status === 422){
                            // coming errors from backend
                            let errors = error.response.data.errors;
                            this.showMsg(errors[Object.keys(errors)[0]][0]);
                        }else{
                            this.showMsg('Error occurred during enrolling course', 'error');
                        }
                    })
                }
            }

        },
        updateEnrollment(){
            if (!this.updating){
                if (this.validate()){ /* && this.isFormUpdated() */
                    this.updating = true;
                    return this.$store.dispatch('updateCourseEnrollment', {id: this.id, data: this.data}).then(response=>{
                        this.updating = false;
                        this.course = response.data.data;
                        this.showMsg('Enrollment form updated successfully', 'success');
                        this.allowEdit = false;
                    }).catch(error=>{
                        this.updating = false;
                        if (error.response && error.response.status === 422){
                            // coming errors from backend
                            let errors = error.response.data.errors;
                            this.showMsg(errors[Object.keys(errors)[0]][0]);
                        }else{
                            this.showMsg('Error occurred during submitting', 'error');
                        }
                    })
                }
            }

        },
        finishCourse(){
            if (!this.finishing){
                if (this.finishReason){
                    this.finishDialog = false;
                    this.finishing = true;
                    return this.$store.dispatch('finishEnrollment', {id: this.id, data: {reason: this.finishReason}}).then(response=>{
                        this.finishing = false;
                        // this.course = response.data.data;
                        this.showMsg('Your request sent successfully', 'success');
                        this.$router.push({name: 'courses'});
                    }).catch(error=>{
                        this.finishing = false;
                        this.showMsg('Error occurred during finishing', 'error');
                    })
                }else{
                    this.showMsg('Please enter the reason.', 'warning');
                }
            }
        },
        isFormUpdated(){
            const enrollment = this.course.enrollment;
            if (enrollment){
                const newData = this.data,
                    data = Object.keys(newData).reduce((obj, key) => {obj[key] = enrollment[key]; return obj}, {});
                
                const updated = !isEqual(newData, data);
                if (!updated) this.showMsg('Please update enrollment form to resubmit');
                return updated;
            }
            return false;
        },
        fillForm(){
            if (this.course.enrollment){
                const enrollment = this.course.enrollment;
                this.classDuration = enrollment.class_duration;
                this.classesPerWeek = enrollment.weekly_classes;
                this.startDate = enrollment.start_day;
                this.comment = enrollment.comment;
                this.preferredDays = enrollment.preferred_days;
                this.timeEntries = enrollment.schedule;
            }
        },
        validate(){
            const data = this.data;
            if (!data.class_duration || !data.weekly_classes || !data.start_day){
                this.showMsg('Required inputs cannot be empty', 'error');
                return false;
            }else if (data.preferred_days.length < data.weekly_classes){
                this.showMsg(`"Preferred Days" must be equal or more than "Classes/ Week"`, 'error');
                return false;
            }else if (data.schedule.length < data.weekly_classes){
                this.showMsg(`"Schedule" times must be equal or more than "Classes/ Week"`, 'error');
                return false;
            }
            return true;
        },
        allowedDates(day){
            const d = new Date(day);
            return this.preferredDays.includes(d.getUTCDay()); // check if day of d is one of preferredDays, getUTCDay to prevent bugs with some timezones
        },
        addTimeEntry(){
            this.timeEntries.push({day: '', from: '', to: ''});
        },
        removeTimeEntry(i){
            this.timeEntries.splice(i, 1);
        },
        ...GlobalMethods
    },
    mounted(){
        this.loadData();
    }
};
</script>
<style lang="scss" scoped>
    .course-title{
        line-height: 2.1rem;
    }
    .description{
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .multiline{
        white-space: pre-line;
    }
</style>
