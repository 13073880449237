<template>
    <v-form @submit.prevent="register" ref="form">
        <v-row>
            <v-col cols="12" lg="6" class="pb-1 pt-0">
                <v-text-field outlined placeholder="Enter First Name" label="Student First Name" name="first_name" v-model="firstName" :disabled="loading" :rules="[rules.global.required]"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="pb-1 pt-0">
                <v-text-field outlined placeholder="Enter Last Name" label="Student Last Name" name="last_name" v-model="lastName" :disabled="loading" :rules="[rules.global.required]"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
                <v-text-field outlined placeholder="Enter Your Email" label="Email" name="email" v-model="email" :disabled="loading" :rules="[rules.global.required, ...rules.email]" ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="pb-1 pt-0">
                <v-text-field outlined placeholder="Enter Password" label="Password"  name="password" v-model="password" :disabled="loading" :rules="[rules.global.required, ...rules.password]" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="pb-1 pt-0">
                <v-text-field outlined placeholder="Confirm Your Password" label="Confirm Password"  name="confirm_password" :disabled="loading" :rules="[rules.global.required, ...rules.confirmPassword]" v-model="confirmPassword" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showConfirmPassword ? 'text' : 'password'" @click:append="showConfirmPassword = !showConfirmPassword"></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" class="pb-1 pt-0">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined placeholder="Date Of Birth" v-model="dateOfBirth" label="Date Of Birth" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="[rules.global.required]" ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="dateOfBirth" :max="maxDateOfBirth" min="1945-01-01" @change="save" color="primary"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" lg="6" class="pb-1 pt-0">
                <v-select outlined placeholder="Choose Gender" label="Gender" name="gender" :items="['Male', 'Female']" v-model="gender" :disabled="loading" :rules="[rules.global.required]" ></v-select>
            </v-col>
            <v-col cols="12" class="pb-1 pt-0">
                <vue-tel-input-vuetify v-model="phone" @validate="phoneValidated" @country-changed="phoneCountryChanged" outlined class="phone-input ltr-dir" :ignoredCountries="ignoredCountries" placeholder="Enter Phone Number" label="Phone Number" :rules="[rules.global.required]" :disabled="loading || disabled" ></vue-tel-input-vuetify>
            </v-col>
        </v-row>
        <v-btn type="submit" block depressed large :loading="loading" :disabled="loading" color="primary" @click.prevent="register" class="font-weight-bold mt-1">{{buttonText}}</v-btn>
    </v-form>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import AppAPI from '@/app-api'

export default {
    props: {
        loading: {type: Boolean, default: false,},
        buttonText: {type: String, default: 'Create Account'},
        dialogOpened: {type: Boolean, default: false,},
    },
    data(){
        const d = new Date();
        d.setFullYear(d.getFullYear()-3);

        return {

            firstName: '',
            lastName: '',
            email: '',
            password: '',
            showPassword: false,
            confirmPassword: '',
            showConfirmPassword: false,
            gender: '',
            
            menu: false,

            maxDateOfBirth: d.toISOString().substr(0, 10),

            phone: '',
            fullPhone: '',
            phoneValid: true,
            phoneCountry: '',

            dateOfBirth: '',

            disabled: false,

            ignoredCountries: ['IL'],  // will be deleted from select country of phone number input

            rules: {
                global: {
                    required: value => !!value || 'Input is required'
                },
                email: [
                    value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Email not valid';
                    }
                ],
                password: [
                    value => value.length >= 6 || "Password min length is 6"
                ],
                confirmPassword: [
                    value => value === this.password || 'Passwords do not match'
                ]
            }
        }
    },
    computed: {
        ...GlobalComputed
    },
    methods: {
        register(){

            if (this.formValidate()){
                let gender = this.gender.toLowerCase(),
                    data = {
                        name: this.firstName + ' ' + this.lastName,
                        email: this.email,
                        password: this.password,
                        phone: this.phone,
                        gender: gender,
                        full_phone: this.fullPhone,
                        phone_country: this.phoneCountry,
                        date_of_birth: this.dateOfBirth,
                    };
                const referral_token = this.$route.query.referral;
                if (referral_token){
                    data.referral_token = referral_token;
                }

                if (this.capRunning && this.isProduction){
                    AppAPI.Notifications.getDeviceToken(response=>{
                        const deviceToken = response.token;
                        data.deviceToken = deviceToken;
                        this.$emit('register', data);
                    }, error=>{
                        this.$emit('register', data);
                    })
                }else{
                    this.$emit('register', data);
                }
            }
        },

        phoneCountryChanged(country){
            this.phoneCountry = country;
        },
        phoneValidated(data){
            this.phoneValid = data.isValid;
            this.fullPhone = data.number;
            if (data.country){
                this.phoneCountry = data.country;
            }
        },
        formValidate(){
            if (this.$refs.form.validate()){
                if (this.phoneValid){
                    return true;
                }else{
                    this.showMsg('Phone number is not valid');
                }
            }else{
                this.showMsg('Data you entered are not valid');
            }
            return false;
        },
        save (date) {
            this.$refs.menu.save(date)
        },

        showMsg(text, color = 'error', timeout = 4000){
            this.$store.dispatch('addSnackMsg', {show: true, text: text, color: color, timeout: timeout, msgBar: true});
        },
        ...GlobalMethods
    },
    watch: {
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
    },
};
</script>
