export default {
    state: {
        calendar: null,

        comingClasses: [],
        comingClassesNextUrl: null,
        
        classesHistory: [],
        classesHistoryNextUrl: null,
    },
    mutations: {
        SET_CALENDAR(state, calendar){
            state.calendar =  calendar;
        },
        UPDATE_CALENDAR_CLASS(state, payload){
            if (state.calendar){
                state.calendar = state.calendar.map(item=>{
                    if (item.id && item.id === payload.id){
                        const newItem = Object.assign(item, payload.data);
                        return newItem;
                    }
                    return item;
                })
            }
        },
        ADD_COMING_CLASSES(state, payload){
            state.comingClasses.push(...payload.data);
            state.comingClassesNextUrl = payload.next;
        },
        SET_COMING_CLASSES(state, payload){
            state.comingClasses = payload.data;
            state.comingClassesNextUrl = payload.next;
        },
        ADD_CLASSES_HISTORY(state, payload){
            state.classesHistory.push(...payload.data);
            state.classesHistoryNextUrl = payload.next;
        },
        SET_CLASSES_HISTORY(state, payload){
            state.classesHistory = payload.data;
            state.classesHistoryNextUrl = payload.next;
        },
    },
    actions: {
        fetchCalendar({commit}, payload){
            let url = '/calendar';
                
            if (payload.startDate){
                let dateArr = payload.startDate.split(['-']);
                dateArr[2] = '01'; // start of month
                const start_date = dateArr.join('-');
                url += '?start_date=' + start_date;
            }

            return window.axios.get(url).then(response=>{
                commit('SET_CALENDAR', response.data.data);
            });
            
        },
        loadComingClasses({state, commit}, payload){
            const next = state.comingClassesNextUrl;
            if (next || next === undefined || payload.initialLoad){
    
    
                const url = payload.initialLoad || !next ? '/classes' : next;
                let params = {...payload.data};

                return window.axios.get(url, {params}).then(response=>{
                    let data = {data: response.data.data, next: response.data.links.next};
                    if (payload.initialLoad){
                        commit('SET_COMING_CLASSES', data);
                    }else{
                        commit('ADD_COMING_CLASSES', data);
                    }
                });
            }
        },
        loadClassesHistory({state, commit}, payload){
            const next = state.classesHistoryNextUrl;
            if (next || next === undefined || payload.initialLoad){
    
    
                const url = payload.initialLoad || !next ? '/classes/history' : next;
                let params = {...payload.data};

                return window.axios.get(url, {params}).then(response=>{
                    let data = {data: response.data.data, next: response.data.links.next};
                    if (payload.initialLoad){
                        commit('SET_CLASSES_HISTORY', data);
                    }else{
                        commit('ADD_CLASSES_HISTORY', data);
                    }
                });
            }
        },
        joinClass({commit}, payload){
            let url = '/classes/' + payload.id + '/join';
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url).then(response=>{
                    commit('UPDATE_CALENDAR_CLASS', {id: payload.id, data: {payment_status: response.data.payment_status}})
                    commit('UPDATE_USER_CREDITS', response.data.credits)
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        saveJoinUrl({commit}, payload){
            let url = '/classes/' + payload.id + '/join-url';
               
            return new Promise((resolve, reject)=>{
                window.axios.put(url, {join_url: payload.url}).then(response=>{
                    commit('UPDATE_CALENDAR_CLASS', {id: payload.id, data: response.data.data})
                    commit('UPDATE_HOME_DATA', {data: {next_class: response.data.data}})
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        markAsAbsent({commit}, payload){
            let url = '/classes/' + payload.id + '/absent';
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url).then(response=>{
                    commit('UPDATE_CALENDAR_CLASS', {id: payload.id, data: response.data.data})
                    commit('UPDATE_HOME_DATA', {data: {next_class: response.data.data}})
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        markAsAttended({commit}, payload){
            let url = '/classes/' + payload.id + '/attended';
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url).then(response=>{
                    commit('UPDATE_CALENDAR_CLASS', {id: payload.id, data: response.data.data})
                    commit('UPDATE_HOME_DATA', {data: {next_class: response.data.data}})
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        saveClassReport({commit}, payload){
            let url = '/class-reports/' + payload.id;
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    commit('UPDATE_CALENDAR_CLASS', {id: payload.id, data: response.data.data})
                    commit('UPDATE_HOME_DATA', {data: {next_class: response.data.data}})
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        cancelClass({commit}, payload){
            let url = '/classes/' + payload.id + '/cancel';
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    commit('UPDATE_CALENDAR_CLASS', {id: payload.id, data: response.data.data})
                    commit('UPDATE_HOME_DATA', {data: {next_class: response.data.data}})
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        rescheduleRequestAction({commit}, payload){
            let url = '/reschedule-requests/' + payload.id + '/' + payload.action;
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url).then(response=>{
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        rescheduleClass({commit}, payload){
            let url = '/classes/' + payload.id + '/reschedule';
               
            return new Promise((resolve, reject)=>{
                window.axios.put(url, {date: payload.date, start_time: payload.time, permanent: payload.permanent, reason: payload.reason}).then(response=>{
                    commit('UPDATE_CALENDAR_CLASS', {id: payload.id, data: response.data.data})
                    commit('UPDATE_HOME_DATA', {data: {next_class: response.data.data}})
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        addMakeupClass({commit}, payload){
            let url = '/classes/makeup';
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
    }
}