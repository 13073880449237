<template>
    <div class="page">
        <v-container :class="{'px-0 pt-0': phoneOnly}">
            <div style="max-width: 850px!important">
                <infinity-wrapper v-if="classes && classes.length" @load="loadData(false)" key="classes">
                    <template v-for="(classData, i) in classes">
                    <v-card :key="i + '-' + classData" class="mb-lg-3 px-2" :class="{'soft-shadow': pcOnly}" :flat="phoneOnly">
                        <v-card-title class="nowrap pb-0 mb-5" style="font-size: 1.1rem">
                            {{isTeacher ? classData.enrollment.student.name + ' - ' : ''}} {{classData.name || classData.enrollment.course.name}}
                            <span class="ms-2 muted--text" style="font-size: 1rem">(#{{classData.id}})</span>
                        </v-card-title>
                        <v-card-subtitle class="d-flex overflow-x-auto">
                            <v-chip v-if="isStudent" small label class="me-2">
                                <v-icon size="15" class="me-1" left>mdi-account-tie-voice</v-icon>
                                <span style="font-weight: 500">{{classData.enrollment.teacher.name}}</span>
                            </v-chip>
                            <!-- <v-chip v-else small label class="me-2">
                                <v-icon size="15" class="me-1" left>mdi-account</v-icon>
                                <span style="font-weight: 500">{{classData.enrollment.student.name}}</span>
                            </v-chip> -->
                            <v-chip small label outlined class="me-2">
                                <v-icon size="15" class="me-1" left>mdi-calendar-outline</v-icon>
                                <span style="font-weight: 500">{{classData.start_datetime.split(' ')[0]}}</span>
                            </v-chip>
                            <v-chip color="success darken-2" outlined dark small label class="me-2">
                                <v-icon size="15" class="me-1" left>mdi-clock-outline</v-icon>
                                <span style="font-weight: 500">{{formatTime(classData.start)}}</span>
                            </v-chip>
                            <v-chip color="red darken-2" outlined dark small label class="me-2">
                                <v-icon size="15" class="me-1" left>mdi-clock-outline</v-icon>
                                <span style="font-weight: 500">{{formatTime(classData.end)}}</span>
                            </v-chip>
                            <!-- Status -->
                            <v-chip v-if="classData.rescheduled && classData.status === 'scheduled'" small class="font-weight-medium" dark color="teal darken-1" label>
                                <v-icon small left class="me-1">mdi-calendar-clock-outline</v-icon>
                                <span v-if="!!classData.waiting_reschedule_request">Reschedule Requested</span>
                                <span v-else>Rescheduled</span>
                            </v-chip>
                            <v-chip v-else-if="!classData.absent && !classData.canceled" small class="font-weight-medium" dark :color="handleStatus(classData.full_status).color" label>{{handleStatus(classData.full_status).text}}</v-chip>

                            <v-chip v-if="classData.makeup" small class="font-weight-medium ms-1" dark color="deep-purple accent-4" label>
                                <v-icon size="17" left class="me-1">mdi-shape-square-rounded-plus</v-icon>
                                <span>Makeup</span>
                            </v-chip>

                            <v-chip v-if="classData.absent" small class="font-weight-medium ms-1" dark color="amber darken-1" label>
                                <v-icon small left class="me-1">mdi-close-octagon-outline</v-icon>
                                Absent
                            </v-chip>
                            
                            <v-chip v-if="classData.canceled" small class="font-weight-medium ms-1" dark color="red darken-1" label>
                                <v-icon small left class="me-1">mdi-close</v-icon>
                                Canceled
                            </v-chip>

                            <div v-if="classData.payment_status" class="d-flex ms-1">
                                <v-chip v-if="classData.payment_status === 'trial'" small class="font-weight-medium" dark color="deep-purple" label>
                                    <v-icon size="17" left class="me-1">mdi-check-decagram</v-icon>
                                    <span>Trial</span>
                                </v-chip>
                                <v-chip v-else-if="classData.payment_status === 'credits-not-enough' && classData.status !== 'expired'" small class="font-weight-medium" dark color="red" label>
                                    <v-icon size="18" left class="me-1">mdi-wallet</v-icon>
                                    <span>Recharge Required</span>
                                </v-chip>
                                <v-chip v-else-if="classData.payment_status === 'paid'" small class="font-weight-medium" dark label>
                                    <v-icon size="20" left class="me-1">mdi-cash-check</v-icon>
                                    <span>Paid</span>
                                </v-chip>
                            </div>
                        </v-card-subtitle>
                        <v-card-actions class="pb-4 px-4 pt-1">
                            <v-btn depressed @click="openClassDialog(classData)">
                                <v-icon left>mdi-card-text</v-icon>
                                <span>Class Details</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-divider v-if="phoneOnly" :key="'d-' + classData.id"></v-divider>
                    </template>

                </infinity-wrapper>

                <template v-else-if="loading">
                    <v-card v-for="i in 10" class="soft-shadow mb-3" :key="i">
                        <v-skeleton-loader type="article" class="py-0" style="max-width: 1000px" ></v-skeleton-loader> <!-- list-item-avatar-three-line, -->
                    </v-card>
                </template>

                <div v-else>
                    <v-card class="soft-shadow pa-4">
                        <v-alert type="info" color="blue-grey" text class="mb-0">
                            No classes were found
                        </v-alert>
                    </v-card>
                </div>
            </div>
        </v-container>

        <v-dialog v-if="classDialogData" v-model="showClassDialog" :fullscreen="phoneOnly" :transition="phoneOnly ? 'scroll-y-transition' : ''" :fluid="phoneOnly" scrollable max-width="375" overlay-color="#000000">
            <class-card :class-data="classDialogData" @close="showClassDialog = false" />
        </v-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import InfinityWrapper from '@/components/default/infinity-wrapper'
import ClassCard from '@/components/custom/class-card'

export default {
    name: "ComingClasses",
    components: {
        InfinityWrapper,
        ClassCard
    },
    data(){
        return {
            loading: false,
            showClassDialog: false,
            classDialogData: null,
        }
    },
    computed: {
        classes(){
            return this.$store.state.userModule.classesHistory;
        },
        nextUrl(){
            return this.$store.state.userModule.classesHistoryNextUrl;
        },
        ...GlobalComputed
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
    },
    methods: {
        openClassDialog(classData){
            this.showClassDialog = true;
            this.classDialogData = classData;
        },
        loadData(initialLoad=false){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('loadClassesHistory', {initialLoad}).then(()=>{
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        formatTime(d){
            const t = new Date(d);
            let hours = t.getHours(), 
                minutes = t.getMinutes() + '', 
                ampm = 'AM';

            if(hours > 11){
                hours = hours - 12;
                ampm = 'PM';
            }

            if(hours == 0) hours = 12;
            if(minutes.length == 1) minutes = '0' + minutes;

            return `${hours}:${minutes} ${ampm}`;
        },
        ...GlobalMethods
    },
    mounted(){
        if (!this.classes || !this.classes.length){
            this.loadData(true);
        }
    }
};
</script>
<style lang="scss" scoped>
.d-flex .v-chip{
    min-width: min-content;
}
</style>