<template>
    <div>
        <v-row class="mb-lg-3">
            <v-col cols="12" lg="6" class="pt-0 pb-2">
                <v-card class="soft-shadow px-2 py-1">
                    <v-card-text>
                        <div class="d-flex justify-space-between align-center w-full">

                            <div class="d-flex align-center">
                                <v-avatar size="60">
                                    <v-icon size="28" color="orange darken-2" class="orange lighten-5">mdi-cash-multiple</v-icon>
                                </v-avatar>
                                <div class="ms-4">
                                    <div class="mb-1">Salary</div>
                                    <h2 v-if="salary" class="strong-text--text font-weight-bold">{{moneyFormat(salary)}}</h2>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="6" class="pt-0 pb-2">
                <v-card class="soft-shadow px-2 py-1">
                    <v-card-text>
                        <div class="d-flex justify-space-between align-center w-full">

                            <div class="d-flex align-center">
                                <v-avatar size="60">
                                    <v-icon size="28" color="indigo" class="indigo lighten-5">mdi-check-decagram</v-icon>
                                </v-avatar>
                                <div class="ms-4">
                                    <div class="mb-1">Done Classes</div>
                                    <h2 v-if="classesCount" class="strong-text--text font-weight-bold">{{numFormat(classesCount)}}</h2>
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        
        <v-card :flat="phoneOnly" class="px-2 px-lg-3 soft-shadow">
            <v-card-title class="d-flex justify-space-between">
                <div>
                    {{title || 'Month Salary Report'}}
                </div>
                <div class="px-3">
                    
                </div>
            </v-card-title>
            <v-card-subtitle>List of done classes which are added to your salary</v-card-subtitle>
            <v-card-text v-if="!loading" :class="{'px-0': phoneOnly}">
                <v-simple-table v-if="classes && classes.length" class="e-data-table">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    ID
                                </th>
                                <th class="text-left">
                                    Amount
                                </th>
                                <th class="text-left">
                                    Student
                                </th>
                                <th class="text-left">
                                    Course
                                </th>
                                <th class="text-left">
                                    Duration
                                </th>
                                <th class="text-left">
                                    Hourly Price
                                </th>
                                <th class="text-right">
                                    Class Start Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="salaryClass in classes" :key="salaryClass.id">
                                <td>#{{salaryClass.id}}</td>
                                <td>
                                    <span class="font-weight-bold primary--text">{{moneyFormat(salaryClass.paid)}}</span>
                                </td>
                                <td>
                                    <span v-if="salaryClass.enrollment" class="font-weight-bold">{{salaryClass.enrollment.student.name}}</span>
                                </td>
                                <td>
                                    <span v-if="salaryClass.enrollment">{{salaryClass.enrollment.course.name}}</span>
                                </td>
                                <td>{{salaryClass.duration}}:00</td>
                                <td>{{moneyFormat(salaryClass.teacher_hourly_price)}}</td>
                                <td class="text-right">{{salaryClass.start_formated}} (Cairo)</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <div v-else-if="!loading">
                    <v-alert type="info" color="blue-grey" text>
                        No classes were found
                    </v-alert>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    props: {
        classes: {},
        loading: {type: Boolean, default: false},
        title: {},
        classesCount: {},
        salary: {},
    },
    data(){
        return {}
    },
    methods: {
        ...GlobalMethods,
    },
    computed: {
        ...GlobalComputed
    },

}
</script>

<style>

</style>