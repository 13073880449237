<template>
    <v-navigation-drawer :expand-on-click="false" mini-variant-width="85" :width="pcOnly ? 300 : 335" :mini-variant="mini" overlay-color="#000" overlay-opacity="0.65" app :permanent="pcOnly" fixed v-model="show" :temporary="phoneOnly" class="app-menu lg-shadow">
        <div v-if="phoneOnly" class="drawer-header pt-5 pt-lg-7">
            <div class="logo-wrapper d-flex align-center justify-space-between px-5" :style="iosSafeArea ? `padding-top: 25px!important` : ''">
                <logo />

                <v-btn large class="mb-2 ms-2" icon text @click.prevent="show=false">
                    <v-icon size="24">mdi-menu</v-icon>
                </v-btn>
            </div>
        </div>

        <v-list-item v-else class="py-2" :class="{'px-4': !mini && pcOnly}">
            <v-list-item-content>
                <logo :height="48" />
            </v-list-item-content>

            <v-btn large class="ml-2 mb-2" icon text @click.prevent="mini = !mini">
                <v-icon size="24">mdi-chevron-left</v-icon>
            </v-btn>
        </v-list-item>

        <v-list-item v-if="mini" class="mb-3">
            <v-btn color="primary" large class="mb-2" icon text @click.prevent="mini = !mini">
                <v-icon size="24">mdi-chevron-right</v-icon>
            </v-btn>
        </v-list-item>


        <v-card flat v-if="appData.ramadan_mode" class="my-4 my-lg-2 mx-4 pa-2 d-flex align-center justify-center" :class="{'purple lighten-5': !mini}">
            <div>
                <img src="/img/ramadan/ramadan.svg" :style="`width: ${mini ? 70 : 85}px; height: auto`" />
            </div>
        </v-card>

        <v-list  class="mt-3" :class="{'px-3': !mini && pcOnly}" nav dense>

            <!-- <v-divider class="mb-3" /> -->
            <v-list-item v-for="(item, i) in drawerRoutes" v-show="!item.pcOnly || pcOnly" color="primary" :key="i" link :to="item.to" :href="item.href" :target="item.href ? '_system' : ''" exact :class="{'v-list-item--active': $route.name === item.name, 'px-6': !mini}">
                <v-list-item-icon>
                    <v-badge small :value="!!item.chip && self[item.chip]" :content="self[item.chip]" overlap light :color="item.chipColor ? item.chipColor : 'ebadge'" left class="menu-item-badge">
                        <v-icon>{{ $route.name === item.name ? item.activeIcon || item.icon : item.icon }}</v-icon>
                    </v-badge>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title :style="$route.name === item.name ? 'font-weight: 500' : 'font-weight: 400!important'" style="font-size: 0.95rem; line-height: 1.4rem">{{item.text}}</v-list-item-title>
                </v-list-item-content>

            </v-list-item>

        </v-list>


        <div v-if="phoneOnly" class="px-9 my-5">
            <v-btn block outlined class="mx-auto" @click="show = false; logoutDialog = true">
                <v-icon left>mdi-account-arrow-right-outline</v-icon>
                Logout
            </v-btn>
        </div>

        <v-divider class="my-5"></v-divider>
        
        <div v-if="!capRunning" class="px-9 d-flex align-center mb-8 pb-8">
            <a href="https://play.google.com/store/apps/details?id=com.riwaqalquran.app" target="_blank">
                <img src="/img/playstore.svg" style="height: 40px;" />
            </a>
            <!-- <a href="https://play.google.com/store/apps/details?id=com.riwaqalquran.app">
                <img src="/img/appstore.svg" style="height: 36px;" class="ms-1" />
            </a> -->
        </div>

        <logout-dialog v-model="logoutDialog" />
    </v-navigation-drawer>

</template>

<script>
import GlobalMethods from '@/helpers/global-methods'
import GlobalComputed from '@/helpers/global-computed'
import Logo from './logo'
import drawerRoutes from '@/helpers/links'
import LogoutDialog from '../default/dialogs/logout'

export default {
    name: 'app-menu',
    props: ['value'],
    data(){
        return {
            self: this,

            show: false,
            logoutDialog: false,

            mini: false,
        }
    },
    components: {
        Logo,
        LogoutDialog,
        // StoreBadge
    },
    watch :{
        show(show){
            if (!show){
                this.$emit('input', false); // close
            }
        },
        value(show){
            this.show = show;
        }
    },
    computed: {
        ...GlobalComputed,
        walletDisabled(){
            return !this.appData.enable_app_w;
        },
        drawerRoutes(){
            let routes = this.isAuthed ? (this.isTeacher ? drawerRoutes.auth.teacher : drawerRoutes.auth.student) : drawerRoutes.guest;
            const self = this;
            return routes.filter(route=>{
                if (route.hide){
                    return !self[route.hide];
                }
                return true;
            })
        },
    },
    methods: {
        openRoute(route){
            this.show = false;
            this.$router.push(route);
        },
        openProfile(){
            let isProfile = this.$route.name === 'profile' && this.$route.params.id == this.user.id;
            if (!isProfile){
                this.$router.push({name: 'profile', params: {id: this.user.id}});
            }
        },
        ...GlobalMethods
    }
}
</script>

<style lang="scss" scoped>
    .app-menu{
        will-change: auto!important;
        border: none!important;
        &.mini{

        }
        .v-list-item{
            border-left: 3px solid transparent;
            &.v-list-item--active{
                border-left: 3px solid;
                border-top-left-radius: 0!important;
                border-bottom-left-radius: 0!important;
                // box-shadow: 0 1px 5px rgba(#000, 0.1);
            }

        }
        .theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before{
            opacity: 0.08!important;
        }
    }
</style>
<style lang="scss">
    .v-navigation-drawer__border{
        display: none;
    }
</style>
