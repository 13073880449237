import Vue from "vue";
import App from "./App.vue";

import "./registerServiceWorker";

/* Catch PWA install event */
window.addEventListener('beforeinstallprompt', e=>{
  e.preventDefault();
  // for later usage in vue components
  window.pwaDeferredPrompt = e;
});


import router from "./router";
import store from "./store";

// importing vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from "./plugins/vuetify"

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
Vue.use(VueTelInputVuetify, {vuetify});

import Logo from '@/components/main/logo'
Vue.component('logo', Logo)

window.apiUrl = process.env.VUE_APP_API_URL
import axios from 'axios'
import './registerServiceWorker'
axios.defaults.baseURL = window.apiUrl;
axios.defaults.headers.common['App-Platform'] = process.env.VUE_APP_PLATFORM;
axios.defaults.headers.common['App-Version'] = process.env.VUE_APP_VERSION;
window.axios = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");


// laravel Echo for realtime
window.Pusher = require('pusher-js');
