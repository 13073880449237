<template>
    <v-card tile class="notification" :class="{seen: seen, new: !seen, 'cursor-pointer': !!to}" v-ripple="ripple" :to="to" @click="clicked">
        <div class="d-flex">

            <div class="icon-wrapper me-4">
                <div class="icon" :class="color + ' lighten-5'">
                    <v-icon :color="color + ' darken-1'">{{icon.icon}}</v-icon>
                </div>
                <span class="time small-text" :class="{'grey--text': seen, 'font-weight-bold primary--text': !seen}">
                    <v-icon v-show="!seen" size="12" class="primary--text">mdi-flash</v-icon>
                    {{seen ? time : 'New'}}
                </span>
            </div>
            <div class="flex-grow notification-details">
                <v-card-title class="title pa-0" :class="titleColor">{{title}}</v-card-title>
                <v-card-subtitle class="content ma-0 pa-0" :class="{'primary--text': !seen}">{{content}}</v-card-subtitle>
            </div>

        </div>
    </v-card>
</template>
<script>
export default {
    props: ['notification'],
    data(){
        return {
            time: '',
        }
    },
    computed: {
        type(){
            return this.notification.data.type;
        },
        title(){
            return this.notification.data.title;
        },
        content(){
            return this.notification.data.content;
        },
        icon(){
            let icon = 'mdi-bell';
            const type = this.type;
            if (type === 'ticket_reply'){
                icon = 'mdi-help';
            }else if (type === 'admin_message'){
                icon = 'mdi-email';
            }else if (type === 'course_enrolled'){
                icon = 'mdi-book-clock';
            }else if (type === 'course_finished' || type === 'finish_enrollment_request'){
                icon = 'mdi-book-remove';
            }else if (type === 'enrollment_approved' || type === 'teacher_enrollment_approved'){
                icon = 'mdi-book-check';
            }else if (type === 'enrollment_action_needed'){
                icon = 'mdi-book-refresh';
            }else if (type === 'recharge_completed'){
                icon = 'mdi-wallet-plus';
            }else if (type === 'borrow_balance'){
                icon = 'mdi-cash-plus';
            }else if (type === 'class_canceled'){
                icon = 'mdi-close-circle';
            }else if (type === 'class_rescheduled' || type === 'class_rescheduled_rejected'){
                icon = 'mdi-calendar-clock';
            }
            return {icon};
        },
        color(){
            // return color depanding on notif type
            const type = this.type;
            if (type === 'ticket_reply'){
                return 'indigo';
            }
            if (type === 'admin_message'){
                return 'green';
            }
            if (type === 'course_enrolled'){
                return 'blue-grey';
            }
            if (type === 'course_finished' || type === 'finish_enrollment_request'){
                return 'error';
            }
            if (type === 'enrollment_approved' || type === 'teacher_enrollment_approved'){
                return 'green';
            }
            if (type === 'enrollment_action_needed'){
                return 'error';
            }
            if (type === 'recharge_completed'){
                return 'green';
            }
            if (type === 'class_canceled' || type === 'class_rescheduled_rejected'){
                return 'error';
            }
            if (type === 'class_rescheduled'){
                return 'teal';
            }
            if (type === 'borrow_balance'){
                return 'deep-purple';
            }
            return 'orange';
        },
        titleColor(){
            if (!this.seen){
                return `text--${this.color} text--darken-1`;
            }
            return 'text--lighten-2 black--text';
        },
        ripple(){
            return this.to ? {class: 'grey--text'} : false;
        },
        to(){
            if (this.notification.data.course_class_id || this.type === 'teacher_enrollment_approved'){
                return {name: 'calendar'};
            }
            if (this.notification.data.course_id){
                return {name: 'course', params: {id: this.notification.data.course_id}}
            }
            return null; 
        },
        seen(){
            return !!this.notification.read;
        }
    },
    methods: {
        clicked(){
            if (this.to){
                this.$emit('navigated', true);
            }
        },
        openLink(){
            let id = this.owned ? this.msg.receiver.id : this.msg.sender.id;
            this.$router.push({name: 'profile', params: {id}})
        },

        timeFormat() {
            const self = this;
            let time = this.notification.created_at;

            if (time == 'now') {
                return "just now";
            }
            
            // Split timestamp into [ Y, M, D, h, m, s ]

            var t = time.split(/[- :]/); // splitting timestamp into array t[], so t[0] is the year .. etc

            // Apply each element to the Date function
            var d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5])); // t[1]-1  -> months starts from 0

            d.setTime(d.getTime());

            var ms = new Date() - d;

            var since = self.timeSince(ms);
            self.time = since;

            // this is to update time every 20s
            this.intervalid1 = setInterval(function () {
                var ms = new Date() - d;

                var since = self.timeSince(ms);
                self.time = since;


            }, 20000);
        },
        timeSince (date) {

            var seconds = Math.floor(date / 1000);

            var interval = Math.floor(seconds / 31536000);

            if (interval >= 1) {
                if (interval == 1) {
                    return "1 year";
                } else {
                    return interval + " years";
                }
            }


            interval = Math.floor(seconds / 2592000);
            if (interval >= 1) {
                if (interval == 1) {
                    return "1 month";
                } else {
                    return interval + " months";
                }
            }


            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                if (interval == 1) {
                    return "1 day";
                } else {
                    return interval + " days";
                }
            }


            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                if (interval == 1) {
                    return "1 hour";
                } else {
                    return interval + " hours";
                }

                /*  else if (interval == 2) {
                    return "2 hours";
                } else if (interval <= 10) {
                    return "" + interval + " hours";
                } */
            }


            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
                if (interval == 1) {
                    return "1 minute";
                } else {
                    return interval + " minutes";
                }

            }

            return "just now";

        },
    },
    mounted(){
        this.timeFormat();
    }
    
}
</script>
<style lang="scss" scoped>
    .notification{
        box-shadow: none!important;
        border-bottom: 1px solid #eee;
        padding: 1rem;
        user-select: none;
        &.cursor-pointer{
            &, .title, .content{
                cursor: pointer;
            }
        }
        &.seen{
            opacity: 0.9;
            // filter: grayscale(0.3);
        }
        &.new{
            opacity: 1;
            .content{
                color: #343434!important;
                font-size: 13.5px;
                font-weight: 500;
            }
        }
        .title{
            font-size: 1rem!important;
            line-height: 1.4rem;
            word-break: normal;
            font-weight: 500;
            margin-bottom: 0.2rem!important;
            cursor: default;
        }
        .content{
            font-size: 13px!important;
            cursor: default;
            user-select: none;
            line-height: 1.4;
        }
        .icon-wrapper{
            display: flex;
            align-items: center;
            flex-direction: column;
            .time{
                //margin-top: 12px;
                line-height: 20px;
            }
            .icon{
                width: 58px;
                height: 58px;
                border-radius: 50%;
                overflow: hidden;
                background-color: #efefef;
                display: flex;
                justify-content: center;
                align-items: center;
    
                &.default-bg{
                    background-color: #faeec4!important;
                }
            }
        }
    }
</style>