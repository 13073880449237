<template>
    <div class="stripe-form">
        <!-- Display a payment form -->
        <form id="payment-form">
            <div id="payment-element">
                <!--Stripe.js injects the Payment Element-->
            </div>
            <v-fade-transition>
                <v-btn v-if="inited" color="primary" :loading="loading" :disabled="loading" block depressed large class="mt-5" @click="handleSubmit">
                    Pay Now
                </v-btn>
            </v-fade-transition>

            <div id="payment-message" class="hidden"></div>
        </form>
    </div>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import Vue from 'vue'

export default {
    components: {
    //   'paypal-buttons': PayPalButton,
    },
    props: {
        amount: {},
        disabled: {type: Boolean, default: false},
        email: {},
    },
    data(){
        return {
            stripeObject: null,
            elements: null,
            loading: false,
            inited: false,
        }
    },
    computed: {
        ...GlobalComputed
    },
    watch: {
        amount(){
            
        }
    },
    methods: {
        ...GlobalMethods,
        async handleSubmit(){ 
            this.loading =true;

            const { error } = await this.stripeObject.confirmPayment({
                elements: this.elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: window.apiUrl + "/stripe/complete",
                    receipt_email: this.email,
                },
            });

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                this.showMsg(error.message, 'error');
            } else {
                this.showMsg('An unexpected error occurred.', 'error');
            }

            this.loading = false;
        },
        initialize(){
            const data = {amount: this.amount};
            window.axios.post('/wallet/recharge/stripe/init', data).then(response=>{
                const clientSecret = response.data.clientSecret;
                this.initPaymentElements(clientSecret);
            }).catch(e=>{
                this.showMsg('Error occurred during initializing payment', 'error');
                console.error(e)
            })
        },

        initPaymentElements(clientSecret){
            const elements = this.stripeObject.elements({ clientSecret });
            this.elements = elements;
            const paymentElementOptions = {
                layout: "tabs",
            };

            const paymentElement = this.elements.create("payment", paymentElementOptions);
            paymentElement.mount("#payment-element");
            this.inited = true;
        }
    },
    created(){
        
    },
    mounted(){
        const stripeKey = this.appData.stripe_pk;
        const stripe = new window.Stripe(stripeKey);
        this.stripeObject = stripe;
        this.initialize();
    }
};
</script>
<style lang="scss" scoped>
.paypal-buttons{
    position: relative;
    .disabled-buttons-overlay{
        z-index: 100!important;
    }
}
</style>