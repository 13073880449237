export default {
    asset(name, type=null, publicPath=true){
        let path = process.env.VUE_APP_ASSETS_PATH ? process.env.VUE_APP_ASSETS_PATH : (publicPath ? '/' : '@/assets');
        if (path.slice(-1) !== '/'){
            path += '/';
        }
        if (type){
            const imageTypes = ['img', 'imgs', 'image', 'images'];
            if (imageTypes.includes(type)){
                path += 'img/';
            }
        }
        return path + name;
    },
    showMsg(text, color = 'error', topBar=false, loading=false, timeout = 5000){
        this.$store.dispatch('addSnackMsg', {show: true, text: text, color: color, timeout: timeout, msgBar: topBar, progress: loading});
    },
    closeMsg(){
        this.snackMsg = {show: false, text: '', msgBar: true, closeMsg: true};  // closeMsg to prevent infinity loop
    },
    setLinearLoading(loading=true){
        this.$store.commit('SET_LINEAR_LOADING', loading);
    },
    numFormat(value){
        let formated = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formated;
    },
    moneyFormat(value, currency = false){
        return '$' + this.numFormat( parseFloat(value).toFixed(2) );
    },
    dateTimeFormat(time, isTimestamp=false){
        var d;
        if (isTimestamp){
            d = new Date(time); // it's a timestamp
        }else{
            // Split timestamp into [ Y, M, D, h, m, s ]
            var t = time.split(/[- :]/); // splitting timestamp into array t[], so t[0] is the year .. etc
            // Apply each element to the Date function
            d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5])); // t[1]-1  -> months starts from 0
            d.setTime(d.getTime());
        }

        let month = d.getMonth()+1;
        let day = d.getDate();

        if (month < 10){
            month = "0" + month;
        }
        if (day < 10){
            day = "0" + day;
        }

        let hours = d.getHours();
        let minutes = d.getMinutes();
        let ampm = 'AM';
        if (hours >= 12){
            ampm = 'PM';
        }
        if (hours > 12){
            hours -= 12;
        }

        if (minutes < 10){
            minutes = "0" + minutes;
        }
        if (hours < 10){
            hours = "0" + hours;
        }
        return `${d.getFullYear()}-${month}-${day} | ${hours}:${minutes} ${ampm}`;
    },
    handleStatus(status){
        let statusColor = '',
            statusText = '';
        switch (status) {
            case 'review':
                statusColor = 'blue-grey';
                statusText = 'In Review';
                break;

            case 'pending':
                statusColor = 'blue-grey';
                statusText = 'Pending';
                break;
            
            case 'updated':
                statusColor = 'indigo';
                statusText = 'Form Updated';
                break;

            case 'completed':
                statusColor = 'success';
                statusText = 'Completed';
                break;
            case 'joined':
                statusColor = 'success';
                statusText = 'Enrolled';
                break;
            case 'action-needed':
                statusColor = 'red';
                statusText = 'Action Needed';
                break;
            case 'approved':
                statusColor = 'success';
                statusText = 'Approved';
                break;
            case 'finished':
                statusColor = 'black';
                statusText = 'Finished';
                break;
            case 'canceled':
                statusColor = 'red';
                statusText = 'Canceled';
                break;

            case 'suspended':
                statusColor = 'red';
                statusText = 'Suspended';
                break;

            case 'not-verified':
                statusColor = 'orange';
                statusText = 'Not Verified';
                break;

            case 'active':
                statusColor = 'success';
                statusText = 'Active';
                break;

            case 'not-approved':
                statusColor = 'orange';
                statusText = 'pending';
                break;


            case 'reviewed':
                statusColor = ''; // light...
                statusText = 'reviewed';
                break;

            case 'unread':
                statusColor = 'primary';
                statusText = 'unread';
                break;

            case 'expired':
                statusColor = 'grey darken-1';
                statusText = 'Attended';
                break;
            case 'missed':
                statusColor = 'error darken-4';
                statusText = 'Missed';
                break;

            case 'scheduled':
                statusColor = 'primary';
                statusText = 'Scheduled';
                break;

            case 'running':
                statusColor = 'blue darken-1';
                statusText = 'Running';
                break;

                    
            default:
                statusColor = 'black';
                statusText = status;
                break;
        }
        return {color: statusColor, text: statusText}
    },

    timeSince (time, isTimestamp=false) {
        // parse time/date object
        let dateObject;
        if (isTimestamp){
            dateObject = new Date(time); // it's a timestamp
        }else{
            // Split datetime into [ Y, M, D, h, m, s ]
            var t = time.split(/[- :]/); // splitting datetime into array t[], so t[0] is the year .. etc
            // Apply each element to the Date function
            dateObject = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5])); // t[1]-1  -> months starts from 0
            dateObject.setTime(dateObject.getTime());
        }

        // format
        var milliseconds = new Date() - dateObject;
        let suffix = 'ago';
        if (milliseconds < 0){
            // it's a future time not past ("left" not "ago")
            milliseconds = -milliseconds;
            suffix = 'left';
        }

        var seconds = Math.floor(milliseconds / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval >= 1) {
            const unit = interval > 1 ? 'years' : 'year';
            return `${interval} ${unit} ${suffix}`;
        }


        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            const unit = interval > 1 ? 'months' : 'month';
            return `${interval} ${unit} ${suffix}`;
        }


        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            const unit = interval > 1 ? 'days' : 'day';
            return `${interval} ${unit} ${suffix}`;
        }


        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            const unit = interval > 1 ? 'hours' : 'hour';
            return `${interval} ${unit} ${suffix}`;
        }


        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            const unit = interval > 1 ? 'minutes' : 'minute';
            return `${interval} ${unit} ${suffix}`;
        }

        return "now";

    },
    scrollToTop(){
        let scrollTo = 0; // scrollTop = top-offset-of-bottom-of-page = full height - screen height (visible area) 
        this.$vuetify.goTo(scrollTo, {duration: 250});
    },
}
