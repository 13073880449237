<template>    
    <div class="conversation-wrapper d-flex flex-column pt-2" style="position: relative">
        <v-progress-linear v-if="loading" height="1" :active="loading" :indeterminate="loading" absolute top color="primary" ></v-progress-linear>
        <!-- <v-toolbar flat class="w-full px-0" height="55" color="grey lighten-5" style="max-height: 55px;">
            <v-toolbar-title class="medium-text"></v-toolbar-title>
        </v-toolbar> -->

        <div ref="messagesContainer" class="flex-grow-1 messages-container px-3 pa-lg-3 e-scrollbar d-flex flex-column-reverse" style="transition: 0.1s ease all;">

            <template v-if="!loading && messages && messages.length">
                <div v-for="(msg, index) in messages" :key="msg.id">
                    <message :msg="msg" :show-avatar="!messages[index-1] || messages[index-1]['sender']['id'] !== msg.sender.id || messages[index-1]['sender_type'] !== msg.sender_type" :owned="!msg.sender.support && msg.sender_type !== 'App\\Admin' && msg.sender.id === user.id" />
                </div>
            </template>
            <div v-else-if="loading" style="opacity: 0.4">
                <!-- <v-skeleton-loader type="list-item-avatar-two-line" class="py-0" style="max-width: 200px" ></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-three-line" class="py-0" style="max-width: 200px; transform: rotate(180deg); margin-left: auto"  ></v-skeleton-loader> -->
            </div>
            <div v-else>
                <v-img src="/img/chat-vector.svg" max-height="300" contain />
            </div>


            <div v-if="loadedOnce && conversationData && messages.length">
                <v-btn v-if="next" small text class="mb-3" :disabled="loadingMore" @click="loadMoreMessages" block>
                    {{loadingMore ? 'Loading...' : 'Load More Messages'}}
                </v-btn>

                <div v-else class="text-center mb-3">
                    <span class="small-text grey--text">Start Of Conversation</span>
                </div>
            </div>

        </div>

        <div class="d-flex align-center w-full pa-0" :style="`height: ${pcOnly ? 70 : 60}px; max-height: ${pcOnly ? 70 : 60}px;`" :class="{'white border-top': pcOnly, 'border-top': phoneOnly}">
            <template v-if="!conversation.closed">
                <div class="px-lg-2">
                    <chat-img :chat-id="conversation.id" />
                    <!-- <v-btn large icon>
                        <v-icon size="25">mdi-image</v-icon>
                    </v-btn> -->
                </div>
                <div class="msg-textarea flex-grow-1">
                    <v-form ref="form" @submit.prevent="sendMsg">
                        <v-textarea ref="msgTextarea" :style="`direction: ${textareaDir}!important;`" flat rounded v-model="newMessage" :disabled="sending || loading" :background-color="pcOnly ? 'grey lighten-4' : ''" placeholder="Type a message" rows="1" hide-details no-resize color="text" solo @keypress="textareaKeyHandler"></v-textarea>
                    </v-form>
                </div>
                <div class="px-lg-2">
                    <v-btn large icon :color="newMessage ? 'primary' : 'text'" :disabled="sending || loading" :loading="sending" @click="sendMsg">
                        <v-icon size="24">mdi-send</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-else>
                <div class="d-flex align-center justify-center w-full">
                    <v-icon size="20" class="me-2">mdi-lock-outline</v-icon>
                    <span>Conversation is closed!</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import ChatImg from './chat-img'
import Message from './message'
export default {
    name: 'Inbox',
    props: {
        conversation: {},
    },
    components: {
        ChatImg,
        Message
    },
    data(){
        return {
            loading: false,
            loadingMore: false,
            sending: false,
            connectedChannel: null,

            newMessage: '',
            
            conversationData: null,
            messages: [],
            next: null,

            textareaDir: 'ltr',

            loadedOnce: false,
        };
    },
    computed:{
        ...GlobalComputed,

        /* messages(){
            let messages = [];
            for (let i=1; i<=20; i++){
                messages.push({
                    id: i,
                    text: 'Text Message: ' + i,
                    type: 'text',
                    sender: {
                        id: i%5 === 0 ? this.user.id : 5,
                        avatar: this.user.avatar,
                        name: i%5 ? this.user.name : 'Ebrahim Maher',
                    },
                    created_at: '2021-11-08 18:30:00',
                    created_at_timestamp: 1636391319799,

                })
            }
            return messages;
        } */
        
    },
    watch: {
        loading(loading){
            this.$emit('loading', loading);
        },
        newMessage(){
            this.setTextareaDir();
        },
        conversation(conversation){
            if (conversation.id !== this.conversationData.id){
                this.reset();
                this.loadConversationData();
            }
        }
    },
    methods:{
        ...GlobalMethods,
        reset(){
            if (this.sending){
                this.sending = false;
            }
            this.newMessage = '';
            if (this.conversationData){
                this.conversationData = null;
            }
            if (this.messages.length){
                this.messages = [];
            }
            this.next = null;
            this.loadedOnce = false;
        },
        loadConversationData(){
            this.loading = true;
            const conversation = this.conversation;
            this.$store.dispatch('getConversationMessages', {id: conversation.id}).then(response=>{
                this.conversationData = conversation;
                this.messages = response.data.data ? response.data.data : [];
                this.next = response.data.links ? response.data.links.next : null;
                this.loading = false;

                // conntect to websockets
                this.leaveRealtimeChat();
                this.setRealtimeChat();

                this.updateSeen();
            }).catch(e=>{
                this.showMsg('Error occurred during preforming your action', 'error');
                this.loading = false;
            })
        },
        sendMsg(){
            if (!this.sending && this.newMessage){
                this.sending = true;
                const conversation = this.conversationData;
                this.$store.dispatch('newChatMsg', {text: this.newMessage, id: conversation.id}).then(response=>{
                    this.sending = false;
                    const message = response.data.data;
                    // if (conversation.id === this.conversationData.id){
                    //     this.messages.unshift(message);
                    // }
                    this.$store.commit('UPDATE_CONVERSATION_DATA', {id: conversation.id, last_message: message, seen: true});
                    this.newMessage = '';
                    window.setTimeout(()=>{
                        this.resetScroll();
                        this.focusOnTextarea();
                        this.updateSeen();
                    }, 100);
                }).catch(e=>{
                    this.sending = false;
                    this.showMsg('Error occurred during preforming your action', 'error');
                })
            }
            this.newMessage = '';
        },
        updateSeen(){
            this.loadedOnce = true;
            if (!this.conversationData.seen){
                this.$store.commit('UPDATE_CONVERSATION_DATA', {id: this.conversationData.id, seen: true});
            }
        },
        
        autoResetScroll(){
            const el = this.$refs.messagesContainer;
            let height = el.offsetHeight,
                scrollBottom = el.scrollTop + el.clientHeight;
            // if current scroll is > up 150px from bottom offset
            if (height - scrollBottom < 150){
                this.resetScroll();
            }
        },
        resetScroll(){
            const el = this.$refs.messagesContainer;
            if (el.scrollTop < -20){
                let scrollTo = el.offsetHeight - el.clientHeight + 500; // scrollTop = top-offset-of-bottom-of-page = full height - screen height (visible area) 
                this.$vuetify.goTo(scrollTo, {duration: 100, container: el});
            }
        },
        focusOnTextarea(){
            this.$refs.msgTextarea.focus();
        },
        setRealtimeChat(){
            let channel = `Conversations.${this.conversationData.id}`;
            this.connectedChannel = channel;
            window.Echo.private(channel)
                .listen('NewConversationMessage', (response) => {
                    let data = response.data;
                    this.messages.unshift(data.data);
                    // this.autoResetScroll();
                });
        },
        leaveRealtimeChat(){
            if (this.connectedChannel){
                window.Echo.leave(this.connectedChannel);
                // window.console.log('leaved: ' + this.connectedChannel);
            }
        },
        loadMoreMessages(){
            if (this.next && !this.loadingMore){
                this.loadingMore = true;

                this.$store.dispatch('getConversationMessages', {id: this.conversationData.id, next: this.next}).then((response)=>{
                    this.loadingMore = false;
                    // this.$refs.messagesContainer.scrollTo(0, 1);
                    const messages = response.data.data ? response.data.data : [];
                    this.messages.push(...messages);
                    this.next = response.data.links ? response.data.links.next : null;

                }).catch(()=>{
                    this.loadingMore = false;
                })
            }
        },
        scrollListener(){
            const el = this.$refs.messagesContainer;
            el.addEventListener('scroll', () => {
                const currentScroll = -1 * el.scrollTop;
                const totalScroll = -1 * (el.offsetHeight - el.scrollHeight);
                if (currentScroll > (totalScroll - 100)){
                    // user is close to end, load new data
                    if (!this.loadingMore){
                        this.loadMoreMessages();
                    }
                }
            });
        },
        textareaKeyHandler(e){
            if(e.which === 13 && !e.shiftKey) {
                e.preventDefault();
                this.sendMsg();
            }
        },
        setTextareaDir(){
            const rtlChar = /[\u0590-\u083F]|[\u08A0-\u08FF]|[\uFB1D-\uFDFF]|[\uFE70-\uFEFF]/mg;
            const text = this.newMessage;
            if (text){
                if (rtlChar.test(text.charAt(0))){
                    // is rtl 
                    this.textareaDir = 'rtl';
                }else{
                    this.textareaDir = 'ltr';
                }
            }
        },
    },
    mounted(){
        if (!this.conversationData){
            this.loadConversationData();
            this.scrollListener();
        }
    }

}
</script>

<style lang="scss" scoped>
    .conversation-wrapper{
        .messages-container{
            overflow-y: auto;
            flex: 1 1 0;
        }
        .border-top{
            border-top: 1px solid rgba(0,0,0, .1)!important;
        }
        .msg-textarea{
            padding: 0 5px;
            .v-input__slot{
                margin: 0!important;
                padding: 0!important;
                box-shadow: none!important;
            }
            .v-input__control{
                min-height: 40px!important;
            }
            textarea{
                font-size: 14.5px;
                line-height: 1.5rem;
                &::placeholder{
                    font-size: 15.5px;
                }
                margin-top: 0!important;
                padding: 10px 0;

            }
        }
    }
</style>