<template>
    <div class="page" :class="{'surface fill-height': phoneOnly}">
        <v-container :class="{'px-0': phoneOnly}">
            <!-- <h1 class="mb-2">reports</h1>
            <p>Hello, welcome to Riwaq Al Quran Portal</p> -->

            <v-card :flat="phoneOnly" class="px-1 px-lg-3" :class="{'transparent': phoneOnly, 'soft-shadow': pcOnly}">
                <v-card-title class="d-flex justify-space-between">
                    <div>
                        <span>Class Reports</span>
                    </div>
                </v-card-title>
                <v-card-subtitle>Reports added by your tutor after each class</v-card-subtitle>
                <v-card-text :class="{'px-0': phoneOnly}">
                    <div class="px-4 px-lg-0 mb-3">
                        <enrollments-select v-model="enrollment_id" />
                    </div>
                    
                    <v-list v-if="reports && reports.length" max-width="850px" two-line class="pa-0 ma-0 transparent">
                        <template v-for="(report, i) in reports" >
                            <v-list-item :key="report.id" v-ripple="phoneOnly ? {class: 'ripple--color'} : false" @click="dialogReport = report; showReportDialog = true;">
                                <v-list-item-avatar tile class="primary me-lg-6" size="45">
                                    <v-icon color="white" style="opacity: 0.85">mdi-file-document-outline</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{isTeacher ? report.student.name + ' - ' : ''}}
                                        {{report.class ? report.class.enrollment.course.name : ''}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="details-text">
                                        <div class="d-flex align-center">
                                            <div class="d-flex align-center">
                                                <span class="details-text primary--text me-1">({{report.rating}})</span>
                                                <v-rating :value="report.rating" color="primary" half-increments background-color="muted lighten-2" hover length="5" readonly dense size="14" half-icon="mdi-star-half-full" ></v-rating>
                                            </div>
                                            <div class="ms-3">
                                                <span>{{dateTimeFormat(report.created_at_timestamp, true)}} <!-- <span class="muted--text">({{report.created_at_readable}})</span> --></span>
                                            </div>
                                        </div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon v-if="pcOnly || isTeacher" class="my-auto pa-0 ms-2">
                                    <v-icon v-if="isStudent" color="primary">mdi-chevron-right</v-icon>
                                    <v-btn v-else :large="pcOnly" color="muted" icon @mousedown.stop @touchstart.stop @click.stop="openEditDialog(report)">
                                        <v-icon size="23">mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>

                            <v-divider v-if="i < reports.length-1" inset style="opacity: 0.6" :key="'divider-' + report.id"></v-divider>
                        </template>

                    </v-list>
                    <div v-else-if="!loading">
                        <v-alert type="info" color="blue-grey" text>
                            You don't have class reports yet
                        </v-alert>
                    </div>


                    <div v-if="reportsNext" class="mt-2" @click="loadData()">
                        <v-btn block text color="primary">Load More Reports</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>

        <v-dialog v-model="showReportDialog" max-width="600" :fullscreen="phoneOnly">
            <v-card v-if="dialogReport" :style="`${iosSafeArea ? 'padding-top: 40px!important; ' : ''}`">
                <v-card-text class="py-2">
                    <div class="d-flex align-center pt-3">
                        <div class="me-4">
                            <router-link :to="isStudent ? {name: 'course', params: {id: dialogReport.class.enrollment.course.id}} : ''">
                                <v-avatar tile width="55" height="45" style="border-radius: 5px!important" v-ripple="{class: 'black--text'}">
                                    <v-img :src="dialogReport.class.enrollment.course.image_url" />
                                </v-avatar>
                            </router-link>
                        </div>
                        <div>
                            <div class="strong-text--text medium-text font-weight-medium nowrap mb-1">{{dialogReport.class ? dialogReport.class.enrollment.course.name : ''}}</div>
                            <div class="details-text">{{isTeacher ? dialogReport.student.name : dialogReport.teacher.name }}</div>
                        </div>
                    </div>
                    <v-divider class="my-5" style="opacity: 0.6"></v-divider>
                    <div>
                        <h3 class="strong-text--text font-weight-medium mb-1">Assesment</h3>
                        <div class="d-flex">
                            <span class="medium-text font-weight-semibold primary--text me-1">({{dialogReport.rating}})</span>
                            <v-rating :value="dialogReport.rating" color="primary" half-increments background-color="muted lighten-2" hover length="5" readonly dense size="18" half-icon="mdi-star-half-full" ></v-rating>
                        </div>
                    </div>
                    <v-divider class="my-5" style="opacity: 0.6"></v-divider>
                    <div>
                        <h3 class="strong-text--text font-weight-semibold mb-1">Class Activity</h3>
                        <p class="text--text" style="white-space: pre-line;" v-html="urlify(escapeHtmlText(dialogReport.class_activity))">
                        </p>
                    </div>
                    <v-divider class="my-5" style="opacity: 0.6"></v-divider>
                    <div>
                        <h3 class="strong-text--text font-weight-semibold mb-1">Homework</h3>
                        <p class="text--text" style="white-space: pre-line;" v-html="urlify(escapeHtmlText(dialogReport.homework))">
                        </p>
                    </div>
                    <template v-if="dialogReport.teacher_notes">
                        <v-divider class="my-5" style="opacity: 0.6"></v-divider>
                        <div>
                            <h3 class="strong-text--text font-weight-medium mb-1">Teacher Notes</h3>
                            <p class="text--text" style="white-space: pre-line;" v-html="urlify(escapeHtmlText(dialogReport.teacher_notes))">
                            </p>
                        </div>
                    </template>

                </v-card-text>
                <v-btn icon absolute top right @click="showReportDialog = false" :style="`${iosSafeArea ? 'top: 46px!important; ' : ''}`">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" :disabled="editing" @click="editDialog = false">Cancel</v-btn>
                    <v-btn :loading="editing" :disabled="editing" text color="success" @click="addNewClassReport">Confirm</v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
        
        <v-dialog v-model="editDialog" max-width="450" :fullscreen="phoneOnly" :fluid="phoneOnly">
            <v-card :class="{'fill-height': phoneOnly}" :style="`${iosSafeArea ? 'padding-top: 40px!important; ' : ''}`">
                <v-card-title class="mb-1">Edit Class Report</v-card-title>
                <!-- <v-card-subtitle>edit class report then save</v-card-subtitle> -->
                <v-card-text>
                    <v-form ref="addReportForm">
                        <div class="mb-4 d-flex justify-center align-center">
                            <v-rating v-model="editReportForm.rating" color="primary" half-increments background-color="muted lighten-2" hover length="5" size="28" half-icon="mdi-star-half-full" ></v-rating>
                            <div class="text-center primary--text font-weight-bold ms-4">
                                <v-chip small :outlined="editReportForm.rating < 5" :color="!editReportForm.rating || editReportForm.rating < 2 ? 'muted' : 'primary'">{{editReportForm.rating ? editReportForm.rating.toFixed(1) : '---'}}</v-chip>
                            </div>
                        </div>
                        <v-divider class="mb-6 mb-lg-8"></v-divider>
                        <v-textarea v-model="editReportForm.class_activity" label="Class Activity *" :disabled="editing" rows="4" outlined></v-textarea>
                        <v-textarea v-model="editReportForm.homework" label="Homework *" :disabled="editing" no-resize rows="4" outlined></v-textarea>
                        <v-textarea v-model="editReportForm.teacher_notes" label="Teacher Notes" :disabled="editing" rows="4" outlined></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" :disabled="editing" @click="updateClassReport = false">Cancel</v-btn>
                    <v-btn text color="primary" :disabled="editing" @click="updateClassReport">Save Report</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import EnrollmentsSelect from '@/components/custom/enrollments-select'

import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';


export default {
    name: "reports",
    components: {
        EnrollmentsSelect
    },
    data(){
        return {
            searchQuery: '',
            loading: false,

            enrollment_id: '',

            editDialog: false,
            editing: false,
            
            editReportForm: {
                id: null,
                rating: '',
                class_activity: '',
                homework: '',
                teacher_notes: '',
            },

            showReportDialog: false,
            dialogReport: null,
        }
    },
    computed: {
        reports(){
            return this.$store.state.classReportsModule.reports;
        },
        reportsNext(){
            return this.$store.state.classReportsModule.reportsNext;
        },
        ...GlobalComputed
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
        enrollment_id(){
            this.loadData(true);
        }
    },
    methods: {
        openEditDialog(report){
            Object.assign(this.editReportForm, {
                id: report.id,
                rating: report.rating,
                class_activity: report.class_activity,
                homework: report.homework,
                teacher_notes: report.teacher_notes,
            });
            this.editDialog = true;
        },
        updateClassReport(){
            if (!this.editing){
                this.editing = true;
                const data = this.editReportForm;
                this.$store.dispatch('updateClassReport', {id: this.editReportForm.id, data}).then(()=>{
                    this.editing = false;
                    this.editDialog = false;
                    this.showMsg('Class Report Saved Successfully', 'success');
                    
                }).catch(()=>{
                    this.editing = false;
                    this.showMsg('Error occurred during preforming the action', 'error');
                })
            }
        },
        
        loadData(initialLoad=false){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('loadClassReports', {initialLoad, enrollment_id: this.enrollment_id}).then(()=>{
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        urlify(text){
            const options = { defaultProtocol: 'https' , target: '_system'};
            return linkifyHtml(text, options);
        },
        escapeHtmlText(text){
            // to prevent XSS attacks
            return text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
        },
        ...GlobalMethods
    },
    mounted(){
        /* if (!this.reports || this.reports.length === 0){
            this.loadData(true);
        } */
        this.loadData(true);
    }
};
</script>
