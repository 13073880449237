<template>
    <v-app-bar :height="heightComputed" :fixed="phoneOnly" :absolute="pcOnly" class="navbar" :class="{'status-overlay-navbar': iosSafeArea, 'appbg': isAuthed && pcOnly, 'surface': !isAuthed || phoneOnly, 'is-app': capRunning}" app light flat>
        <div class="w-100" :class="{'fill-height': !iosSafeArea, 'd-flex flex-column': iosSafeArea}">
            <div v-if="iosSafeArea" class="status-overlay px-3 d-flex flex-row-reverse align-center w-100" style="height: 40px">
                <template v-if="!isProduction">
                    <v-spacer></v-spacer>
                    <v-icon small class="ms-1">mdi-wifi-strength-4</v-icon>
                    <v-icon small class="ms-1">mdi-signal-cellular-outline</v-icon>
                    <v-icon small class="ms-1">mdi-battery</v-icon>
                    <span class="details-text me-1">12:30</span>
                </template>
            </div>
            <v-container class="d-flex fill-height align-center flex-row" :class="{'px-1 py-0': !iosSafeArea, 'px-4 py-2': iosSafeArea}">
                <v-spacer v-if="phoneOnly && !isAuthed"></v-spacer>
                <div class="d-flex">
                    <div class="d-flex align-center justify-center">
                        <div v-if="isAuthed" class="d-flex align-center">
                            <v-btn v-if="phoneOnly" icon @click.prevent="$emit('menu')" class="me-0">
                                <v-icon>mdi-menu</v-icon>
                            </v-btn>
                            <logo v-if="phoneOnly" :link="$route.name !== 'login-teachers'" :height="44" />
                            <h2 v-else class="title">{{routeName}}</h2>
                        </div>
                        <div v-else>
                            <logo :link="$route.name !== 'login-teachers'" :height="pcOnly ? 50 : 44" />
                        </div>
                    </div>
                </div>
            <v-spacer />
                <div v-if="$route.name !== 'login-teachers'" class="d-flex align-center">
                    <template v-if="isAuthed">
                        <!-- Credits Badge -->
                        <credits-badge v-if="pcOnly" />

                        <inbox-button />

                        <notifications-menu v-if="pcOnly" />
                        <notifications-dialog v-else />
                    </template>
                    <div v-else-if="pcOnly" class="mx-3">
                        <!-- <avatar :to="{name: 'login'}" :size="30" :icon-size="25"/> -->

                        <v-btn class="ms-2" :to="{name: 'help'}" color="primary" text depressed>Contact Us</v-btn>
                        <!-- <v-btn class="ms-2" :to="{name: 'login-teachers'}" color="primary" text depressed>Teachers Sign In</v-btn> -->
                        <v-btn v-if="fetchedUserCountry === 'EG'" class="ms-3 me-2" outlined :to="{name: 'login-teachers'}" color="primary" depressed>Teachers Sign In</v-btn>
                        <v-btn class="ms-2" v-if="$route.name === 'login'" :disabled="fetchedUserCountry === 'EG'" :to="{name: 'register'}" color="primary" depressed>New Account</v-btn>
                        <v-btn v-if="$route.name !== 'login'" class="ms-3" :to="{name: 'login'}" color="primary" depressed>Sign in</v-btn>
                    </div>
                    
                    <user-nav v-if="isAuthed && pcOnly" :name="user.name" :avatar="user.avatar" />
                    
                    <!-- <v-divider vertical class="ma-2" /> -->
                    <v-btn v-if="!isAuthed && pcOnly" icon @click.prevent="$emit('menu')">
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </div>
            </v-container>
        </div>
        <v-progress-linear v-if="linearLoading" height="1" :active="linearLoading" :indeterminate="linearLoading" absolute top color="primary" ></v-progress-linear>

        <!-- <install-pwa-dialog v-if="pcOnly && !capRunning" v-model="pwaDialog" /> -->

    </v-app-bar>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import NotificationsMenu from './notifications-menu'
import NotificationsDialog from './notifications-dialog'
import InboxButton from './inbox-button'
import drawerRoutes from '@/helpers/links'

import CreditsBadge from '@/components/custom/credits-badge'


import Logo from '../logo'
// import Avatar from '../../default/avatar'
import UserNav from './user-nav'


export default {
    props: {
        height: {
            default: 80,
            type: Number
        }
    },
    data(){
        return {
            
        }
    },
    components: {
        Logo,
        CreditsBadge,
        NotificationsMenu,
        NotificationsDialog,
        UserNav,
        InboxButton,
        // Avatar,
    },
    computed: {
        heightComputed(){
            let height = this.height;
            if (this.iosSafeArea){
                height += 40;
            }
            return height;
        },
        linearLoading(){
            return this.$store.state.globalModule.linearLoading;
        },
        routeName(){
            const routes = this.isTeacher ? drawerRoutes.auth.teacher : drawerRoutes.auth.student;
            const routeLink = routes.find(route=>route.name === this.$route.name);
            return routeLink ? routeLink.text : this.$route.meta.title;
        },
        ...GlobalComputed
    },
    methods: {
        ...GlobalMethods
    }
}
</script>

<style lang="scss" scoped>
    .navbar{
        .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before{
            opacity: 0!important;
        }

        &.surface{
            box-shadow: 0 1px 4px 0 rgba(0,0,0, .1)!important;

            &.is-app{
                box-shadow: none!important;
                border-bottom: 1px solid rgba(#000, 0.1)!important;
            }
        }

        @media(max-width: 700px){
            .v-toolbar__content .v-btn.v-btn--icon {
                margin: 0 -10px;
            }
        }
        .status-overlay{
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
</style>
<style lang="scss">
.status-overlay-navbar{
    .v-toolbar__content{
        padding: 0!important;
        align-items: start!important;
    }
}
</style>