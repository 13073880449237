<template>
    <div class="paypal-buttons">
        <div id="paypal-button-container"></div>
        <v-overlay v-if="!amount || disabled || loading" class="disabled-buttons-overlay select-none" color="surface" opacity="0.4" absolute></v-overlay>
    </div>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import Vue from 'vue'
// const PayPalButton = window.paypal.Buttons.driver("vue", Vue);
const paypal = window.paypal;

export default {
    components: {
    //   'paypal-buttons': PayPalButton,
    },
    props: {
        amount: {},
        disabled: {type: Boolean, default: false}
    },
    data(){
        return {
            paypalObject: null,
            loading: false,
        }
    },
    computed: {
        options(){
            const self = this,
                amount = this.amount;
            return {
                createOrder(data, actions) {
                    this.loading = true;
                    return actions.order.create({
                        intent: 'CAPTURE',
                        purchase_units: [
                            {amount: {value: amount}},
                        ],
                        application_context: {
                            landing_page: 'BILLING',
                            brand_name: 'Riwaq Al Quran',
                            locale: 'en-US',
                            shipping_preference: 'NO_SHIPPING'
                        }
                    });
                },
                onApprove(data, actions) {
                    return actions.order.capture().then(response=>{
                        this.loading = false;
                        self.$emit('captured', response);
                    }).catch(e=>{
                        this.loading = false;
                        self.$emit('error', e);
                    });
                },
            }
        },
        ...GlobalComputed
    },
    watch: {
        amount(){
            this.paypalObject.updateProps(this.options);
        }
    },
    methods: {
        ...GlobalMethods,
    },
    created(){
        
    },
    mounted(){
        this.paypalObject = window.paypal.Buttons();
        this.paypalObject.updateProps(this.options);
        this.paypalObject.render('#paypal-button-container');
    }
};
</script>
<style lang="scss" scoped>
.paypal-buttons{
    position: relative;
    .disabled-buttons-overlay{
        z-index: 100!important;
    }
}
</style>