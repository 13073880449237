<template> 
    <div>
        <!-- Header Slider -->
        <div v-if="slideImages && slideImages.length" class="mb-4 mb-lg-10">
            <v-carousel :show-arrows-on-hover="slideImages.length > 1" cycle :height="pcOnly ? 450 : 150" :show-arrows="slideImages.length > 1" :continuous="true" class="header-carousel" :hide-delimiters="slideImages.length <= 1" :class="{'bordered': (!slideImages || !slideImages.length)}">
                <template v-slot:prev="{ on, attrs }">
                    <v-btn large fab v-bind="attrs" v-on="on" class="transparent" depressed>
                        <v-icon large>mdi-chevron-right</v-icon>
                    </v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                    <v-btn large fab v-bind="attrs" v-on="on" class="transparent" depressed>
                        <v-icon large>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <v-carousel-item v-for="(img,i) in slideImages" :key="i">
                    <a v-if="img.href" :href="img.href" :target="capRunning ? '_system' : '_blank'">
                        <div :style="`width: 100%; height: 100%; background-image: url(${img.url}); background-size: cover; background-position: center;`" class="p-relative">
                            <v-btn fab :small="pcOnly" :x-small="phoneOnly" absolute top right depressed  style="top: 10px; background-color: rgba(0,0,0, .25);">
                                <v-icon>mdi-open-in-new</v-icon>
                            </v-btn>
                        </div>
                    </a>
                    <div v-else :style="`width: 100%; height: 100%; background-image: url(${img.url}); background-size: cover; background-position: center;`" />
                    <!-- <v-img :src="img.url" center contain></v-img> -->
                </v-carousel-item>
            </v-carousel>
        </div>

        <v-card class="soft-shadow px-2 py-1 mb-3">
            <v-card-title class="d-flex justify-space-between">
                <div>Upcoming Class</div>
                <div>
                    <v-btn :icon="phoneOnly" color="blue-grey darken-2" :loading="loading" :disabled="loading" outlined class="me-2" @click="$emit('load')">
                        <v-icon>mdi-reload</v-icon>
                    </v-btn>
                    <v-btn :icon="phoneOnly" color="blue-grey darken-2" outlined :to="{name: 'calendar'}" depressed>
                        <v-icon size="22" :left="pcOnly">mdi-calendar</v-icon>
                        <span v-if="pcOnly">Calendar</span>
                    </v-btn>
                    <v-btn :icon="phoneOnly" color="deep-purple accent-4" class="ms-2" outlined depressed @click="addClassDialog = true">
                        <v-icon size="22" :left="pcOnly">mdi-shape-square-rounded-plus</v-icon>
                        <span v-if="pcOnly">Add Class</span>
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-subtitle class="mb-2">Open calendar to view more classes</v-card-subtitle>
            <v-card-text style="max-width: 750px">
                <v-alert v-if="!nextClass" text color="blue-grey" type="info">
                    <div>
                        It seems that you don't have classes...
                    </div>
                </v-alert>

                <upcoming-class-card v-else :class-data="nextClass" />
            </v-card-text>
        </v-card>
        <v-row class="my-4">
            <v-col class="py-1 py-xl-0" cols="12" md="6" lg="6" xl="3">
                <v-card class="soft-shadow px-2 py-1">
                    <v-card-text>
                        
                        <div class="d-flex justify-space-between align-center w-full">

                            <div class="d-flex align-center">
                                <v-avatar size="50">
                                    <v-icon size="28" color="indigo" class="bordered">mdi-account-group</v-icon>
                                </v-avatar>
                                <div class="ms-4">
                                    <div class="mb-1">My Students</div>
                                    <h2 class="strong-text--text font-weight-bold">{{numFormat(homeData.students_count)}}</h2>
                                </div>
                            </div>
                            <div>
                                <!-- <v-btn outlined color="blue darken-1" dark depressed :to="{name: 'my-students'}">
                                    <v-icon size="22" left>mdi-account-group</v-icon>
                                    My Students
                                </v-btn> -->
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="py-1 py-xl-0" cols="12" md="6" lg="6" xl="3">
                <v-card class="soft-shadow px-2 py-1">
                    <v-card-text>
                        <div class="d-flex justify-space-between align-center w-full">

                            <div class="d-flex align-center">
                                <v-avatar size="50">
                                    <v-icon size="28" color="primary" class="bordered">mdi-check-decagram</v-icon>
                                </v-avatar>
                                <div class="ms-4">
                                    <div class="mb-1">Done Classes</div>
                                    <h2 class="strong-text--text font-weight-bold">
                                        <div class="d-flex align-center">
                                            {{homeData.month_done_classes}}
                                            <div class="caption muted--text ms-2 font-weight-normal">
                                                <!-- (All: {{homeData.done_classes}}) -->
                                                ({{monthDoneDurationHours}} Hours)
                                            </div>
                                            <div v-if="appData.enable_app_w" class="caption orange--text text--darken-3 ms-2 font-weight-normal">
                                                <!-- (All: {{homeData.done_classes}}) -->
                                                ({{moneyFormat(homeData.month_salary)}})
                                            </div>
                                        </div>
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <v-btn color="primary" small outlined dark depressed :to="{name: 'salary-report'}">
                                    <v-icon size="22" left>mdi-file-clock</v-icon>
                                    Report
                                </v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="py-1 py-xl-0" cols="12" md="6" lg="6" xl="3">
                <v-card class="soft-shadow px-2 py-1">
                    <v-card-text>
                        
                        <div class="d-flex justify-space-between align-center w-full">

                            <div class="d-flex align-center">
                                <v-avatar size="50">
                                    <v-icon size="28" :color="!homeData.month_delayed_classes || homeData.month_delay_average <= 3 || ((homeData.month_delayed_classes / homeData.month_delay_percent_total_classes) < 0.3) ? 'strong-text' : 'error'" class="bordered">mdi-timer-alert-outline</v-icon>
                                </v-avatar>
                                <div class="ms-4">
                                    <div class="mb-1">Delayed Classes</div>
                                    <!-- <h2 class="strong-text--text font-weight-bold">{{numFormat(homeData.students_count)}}</h2> -->
                                    <div class="strong-text--text">
                                        <b>{{homeData.month_delayed_classes}}</b> <span>{{homeData.month_delayed_classes === 1 ? 'Class' : 'Classes'}}</span>
                                        <span class="details-text ms-1">(%{{!homeData.month_delayed_classes ? 0 : percentFormat((homeData.month_delayed_classes / homeData.month_delay_percent_total_classes) * 100)}})</span>
                                        <v-chip label small outlined class="small-text px-2 ms-2" style="height: 20px">
                                            <b>{{homeData.month_delay_average ? `${parseFloat(homeData.month_delay_average).toFixed(2)} Minutes` : `0 Minutes`}}</b>
                                        </v-chip>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <!-- <v-btn outlined color="blue darken-1" dark depressed :to="{name: 'my-students'}">
                                    <v-icon size="22" left>mdi-account-group</v-icon>
                                    My Students
                                </v-btn> -->
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="py-1 py-xl-0" cols="12" md="6" lg="6" xl="3">
                <v-card class="soft-shadow px-2 py-1">
                    <v-card-text>
                        
                        <div class="d-flex justify-space-between align-center w-full">

                            <div class="d-flex align-center">
                                <v-avatar size="50">
                                    <v-icon size="28" :color="!homeData.last_month_delayed_classes || homeData.last_month_delay_average <= 3 || ((homeData.last_month_delayed_classes / homeData.last_month_delay_percent_total_classes) < 0.3) ? 'strong-text' : 'error'" class="bordered">mdi-timer-alert-outline</v-icon>
                                </v-avatar>
                                <div class="ms-4">
                                    <div class="details-text mb-1">Delayed Classes (last month)</div>
                                    <!-- <h2 class="strong-text--text font-weight-bold">{{numFormat(homeData.students_count)}}</h2> -->
                                    <div class="strong-text--text">
                                        <b>{{homeData.last_month_delayed_classes}}</b> <span>{{homeData.last_month_delayed_classes === 1 ? 'Class' : 'Classes'}}</span>
                                        <span class="details-text ms-1">(%{{!homeData.last_month_delayed_classes ? 0 : percentFormat((homeData.last_month_delayed_classes / homeData.last_month_delay_percent_total_classes) * 100)}})</span>
                                        <v-chip label small outlined class="small-text px-2 ms-2" style="height: 20px">
                                            <b>{{homeData.last_month_delay_average ? `${parseFloat(homeData.last_month_delay_average).toFixed(2)} Minutes` : `0 Minutes`}}</b>
                                        </v-chip>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <!-- <v-btn outlined color="blue darken-1" dark depressed :to="{name: 'my-students'}">
                                    <v-icon size="22" left>mdi-account-group</v-icon>
                                    My Students
                                </v-btn> -->
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-card class="soft-shadow px-2 py-1 mb-3">
            <v-card-title>
                <v-icon left color="teal darken-2">mdi-calendar-clock</v-icon>
                Reschedule Requests
                <span v-if="rescheduleRequests.length" class="teal--text text--darken-2 ms-2">({{rescheduleRequests.length}})</span>                </v-card-title>
            <!-- <v-card-subtitle class="mb-2">Open calendar to view more classes</v-card-subtitle> -->
            <v-card-text>
                <v-alert v-if="!rescheduleRequests.length" text color="blue-grey" type="info">
                    <div>
                        You don't have pending reschedule requests...
                    </div>
                </v-alert>
                <div v-else>
                    <reschedule-requests :requests="rescheduleRequests" />
                </div>
            </v-card-text>
        </v-card>
        <v-card class="soft-shadow px-2 py-1 mb-3">
            <v-card-title>Teaching Courses</v-card-title>
            <v-card-text :class="{'px-1 pb-0': phoneOnly}">
                <v-row v-if="homeData.teaching_courses && homeData.teaching_courses.length" :key="0">
                    <v-col v-for="course in homeData.teaching_courses" :key="course.id" :cols="12" :md="6" :lg="4" :xl="3">
                        <course-card :course="course" style="height: 100%"/>
                    </v-col>
                </v-row>
                <v-alert v-else text color="blue-grey" type="info">
                    No teaching courses to show
                </v-alert>
            </v-card-text>
        </v-card>

        <v-dialog v-model="addClassDialog" max-width="350px">
            <!-- <action-dialog button-color="primary" action="post" url="/classes" success-msg="Class has been added successfully" title="Add Makup Class" subtitle="" button-text="Confirm" :data="{...addClassForm, enrollment_id: enrollment.id}" @reset="teacherHourlyPrice = ''" @sync="makeupClassDone"> -->
            <v-card>
                <v-card-title>
                    <v-icon color="deep-purple accent-4" class="me-2">mdi-shape-square-rounded-plus</v-icon>
                    Add Makeup Class
                </v-card-title>
                <!-- <v-card-subtitle>Add custom class directly</v-card-subtitle> -->
                <v-card-text>
                    <v-alert v-if="addClassForm.date === today" text dense type="info" color="warning" class="details-text">
                        <span class="strong-text--text">As class day is today, you can't choose time before {{now}}</span>
                    </v-alert>

                    <v-select v-model="addClassForm.enrollment_id" prepend-inner-icon="mdi-book-play" :items="enrollmentsItems" label="Class" placeholder="Choose Class"></v-select>
                    <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field hide-details placeholder="Class Day" v-model="addClassForm.date" label="Day" prepend-inner-icon="mdi-calendar" class="mb-5" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker ref="picker" v-model="addClassForm.date" :min="today" @change="saveMenu" color="primary"></v-date-picker>
                    </v-menu>
                    <time-input v-model="addClassForm.start_time" :min="addClassForm.date === today ? now : null" :outlined="false" half-format label="Start Time" placeholder="Class Start Time" class="mb-5" />
                    <!-- <time-input v-model="addClassForm.end_time" :min="addClassForm.date === today ? now : null" :outlined="false" half-format label="End Time (optional)" placeholder="Class End Time" /> -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="muted" :disabled="addingClass" @click="addClassDialog = false">Cancel</v-btn>
                    <v-btn text color="deep-purple accent-4" :disabled="addingClass" :loading="addingClass" @click="addMakeupClass">Add Class</v-btn>
                </v-card-actions>
            </v-card>
            <!-- </action-dialog> -->
        </v-dialog>
    </div>
</template>

<script>
import CourseCard from '@/components/custom/course-card'
import UpcomingClassCard from '@/components/custom/upcoming-class-card'
import RescheduleRequests from './reschedule-requests'
import TimeInput from '@/components/custom/time-input'

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    props: {
        loading: {type: Boolean, default: false}
    },
    data(){
        let d = new Date();
        return {
            today: d.toISOString().substr(0, 10),
            now: d.toTimeString().substr(0, 5),

            addClassDialog: false,
            addingClass: false,
            addClassForm: {
                enrollment_id: '',
                date: '',
                start_time: '',
                // end_time: '',
            },
            
            interval: null,
        }
    },
    components: {
        UpcomingClassCard,
        CourseCard,
        RescheduleRequests,
        TimeInput
    },
    computed: {
        ...GlobalComputed,
        homeData(){
            return this.$store.state.userModule.homeData;
        },
        monthDoneDurationHours(){
            return parseFloat((this.homeData.month_done_duration/60).toFixed(2));
        },
        nextClass(){
            return this.homeData ? this.homeData.next_class : null;
        },
        rescheduleRequests(){
            return this.homeData ? this.homeData.reschedule_requests : null;
        },
        enrollmentsItems(){
            return this.homeData ? this.homeData.enrollments.map(enrollment=>{
                return {text: `${enrollment.student.name} - ${enrollment.course.name}`, value: enrollment.id};
            }) : [];
        },
        slideImages(){
            return this.homeData ? this.homeData.slide_images : [];
        }
    },
    methods: {
        addMakeupClass(){
            if (!this.addingClass){
                this.addingClass = true;
                this.$store.dispatch('addMakeupClass', {data: this.addClassForm}).then(response=>{
                    this.addingClass = false;
                    this.addClassDialog = false;
                    this.showMsg('Class has been added successfully', 'success');
                    Object.assign(this.addClassForm, {
                        date: '',
                        start_time: '',
                    })
                }).catch(e=>{
                    this.addingClass = false;
                    this.showMsg('Error occurred during preforming your action', 'error');
                })
            }
        },
        saveMenu(date){
            this.$refs.menu.save(date);
        },
        percentFormat(value){
            return Math.round(value * 10) / 10;
        },
        ...GlobalMethods
    },
    created(){
        const timeout = this.appData.home_reload_timeout || 10000;
        this.interval = window.setInterval(()=>{
            this.$emit('load');
        }, timeout)
    },
    beforeDestroy(){
        window.clearInterval(this.interval);
    }
}
</script>

<style lang="scss" scoped>
    .header-carousel{
        border-radius: 5px;
        border: 1px solid rgba(#000, 0.1);

        @media(min-width: 768px){
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25) !important;
            border-radius: 13px;
        }
    }
</style>
<style lang="scss">
.v-window__prev, .v-window__next{
    background: none!important;
}
.v-carousel__controls{
    background: none!important;
    .v-carousel__controls__item{
        margin: 0 2px!important;

        .v-icon.mdi-circle{
            font-size: 15px!important;
        }
    }
}
</style>
