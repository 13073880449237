export default {
    state: {
        courses: null,
    },
    mutations: {
        SET_COURSES(state, payload){
            state.courses = payload.data;
        },
        ADD_COURSES(state, payload){
            state.courses.push(...payload.data);
        },
    },
    actions: {
        loadCourses({commit}){
            let url = '/courses';
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    commit('SET_COURSES', {data: response.data.data});
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        getCourse({commit}, payload){
            let url = '/courses/' + payload.id;
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        loadSalaryReport({commit}){
            let url = '/history/salary';
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    commit('SET_COURSES', {data: response.data.data});
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        enrollCourse({commit}, payload){
            let url = '/courses/' + payload.id + '/enroll';
            
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        updateCourseEnrollment({commit}, payload){
            let url = '/courses/' + payload.id + '/enroll';
            
            return new Promise((resolve, reject)=>{
                window.axios.put(url, payload.data).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        finishEnrollment({commit}, payload){
            let url = '/courses/' + payload.id + '/finish';
            
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
    }
}