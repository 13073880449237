<template>
    <div class="page">
        <v-container v-if="homeData">
            <ramadan-widget v-if="appData.ramadan_mode" />
            
            <teacher v-if="isTeacher" :loading="loading" @load="loadData" />
            <student v-else :loading="loading" @load="loadData" />
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import Teacher from './teacher'
import Student from './student'

import RamadanWidget from '@/components/custom/ramadan-widget'

export default {
    name: "Home",
    components: {
        Teacher,
        Student,
        RamadanWidget
    },
    data(){
        return {
            loading: false,
        }
    },
    computed: {
        homeData(){
            return this.$store.state.userModule.homeData;
        },
        ...GlobalComputed
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
    },
    methods: {
        loadData(){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('loadHomeData').then(()=>{
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
    ...GlobalMethods
    },
    mounted(){
        this.loadData();
    }
};
</script>
