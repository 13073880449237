<template>
    <div class="page">
        <v-container>
            <v-card :flat="phoneOnly" class="px-lg-3 py-1" :class="{'transparent': phoneOnly, 'soft-shadow': pcOnly}">
                <v-card-title>Working Hours</v-card-title>
                <v-card-subtitle>Enter your fixed working hours (Please notify us before changing your working hours)</v-card-subtitle>
                <v-card-text>
                    <v-row style="max-width: 1100px">
                        <template v-for="(entry, i) in timeEntries">
                            <v-col cols="5" :key="'1-' + i" :class="{'px-1': phoneOnly}">
                                <v-select v-model="timeEntries[i]['day']" :dense="phoneOnly" :items="daysItems" :disabled="loading || saving" outlined label="Day" placeholder="Choose Class Day" hide-details></v-select>
                            </v-col>
                            <v-col cols="3" :key="'2-' + i" :class="{'px-1': phoneOnly}">
                                <time-input v-model="timeEntries[i]['start_time']" :dense="phoneOnly" :hide-icon="phoneOnly" :disabled="(!timeEntries[i]['day'] && timeEntries[i]['day'] !== 0) || loading || saving" outlined label="From" placeholder="From Time" /> <!-- :max="timeEntries[i]['end_time']" -->
                            </v-col>
                            <v-col cols="3" :key="'3-' + i" :class="{'px-1': phoneOnly}">
                                <time-input v-model="timeEntries[i]['end_time']" :dense="phoneOnly" :hide-icon="phoneOnly" :disabled="!timeEntries[i]['start_time'] || loading || saving" outlined label="To" placeholder="To Time" /> <!-- :min="timeEntries[i]['start_time']" -->
                            </v-col>
                            <v-col cols="1" :key="'4-' + i" class="d-flex justify-start align-center" :class="{'px-0': phoneOnly}">
                                <v-btn v-if="i > 0" color="error" icon @click="removeTimeEntry(i)">
                                    <v-icon size="20">mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </template>
                        <v-col cols="12" class="py-0">
                            <v-btn color="primary" dense small text @click="addTimeEntry">
                                <v-icon left>mdi-plus</v-icon>
                                Additional Time Entry
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn :loading="saving" :disabled="saving || !readyTimeEntries.length" depressed color="primary" class="px-3" @click="saveChanges">
                        <v-icon size="20" left>mdi-content-save-edit</v-icon>
                        <span>Save Changes</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src

import {isEqual} from 'lodash'

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import TimeInput from '@/components/custom/time-input'
export default {
    name: "Course",
    components: {
        TimeInput
    },
    data(){
        return {
            loading: false,
            saving: false,

            timeEntries: [
                {day: '', start_time: '', end_time: ''},
                {day: '', start_time: '', end_time: ''},
                {day: '', start_time: '', end_time: ''},
            ],

            daysItems: [
                {text: 'Saturday', value: 6},
                {text: 'Sunday', value: 0},
                {text: 'Monday', value: 1},
                {text: 'Tuesday', value: 2},
                {text: 'Wednesday', value: 3},
                {text: 'Thursday', value: 4},
                {text: 'Friday', value: 5},
            ],
        }
    },
    computed: {
        readyTimeEntries(){
            return this.timeEntries.filter(time=>!!time.start_time && !!time.end_time)
        },
        ...GlobalComputed
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
        saving(loading){
            this.setLinearLoading(loading);
        },
    },
    methods: {
        loadData(){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('fastGetAction', {url: '/teacher-schedule'}).then(response=>{
                    this.loading = false;
                    const data = response.data.data;
                    this.sync(data);
                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        saveChanges(){
            if (!this.saving){
                this.saving = true;
                this.$store.dispatch('fastPutAction', {url: '/teacher-schedule', data: {schedule: this.readyTimeEntries}}).then(response=>{
                    this.saving = false;
                    const data = response.data.data;
                    this.sync(data);
                    this.showMsg('Changes Saved Successfully', 'success');
                }).catch(error=>{
                    this.saving = false;
                    if (error.response && error.response.status === 422){
                        // coming errors from backend
                        let errors = error.response.data.errors;
                        this.showMsg(errors[Object.keys(errors)[0]][0]);
                    }else{
                        this.showMsg('Error occurred during saving your changes', 'error');
                    }
                })
            }
        },

        validate(){
            const data = this.data;
            if (!data.class_duration || !data.weekly_classes || !data.start_day){
                this.showMsg('Required inputs cannot be empty', 'error');
                return false;
            }else if (data.preferred_days.length < data.weekly_classes){
                this.showMsg(`"Preferred Days" must be equal or more than "Classes/ Week"`, 'error');
                return false;
            }
            return true;
        },
        addTimeEntry(){
            this.timeEntries.push({day: '', start_time: '', end_time: ''});
        },
        removeTimeEntry(i){
            this.timeEntries.splice(i, 1);
        },
        sync(data){
            if (data && data.length){
                this.timeEntries = data;
            }
        },
        ...GlobalMethods
    },
    mounted(){
        this.loadData();
    }
};
</script>
<style lang="scss" scoped>

</style>
