<template>
    <span class="time-now">
        {{timeNow}}
    </span>
</template>

<script>
import GlobalMethods from '@/helpers/global-methods'
import GlobalComputed from '@/helpers/global-computed'

import moment from 'moment-timezone'

export default {
    props: {
        timezone: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            timeNow: '',
            timeNowInterval: null,
        }
    },
    computed: {
        ...GlobalComputed
    },
    methods: {
        setTimeNow(){
            // set time using user's timezone
            const timezone = this.timezone;
            if (timezone){
                const time = moment().tz(timezone).format('hh:mm:ss A');
                this.timeNow = time;
            }
        },
        setTimeInterval(){
            this.setTimeNow();

            this.timeNowInterval = window.setInterval(() => {
                this.setTimeNow();
            }, 1000);
        },
        ...GlobalMethods
    },
    beforeDestroy(){
        if (this.timeNowInterval){
            window.clearInterval(this.timeNowInterval);
        }
    },
    mounted(){

        this.setTimeInterval();
    }
}
</script>

<style lang="scss" scoped>
.time-now{
    letter-spacing: 1px;
    font-weight: 500;
}
</style>
