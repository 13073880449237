export default {
    
    verifyEmailAction({commit}, payload){
        let url = `/verify`;
        return new Promise((resolve, reject)=>{
            window.axios.post(url, {code: payload.code}).then(response=>{
                commit('SET_USER', response.data.user);
                resolve(response);
            }).catch(error=>{
                reject(error);
            })
        });
    },
    sendCodeAgain(){
        let url = `/verify/send`;
        return new Promise((resolve, reject)=>{
            window.axios.post(url).then(response=>{
                resolve(response);
            }).catch(error=>{
                reject(error);
            })
        });
    },
}