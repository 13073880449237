<template>
    <div class="page">
        <v-container>
            <v-card :flat="phoneOnly" class="px-lg-3 py-lg-3" :class="{'soft-shadow': pcOnly, 'transparent': phoneOnly}">

                <!-- Calendar Header -->
                <v-toolbar flat class="mb-lg-4 w-full px-0" height="55">
                    <div v-if="pcOnly">
                        <v-btn v-if="calendarType === 'month'" outlined color="muted" @click="setToday">This Month</v-btn>
                    </div>
                    <v-spacer v-if="pcOnly"></v-spacer>
                    <v-toolbar-title class="d-flex align-center" :class="{'mx-auto': phoneOnly}">
                        <v-btn color="primary" width="35" height="35" icon outlined @click="prev">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <div class="mx-4">{{title}}</div>
                        <v-btn color="primary" width="35" height="35" icon outlined @click="next">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-toolbar-title>
                    <v-spacer v-if="pcOnly"></v-spacer>
                    <div v-if="pcOnly">
                        <v-select outlined hide-details dense :style="`max-width: ${pcOnly ? 150 : 100}px`" v-model="calendarType" :items="[{text: 'Month', value: 'month'}, { text: 'Week', value: 'week'}]"></v-select>
                    </div>
                </v-toolbar>

                <div v-if="phoneOnly" class="mt-1 mb-5 px-5">
                    <v-select outlined hide-details dense v-model="calendarType" :items="[{text: 'Month', value: 'month'}, { text: 'Week', value: 'week'}]"></v-select>
                </div>

                <!-- Calendar -->
                <div style="position: relative">
                    <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="primary" ></v-progress-linear>
                </div>
                <div :style="phoneOnly ? 'overflow-x: scroll;' : ''">
                    <v-calendar
                    ref="calendar"
                    v-model="selected"
                    :weekdays="[6, 0, 1, 2, 3, 4, 5]"
                    :type="calendarType"
                    color="primary"
                    :events="events"
                    event-overlap-mode="stack"
                    :event-overlap-threshold="30"
                    :event-color="getEventColor"
                    @change="getEvents"
                    @click:event="showEvent"
                    :loading="true"
                    style="min-height: 70vh; min-width: 1000px"
                >
                    <template v-slot:event="{ event, timed, eventSummary }">
                        <div class="py-2" v-html="eventSummary()" ></div>
                    </template>
                </v-calendar>
                </div>
                    <div class="calendar-keys d-flex flex-wrap mt-4 mb-2">
                        <v-chip small label dark color="blue-grey darken-2" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color blue-grey darken-2 me-1"></div>
                            <span>Attended</span>
                        </v-chip>
                        <v-chip small label dark color="error darken-4" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color error darken-4 me-1"></div>
                            <span>Missed</span>
                        </v-chip>
                        <v-chip small label dark color="deep-orange" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color deep-orange me-1"></div>
                            <span>Next Classes</span>
                        </v-chip>
                        <v-chip small label dark color="deep-purple" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color deep-purple me-1"></div>
                            <span>Trial</span>
                        </v-chip>
                        <!-- <v-chip small label dark color="blue-grey darken-1" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color blue-grey darken-1 me-1"></div>
                            <span>Scheduled</span>
                        </v-chip> -->
                        <v-chip small label dark color="teal" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color teal darken-1 me-1"></div>
                            <span>Rescheduled</span>
                        </v-chip>
                        <v-chip small label dark color="blue darken-2" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color blue darken-2 me-1"></div>
                            <span>Running</span>
                        </v-chip>
                        <v-chip small label dark color="red darken-1" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color red darken-1 me-1"></div>
                            <span>Canceled</span>
                        </v-chip>
                        <v-chip small label dark color="amber darken-2" outlined class="d-flex me-2 px-2 mb-2 mb-lg-0">
                            <div class="key-color amber darken-2 me-1"></div>
                            <span>Absent</span>
                        </v-chip>
                    </div>
            </v-card>
        </v-container>

        <v-dialog v-if="classDialogEvent" v-model="showClassDialog" :fullscreen="phoneOnly" :fluid="phoneOnly" :transition="phoneOnly ? 'slide-y-transition' : 'dialog-transition'" scrollable max-width="375" overlay-color="#000000">
            <class-card v-if="showClassDialogCard" :class-data="classDialogEvent" @close="showClassDialog = false" />
        </v-dialog>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import ClassCard from '@/components/custom/class-card'

export default {
    name: "Calendar",
    components: {
        ClassCard
    },
    data(){
        return {
            calendarType: 'month',

            title: '',
            selected: '',
            loading: false,
            
            showClassDialog: false,
            showClassDialogCard: false,
            classDialogEvent: null,

            interval: null,
        }
    },
    computed: {
        ...GlobalComputed,
        fetchedCalendar(){
            return this.$store.state.userModule.calendar ;
        },
        events(){
            if (!this.fetchedCalendar) return [];
            return this.fetchedCalendar.map((item, i)=>{
                const color = item.absent ? 'amber darken-1' : (item.canceled ? 'red darken-1' : (item.full_status === 'missed' ? 'error darken-4' : ( item.status === 'expired' ? 'blue-grey' : (item.status === 'running' ? 'blue darken-2' : (item.payment_status === 'trial' ? 'deep-purple' : (item.rescheduled ? 'teal' : (item.id ? 'deep-orange' : 'primary')))))));
                const name = this.isTeacher ? item.enrollment.student.name + ' - ' + item.enrollment.course.name : item.enrollment.course.name;
                return {
                    ...item,

                    name: name,
                    start: new Date(item.start), // start is ms timestamp
                    end: new Date(item.end), // end is ms timestamp

                    color: color,
                    timed: true,
                }
            });
        },
    },
    methods: {
        loadData(){
            if (!this.loading){
                this.loading = true;

                return this.$store.dispatch('fetchCalendar', { startDate: this.selected }).then(response=>{
                    this.loading = false;

                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        showEvent(event){
            this.showClassDialog = true;
            this.classDialogEvent = event.event;
        },
        next(){
            this.$refs.calendar.next();
        },
        prev(){
            this.$refs.calendar.prev();
        },
        setToday(){
            this.selected = '';
        },
        getEvents ({ start, end }) {
            this.title = this.$refs.calendar.title;
            this.loadData();
        },
        getEventColor (event) {
            return event.color
        },
        backButtonHandler(){
            if (this.showClassDialog){
                this.showClassDialog = false;
            }else{
                this.$router.go(-1);
            }
        },
        ...GlobalMethods
    },
    watch: {
        fetchedCalendar(calendar){
            if (this.classDialogEvent){
                const event = calendar.find(item=>item.id === this.classDialogEvent.id);
                if (event){
                    this.classDialogEvent = event;
                }
            }
        },
        showClassDialog(show){
            if (show){
                this.showClassDialogCard = true;
            }else{
                window.setTimeout(()=>{
                    this.showClassDialogCard = false;
                }, 400);
            }
        },
    },
    mounted(){
        if (this.isTeacher){
            this.calendarType = 'week';
        }
        /* if (!this.fetchedCalendar || !this.fetchedCalendar.length){
            this.loadData();
        } */

        if (this.capRunning){
            document.addEventListener('backbuttonclicked', this.backButtonHandler);
        }
    },
    created(){
        const timeout = this.appData.calendar_reload_timeout || 20000;
        this.interval = window.setInterval(()=>{
            this.loadData();
        }, timeout)
    },
    beforeDestroy(){
        window.clearInterval(this.interval);
        if (this.capRunning){
            document.removeEventListener('backbuttonclicked', this.backButtonHandler)
        }
    },
};
</script>

<style lang="scss" scoped>
.key-color{
    width: 12px;
    height: 12px;
    border-radius: 2px;
}
</style>
<style lang="scss">
.v-calendar{
    .v-event-timed{
        /* weekly and daily */
        
        margin-right: auto!important;
        margin-left: auto!important;
        margin-bottom: 3px!important;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        font-size: 12.5px!important;
        font-weight: 500;
        line-height: 1.2rem;
    }
    .v-event{
        height: 32px!important;
        margin-right: auto!important;
        margin-left: auto!important;
        margin-bottom: 3px!important;
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        font-size: 12.5px!important;
        font-weight: 500;
    }
    .v-calendar-weekly__day-label{
        margin: 4px 0;
    }
    .v-calendar-weekly__day.v-present{
        background: #fff3ed!important;
    }
}
</style>