<template>
    <div class="page">
        <v-container>
        <v-card max-width="625" class="settings-card soft-shadow surface-card" :class="{'px-5 py-2 surface': pcOnly}">
            <v-card-title>Security</v-card-title>
            <v-card-subtitle class="details-text grey--text pa-0 ms-4">Security and Privacy settings</v-card-subtitle>
            <v-divider class="mt-4 mx-4" />
            
            <v-list :color="pcOnly ? 'surface' : 'appbg'">
                <v-list-item :disabled="isTeacher" @click="passwordDialog = true">
                    <v-list-item-icon>
                        <v-icon color="strong-text">mdi-lock</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-subtitle>Password</v-list-item-subtitle>
                        <v-list-item-title>**********</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon class="mx-4">
                        <v-icon color="grey lighten-1" size="21">mdi-pencil</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-card>

        <v-dialog v-if="!isTeacher" v-model="passwordDialog" max-width="350" overlay-color="#000" overlay-opacity="0.6">
                <v-card class="px-2 pb-1">
                    <v-card-title>Change Password</v-card-title>
                    <v-card-text class="pt-4">
                        <v-text-field flat hide-details label="New Password" :disabled="editing" type="password" :loading="editing" v-model="password"></v-text-field>
                        <v-text-field flat hide-details class="mt-5" label="Confirm Password" :disabled="editing" type="password" :loading="editing" v-model="confirmPassword"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn large color="grey darken-1" text @click="passwordDialog = false">cancel</v-btn>
                        <v-btn large class="px-3" color="primary darken-1" :loading="editing" :disabled="editing" text @click="changePassword">confirm</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    name: "Home",
    components: {
        
    },
    data(){
        return {
            loading: false,

            password: '',
            confirmPassword: '',
            passwordDialog: '',
            editing: false,
        }
    },
    computed: {
        
        ...GlobalComputed
    },
    watch: {
        
    },
    methods: {
        
        changePassword(){
            this.editing = true;
            let password = this.password,
                confirm = this.confirmPassword;
            if (password){
                if(password === confirm){
                    this.$store.dispatch('changePassword', {password}).then(()=>{
                        this.showMsg('Password hase been changed successfully', 'success');
                        this.editing = false;
                        this.password = '';
                        this.confirmPassword = '';
                        this.passwordDialog = false;

                    }).catch(()=>{
                        this.showMsg('Error occurred during preforming your action');
                        this.editing = false;
                        this.passwordDialog = false;
                    })

                }else{
                    this.showMsg('Passwords do not match');
                    this.editing = false;
                }

            }else{
                this.showMsg('Fill required inputs');
                this.editing = false;
                this.passwordDialog = false;
            }
        },
        ...GlobalMethods
    },
    mounted(){
        
    }
};
</script>
