export default {
    mutations: {
        
    },
    actions: {
        completeRecharge({commit}, payload){
            let url = '/wallet/recharge';
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    commit('UPDATE_USER_CREDITS', response.data.credits)
                    commit('UPDATE_USER_LOAN_CREDITS', response.data.loan_credits)
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        rechargeViaEbmGateway({commit}, payload){
            let url = '/wallet/recharge/ebmgateway';
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        },
        borrowBalance({commit}, payload){
            let url = '/wallet/borrow';
               
            return new Promise((resolve, reject)=>{
                window.axios.post(url, payload.data).then(response=>{
                    commit('UPDATE_USER_CREDITS', response.data.credits)
                    commit('UPDATE_USER_LOAN_CREDITS', response.data.loan_credits)
                    resolve(response);
                }).catch(e=>{
                    reject(e);
                });
            })
        }
    }
}