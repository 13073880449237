<template>
  <v-menu v-model="userMenu" left :offset-y="true" transition="slide-y-transition"> <!-- fade-up -->
    <template v-slot:activator="{}">
        <v-btn icon class="e-link px-2 d-flex align-center" @click.stop="userMenu = !userMenu">
            <!-- <div class="e-link d-flex justify-center align-center">
                <v-icon style="width: 17px">mdi-menu-down</v-icon>
            </div> -->

            <avatar color="text" :size="30" :icon-size="30" :src="avatar"/>
        </v-btn>
    </template>
    <v-list min-width="160" >
        <!-- <v-list-item >
            <div>
                Welcome <span class="primary--text text--darken-2">{{name.split(' ')[0]}}</span>
            </div>
        </v-list-item>
        <v-divider class="mb-3" /> -->
        <v-list-item @click="$router.push({name: 'help'}); userMenu = false">
            <v-list-item-title>Help</v-list-item-title>
        </v-list-item>
        <v-list-item  @click="$router.push({name: 'settings'}); userMenu = false">
            <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logoutDialog = true; userMenu = false">
            <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
    </v-list>

    <logout-dialog v-model="logoutDialog" />
</v-menu>
</template>

<script>
import Avatar from '../../default/avatar'
import LogoutDialog from '../../default/dialogs/logout'

export default {
    props: ['name', 'avatar'],
    data(){
        return {
            userMenu: false,
            logoutDialog: false,
        }
    },
    components: {Avatar, LogoutDialog},
}
</script>

<style>

</style>