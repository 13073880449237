<template>
    <v-app>
        <loading :loading="!appLoadedOnce || loading"></loading>
        
        <template v-if="appLoadedOnce">

            <force-update />
            <announcement />

            <app-menu v-if="isAuthed" v-model="appMenu"/>
            <guest-menu v-else v-model="guestMenu"/>

            <navbar :height="navbarHeight" @menu="showMenu"/>

            <!-- confirm email msg -->
            <div v-if="innerApp && !confirmed" class="fixed-top-bar d-flex align-center justify-space-between justify-md-center pa-0 white--text orange" :style="`top: ${navbarHeight}px`">
                <div class="px-3">Account not confirmed</div>
                <v-btn text large tile color="grey darken-3" class="light--text" @click.stop="openConfirmPage">
                    <span class="white--text">confirm</span>
                </v-btn>
            </div>
            
            <v-main v-if="appLoadedOnce" class="main-container" :class="{'pc': pcOnly, 'is--app': capRunning, 'white': $route.meta.whiteBackground}" :style="'padding-top: ' + navbarHeight">
                <transition :name="transitionName" mode="out-in">
                    <router-view>
                        <!-- to add footer, just put <slot name="footer"> in view and set route meta.footer to true then footer will be auto inserted & transition will be auto changed   -->
                        <!-- <e-footer slot="footer" :fixed="!$route.meta.footer.notFixed" v-if="$route.meta.footer && $route.meta.footer.show" :light="!$route.meta.footer.dark" /> -->
                    </router-view>
                </transition> 
            </v-main>

            <bottom-navigation v-if="capRunning" />

            <!-- footer -->
            <!-- <e-footer light v-if="appLoadedOnce && showFooter" /> -->
            <app-msg :navbar-height="navbarHeight" />
        </template>

    </v-app>
</template>

<script>
import moment from 'moment-timezone'

import AppMsg from "./components/default/app-msg";
import Loading from "./components/default/loading";

import GlobalComputed from './helpers/global-computed'
import GlobalMethods from './helpers/global-methods'
import Colors from './helpers/colors'

import Navbar from './components/main/navbar/navbar'
import BottomNavigation from './components/main/bottom-navigation.vue'
import AppMenu from './components/main/app-menu'
import GuestMenu from './components/main/guest-menu'
// import EFooter from './components/main/e-footer'

import Echo from "laravel-echo"
import Announcement from './components/custom/announcement.vue';

import AppAPI from '@/app-api'
import ForceUpdate from "./components/default/force-update";
 
export default {
    name: "App",
    
    components: {
        ForceUpdate,
        Loading,
        AppMsg,
        Navbar,
        AppMenu,
        BottomNavigation,
        GuestMenu,
        Announcement,
    },

    data: () => ({
        guestMenu: false,
        appMenu: false,
        transitionName: 'fade-up',
    }),
    computed: {
        navbarHeight(){
            return this.pcOnly ? 85 : (this.isAuthed ? 65 : 70);
        },
        showFooter(){
            return this.$route.meta.footer;
        },
        innerApp(){
            return this.isAuthed && this.$route.name !== 'confirm';
        },
        confirmed(){
            if (!this.user || !this.appData.email_verification){
                return true;
            }
            return !!this.user.email_verified_at;
        },
        loading(){
            return this.$store.state.globalModule.loading;
        },
        ...GlobalComputed
    },
    watch: {
        $route(to, from){
            // force email verification
            this.forceEmailConfirm(to.name);
            this.teacherStudentMiddleware(to);

            if (to.name === 'login'){
                if (from.name ==='register'){
                    this.transitionName = 'slide-left';
                }
            }else if (to.name === 'register'){
                if (from.name === 'login'){
                    this.transitionName = 'slide-right';
                }
            }else{
                this.transitionName = this.isAuthed ? 'fast-fade' : 'fade-up';
            }
            
            if (to.meta.footer){
                // to fix footer bug with transition
                this.transitionName = 'fade';
            }
        },
        isAuthed(authed){
            if (!authed){
                this.$router.push({name: 'login'});
            }else{
                // user logged in or registered
                this.saveTimezone();

                this.updateWebsocketsAuth();
            }
        },
    },
    methods: {
        showMenu(){
            if (this.isAuthed){
                this.appMenu = !this.appMenu;
            }else{
                this.guestMenu = !this.guestMenu;
            }
        },
        openConfirmPage(){
            this.$router.push({name: 'confirm'});
        },
        getUserCountry(){
            // get only in production
            if (process.env.NODE_ENV === "production"){
                window.axios.get('https://ip2c.org/s').then(response=>{
                    if (response && response.data){
                        this.$store.commit('SET_USER_COUNTRY', response.data.split(';')[1]);
                    }
                });
            }
        },
        teacherStudentMiddleware(route){
            const isTeacher = this.isTeacher;
            if (route.meta.studentOnly && isTeacher){
                this.$router.push({name: 'not-found'});
            }else if (route.meta.teacherOnly && !isTeacher){
                this.$router.push({name: 'not-found'});
            }
        },
        forceEmailConfirm(route=null){
            if (!route){
                route = this.$route.name;
            }
            const force = process.env.VUE_APP_FORCE_CONFIRMATION;
            if (force){
                if (!this.confirmed){
                    if (route !== 'confirm'){
                        this.openConfirmPage();
                    }
                }
            }
        },
        saveTimezone(){
            const timezone = moment.tz.guess();
            if (!this.user.timezone || timezone !== this.user.timezone){
                if (timezone){
                    this.$store.dispatch('fastPutAction', {url: `/timezone`, data: {timezone}});
                }
            }
        },
        connectToWebsockets(){
            const WsConfig = this.appData.WsConfig;
            if (WsConfig){
                window.Echo = new Echo(WsConfig);
            }
        },
        updateWebsocketsAuth(){
            const token = window.localStorage.getItem('tK'),
                apiUrl = process.env.VUE_APP_API_URL;
            // change auth endpoint
            window.Echo.connector.pusher.config.authEndpoint = `${apiUrl}/broadcasting/auth?api_token=${token}`;
        },
        importPaypalSdkScript(){
            const clientId = this.appData.pp_client;
            if (clientId){
                let script = document.createElement('script')
                script.setAttribute('src', `https://www.paypal.com/sdk/js?client-id=${clientId}&locale=en_US`)
                document.head.appendChild(script)
            }
        },
        afterAuthActions(){
            // this.updateWebsocketsAuth();
            this.forceEmailConfirm();
            this.teacherStudentMiddleware(this.$route);
            this.saveTimezone();

            if (this.appData.ramadan_mode){
                this.applyRamadanMode();
            }
        },
        initApp(){
            const self = this;
            this.connectToWebsockets();
            this.importPaypalSdkScript();
            // window.console.log('App Init');
            // check token is valid or not
            this.$store.dispatch('checkAuth').then(response=>{
                // then set loaded to true
                window.setTimeout(()=>{
                    self.$store.dispatch('appLoaded'); // set app loaded status
                }, 100);

                if (response.authed){
                    self.afterAuthActions();
                }else{
                    this.getUserCountry();
                }
                if (response.redirect){
                    self.$router.push({name: response.redirect});
                }

            }).catch(error=>{
                this.checkErrorStatus(error);
                this.showMsg('(2) Error occurred during loading your data');
            });

        },

        checkErrorStatus(error){
            if (error && error.response && error.response.status){
                // if unauthorized (maybe logout or token changed)
                if (error.response.status === 401){
                    this.logout();
                }
            }
        },
        applyRamadanMode(){
            this.$vuetify.theme.themes.light.primary = Colors.light.ramadanprimary;
        },
        ...GlobalMethods,
    },
    created(){
        if (this.capRunning){
            AppAPI.init(this);
            AppAPI.changeStatusbar(false, true);
        }
    },
    mounted(){
        // first load app global data / settings (admin app data)
        this.$store.dispatch('loadAppData').then(()=>{
              
            // if nor running, init app now
            // we are on browser/web app!
            this.initApp();

        }).catch(()=>{
            this.showMsg('(1) Error occurred during loading your data');
        })
    }
};
</script>

<style lang="scss">
@import 'scss/main';
.main-container{
    background-color: #fafafa;
    &.is--app{
        background-color: #ffffff;
    }
}
</style>