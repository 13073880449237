<template>
    <v-list-item class="px-5 px-lg-6" color="primary" @click="$emit('open')" v-ripple="phoneOnly" :disabled="disabled" :class="{'v-list-item--active': active}" :style="disabled ? 'opacity: 0.8' : ''">
        <v-list-item-avatar size="50">
            <v-img :src="conversation.avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title class="d-flex justify-space-between align-center">
                <span class="strong-text--text">{{conversation.title.length > 20 ? conversation.title.slice(0, 20) + '...' : conversation.title}}</span>

                <div>
                    <small class="small-text" :class="{'muted--text': disabled}">{{timeAgo.replace(' ago', '')}}</small>
                    <v-icon v-if="unread" color="primary" size="15" class="ms-2">mdi-message-text</v-icon>
                </div>
            </v-list-item-title>
            <v-list-item-subtitle>
                <span v-if="conversation.last_message" :class="{'font-weight-semibold text--text': unread}">
                    <span>{{conversation.last_message.sender.name}}: </span>
                    <span v-if="conversation.last_message.type === 'image'" class="ms-1">
                        <v-icon color="indigo" size="20">mdi-image-outline</v-icon>
                    </span>
                    <span v-else>{{conversation.last_message.text}}</span>
                </span>
                <span style="opacity: 0.7" v-else>
                    Say Assalamu Alaikom
                </span>

            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
// @ is an alias to /src
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    props: {
        conversation: {},
        active: {type: Boolean, default: false,},
        disabled: {type: Boolean, default: false,},
    },
    components: {
        
    },
    data(){
        return {
            timeAgo: '',
        };
    },
    computed:{
        ...GlobalComputed,
        unread(){
            return !this.conversation.seen;
        }
    },
    methods:{
        ...GlobalMethods,
        setTimeIntervals() {
            const self = this;
            let time = this.conversation.last_message ? this.conversation.last_message.created_at_timestamp : this.conversation.created_at_timestamp;
            self.timeAgo = self.timeSince(time, true);

            // update time every 1s
            this.intervalid = setInterval(function () {
                time = self.conversation.last_message ? self.conversation.last_message.created_at_timestamp : self.conversation.created_at_timestamp;
                self.timeAgo = self.timeSince(time, true);
            }, 1000);
        },
    },
    mounted(){
        this.setTimeIntervals();
    }
}
</script>

<style lang="scss" scoped>
    
</style>