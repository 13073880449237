<template>
    <div class="page welcome-page">
        
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    name: "Welcome",
    data(){
        return {
            
        }
    },
    components: {
    },
    computed: {
        ...GlobalComputed
    },
    methods: {
        ...GlobalMethods
    },
    created(){
        this.$router.push({name: 'login'});
    }
};
</script>
