<template>
    <div>
        <v-snackbar v-if="!snackMsg.msgBar" bottom v-model="snackMsg.show" :timeout="snackMsg.timeout ? snackMsg.timeout : 2000" class="py-7" :class="{'pr-8': pcOnly}">
            <div class="d-flex justify-space-between align-center ps-3">
                <span>
                    <v-progress-circular v-if="snackMsg.progress" indeterminate :size="25" :width="3"></v-progress-circular>
                    {{snackMsg.text}}
                </span>
                <v-btn class="ms-2" :color="snackMsg.color ? snackMsg.color : 'primary'" text @click="snackMsg.show = false">Hide</v-btn>
            </div>
        </v-snackbar>
        <msg-bar v-if="snackMsg.show && snackMsg.text && snackMsg.msgBar" :margin="navbarHeight" :color="snackMsg.color" :text="snackMsg.text" :timeout="snackMsg.timeout ? snackMsg.timeout : 4000" @close="closeMsg" :under-app-bar="true" />
    </div>

</template>

<script>
import MsgBar from "./msg-bar";

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    props: {
        navbarHeight: {
            default: 80,
            type: Number
        }
    },
    data(){
        return {
            snackMsg: {
                show: false,
                text: '',
            }
        }
    },
    components: {MsgBar},
    watch: {

        globalSnackMsg(msg){
            this.snackMsg = msg;
            
        },
        snackMsg(msg){
            // closeMsg to prevent infinity loop
            if (!msg.show && msg.closeMsg){
                this.$store.dispatch('addSnackMsg', {show: false, text: '', msgBar: true});
            }
        },
    },
    computed: {

        globalSnackMsg(){
            return this.$store.state.globalModule.snackMsg;
        },
        ...GlobalComputed
    },
    methods: {
        ...GlobalMethods,
    }
}
</script>

<style>

</style>