<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Class Course
                        </th>
                        <th class="text-center">
                            Once / Permanent
                        </th>
                        <th class="text-left">
                            From
                        </th>
                        <th class="text-left">
                            To
                        </th>
                        <th class="text-left">
                            Reason
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="request in requests" :key="request.id">
                        <td class="py-3">
                            <div class="d-flex align-center">
                                <router-link class="me-3" :to="{name: 'course', params: {id: request.enrollment.course.id}}">
                                    <v-avatar tile width="60" height="50" style="border-radius: 5px!important" v-ripple="{class: 'black--text'}">
                                        <v-img :src="request.enrollment.course.image_url" />
                                    </v-avatar>
                                </router-link>
                                <div>
                                    <div class="strong-text--text nowrap mb-1 font-weight-medium">{{request.enrollment.course.name}}</div>
                                    <div>
                                        <v-chip class="font-weight-medium" text small label>
                                            {{request.sender.name}}
                                        </v-chip>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <v-chip v-if="request.permanent" color="primary">
                                <v-icon size="20" left class="me-1">mdi-repeat</v-icon>
                                Permanent
                            </v-chip>
                            <v-chip v-else>
                                <v-icon size="20" left class="me-1">mdi-numeric-1-circle-outline</v-icon>
                                Once
                            </v-chip>
                        </td>
                        <td>
                            <v-chip text class="font-weight-medium">
                                <v-icon left>mdi-clock-outline</v-icon>
                                <span>{{request.old_start_formated}}</span>
                            </v-chip>
                        </td>
                        <td>
                            <v-chip color="teal" text class="teal lighten-5 teal--text text--darken-2 font-weight-bold">
                                <v-icon left>mdi-clock-outline</v-icon>
                                <span>{{request.start_formated}}</span>
                            </v-chip>
                        </td>
                        <td>
                            <v-btn v-if="request.reason" @click="openReasonDialog(request)">
                                <v-icon left>mdi-text</v-icon>
                                <span style="text-transform: none">Show Reason</span>
                            </v-btn>
                            <span v-else>No Reason</span>
                        </td>
                        <td style="white-space: nowrap;">
                            <v-btn color="teal" dark class="me-2" @click="showApproveDialog(request)">
                                Approve
                            </v-btn>
                            <v-btn color="red" dark @click="showRejectDialog(request)">
                                Reject
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="reasonDialog" max-width="350">
            <v-card>
                <v-card-title>
                    <v-icon class="me-2">mdi-text</v-icon>
                    <span>Reason</span>
                </v-card-title>
                <v-card-text class="text--text">
                    <div v-if="reasonItem && reasonItem.reason" style="white-space: pre-line;">{{reasonItem.reason}}</div>
                </v-card-text>
                <v-btn absolute top right icon color="muted" @click="reasonDialog = false;">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card>
        </v-dialog>

        <v-dialog v-model="approveDialog" max-width="350">
            <v-card>
                <v-card-title>Confirm Reschedule</v-card-title>
                <v-card-text>
                    Are you sure that you want to approve this request? The calendar will be updated immediately.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" :disabled="approving" @click="approveDialog = false">Cancel</v-btn>
                    <v-btn :loading="approving" :disabled="approving" text color="success" @click="approveRequest(approveRequestId)">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="rejectDialog" max-width="350">
            <v-card>
                <v-card-title>Confirm Rejection</v-card-title>
                <v-card-text>
                    Are you sure that you want to reject this request?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="muted" :disabled="rejecting" @click="rejectDialog = false">Cancel</v-btn>
                    <v-btn :loading="rejecting" :disabled="rejecting" text color="red" @click="rejectRequest(rejectRequestId)">Reject</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
export default {
    props: {
        requests: Array
    },
    data(){
        return {
            approving: false,
            approveRequestId: null,
            approveDialog: false,

            rejecting: false,
            rejectRequestId: null,
            rejectDialog: false,

            reasonDialog: false,
            reasonItem: null,
        }
    },
    computed: {
        ...GlobalComputed,

    },
    methods: {
        ...GlobalMethods,

        showApproveDialog(request){
            this.approveRequestId = request.id;
            this.approveDialog = true;
        },
        showRejectDialog(request){
            this.rejectRequestId = request.id;
            this.rejectDialog = true;
        },
        openReasonDialog(request){
            this.reasonItem = request;
            this.reasonDialog = true;
        },
        approveRequest(id){
            if (!this.approving){
                // this.approveDialog = false;
                this.approving = true;
                return this.$store.dispatch('rescheduleRequestAction', {action: 'approve', id: this.approveRequestId}).then(response=>{
                    this.approving = false;
                    this.showMsg('Reschedule has been approved successfully', 'success');
                    this.$router.push({name: 'calendar'});
                }).catch(error=>{
                    this.approving = false;
                    this.showMsg('Error occurred during preforming your action', 'error');
                })
            }
        },
        rejectRequest(id){
            if (!this.rejecting){
                // this.rejectDialog = false;
                this.rejecting = true;
                return this.$store.dispatch('rescheduleRequestAction', {action: 'reject', id: this.rejectRequestId}).then(response=>{
                    this.rejecting = false;
                    this.showMsg('Reschedule request rejected successfully', 'success');
                    this.$router.push({name: 'calendar'});
                }).catch(error=>{
                    this.rejecting = false;
                    this.showMsg('Error occurred during preforming your action', 'error');
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>

</style>