<template>
    <div class="message-wrapper" :class="{'owned': owned, 'mt-2': showInfo, 'mb-5': showAvatarComputed, 'mb-3': !showAvatarComputed}">

        <v-expand-transition appear>
            <div v-if="(showInfo) && type === 'text'" class="msg-info mb-1 d-flex" :class="{'rtl-dir': owned, 'ltr-dir ml-10': !owned}">
                <span class="msg-time d-flex small-text grey--text">
                    <span class="mx-1">{{msg.sender.name}}</span>
                    <span class="mx-2 ltr-dir">{{dateTimeFormat(msg.created_at_timestamp, true)}}</span>
                </span>
            </div>
        </v-expand-transition>

        <div class="d-flex" :class="{'rtl-dir': owned}"> <!-- 'flex-row-reverse': !owned -->
            <div v-if="!owned" class="msg-avatar">
                <div v-if="showAvatarComputed" v-ripple class="avatar" :style="avatarStyle"></div>
            </div>
            <div class="msg-text" :v-ripple="type === 'text'" :class="{'owned white--text': owned, 'primary': owned, 'full-radius': showAvatarComputed && !owned, 'media-content': type !== 'text', 'video-content': type === 'video'}" @click.stop="showInfo = type === 'text' ? !showInfo : false"> <!-- 'active darken-1': showInfo,  -->
                <v-img v-if="type === 'image'" :src="msg.image_url" class="msg-img text--lighten-1" min-height="75" min-width="200" :class="{'primary--text ml-auto': owned, 'grey--text mr-auto': !owned}" v-ripple="{class: 'black--text'}" @mousedown.stop @touchstart.stop @click.stop="showFullImage" />

                <!-- <div v-else-if="type === 'video'" class="video-wrapper ltr-dir">
                    <vue-plyr>
                        <video :src="msg.video"> </video>
                    </vue-plyr>
                </div> -->
                <div v-else-if="type === 'audio'" class="audio-wrapper grey lighten-3 ltr-dir">
                    <vue-plyr>
                        <audio controls crossorigin playsinline :src="msg.audio"> </audio>
                    </vue-plyr>
                </div>
                <!-- <div v-else-if="type === 'file'">
                    <attachment dense :file="msg.file" />
                </div> -->
                
                <div v-else style="white-space: pre-line; line-height: 1.45rem;" :style="`direction: ${dir}!important;`">
                    <span v-html="textHtml"></span>
                    <span v-if="isLong">...</span>
                    <a v-if="isLong" class="show-more ms-1" :class="owned ? 'white--text' : (showAll ? 'grey--text ' : 'light-blue--text')" @touchstart.stop @mousedown.stop @click.stop="showAll = !showAll">{{showAll ? 'Show Less' : 'Show More'}}</a>
                </div>
                <div v-if="!showAvatarComputed" style="line-height: 1; margin-top: -4px;" :style="`direction: ${dir}!important;`">
                    <span v-if="type === 'text'" class="time-text small-text" :class="{'white--text': owned, 'text--text': !owned}"> <span> {{time}} </span> </span>
                </div>


                <div v-if="type !== 'text'" class="msg-info d-flex mt-2">
                    <span class="msg-time d-flex small-text grey--text" :class="{'mr-auto': !owned, 'ml-auto': owned}">
                        <span class="mx-1">{{msg.sender.name}}</span>
                        <span class="mx-2 ltr-dir">{{time}}</span>
                    </span>
                </div>

                <v-btn type="a" v-if="type==='image' && msg.download" class="save-img-btn grey lighten-3" icon color="grey darken-2" :download="filename" :href="msg.download" @click.stop="downloadImage(msg.download)" @touchstart.stop @mousedown.stop>
                    <v-icon class="grey--text text--darken-1" size="20">mdi-download</v-icon>
                </v-btn>
            </div>
        </div>

        <!-- <div v-if="showAvatarComputed" style="line-height: 1; direction: ltr!important;" class="mt-1">
            <span v-if="type === 'text'" class="time-text small-text grey--text"> <span> {{time}} </span> </span>
        </div> -->
        <img-viewer v-if="type === 'image'" :src="msg.image_url" @inited="inited"/>
        
    </div>
</template>
<script>
import ImgViewer from './img-viewer'
// import Attachment from './attachment'

import Vue from 'vue'
// import VuePlyr from 'vue-plyr'
// // The second argument is optional and sets the default config values for every player.
// Vue.use(VuePlyr, {
//   plyr: {
//     fullscreen: { enabled: true },
//     controls: ['play-large', 'play', 'progress',  'fullscreen'],
//   },
//   emit: ['ended']
// })

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

export default {
    props: ['msg', 'showAvatar', 'owned'],
    data(){
        return {
            time: '',
            showInfo: false,
            showAll: false,
            dir: 'ltr',
        }
    },
    components: {ImgViewer},
    computed: {
        ...GlobalComputed,
        showAvatarComputed(){
            return this.showAvatar && !this.owned;
        },

        avatarStyle(){
            return 'background-image: url(' + this.msg.sender.avatar + ')';
        },
        filename(){
            return 'eb-img-' + Math.floor(Math.random() * Math.floor(10000)) + '.jpg';
        },
        isLong(){
            return this.msg.text && this.msg.text.length > 215 ? true : false; /* && !this.owned */
        },
        text(){
            return this.isLong && !this.showAll ? this.msg.text.slice(0, 216) : this.msg.text;
        },
        textHtml(){
            const safeText = this.escapeHtmlText(this.text);
            return this.urlify(safeText);
        },
        deviceReady(){
            return this.$store.state.deviceReady;
        },  
        type(){
            let type = this.msg.type;
            return this.msg[type + '_url'] ? type : 'text';
        },
    },
    methods: {
        ...GlobalMethods,
        setDir(){
            const rtlChar = /[\u0590-\u083F]|[\u08A0-\u08FF]|[\uFB1D-\uFDFF]|[\uFE70-\uFEFF]/mg;
            const text = this.msg.text;
            if (text){
                if (rtlChar.test(text.charAt(0))){
                    // is rtl 
                    this.dir = 'rtl';
                }else{
                    this.dir = 'ltr';
                }
            }
        },
        showFullImage(){
            //  show full image 
            this.$viewer.show()
        },
        downloadImage(url = this.msg.download){
            
            if (this.deviceReady){
                window.open(url, "_system");
            }
        },
        inited (viewer) {
            this.$viewer = viewer
        },
        setTimeIntervals(time) {
            const self = this;
            self.time = self.timeSince(time, true);

            // update time every 1s
            this.intervalid = setInterval(function () {
                self.time = self.timeSince(time, true);
            }, 1000);
        },
        urlify(text){
            const options = { defaultProtocol: 'https' , target: '_system'};
            return linkifyHtml(text, options);
        },
        escapeHtmlText(text){
            // to prevent XSS attacks
            return text.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
        },
    },
    mounted(){
        this.setTimeIntervals(this.msg.created_at_timestamp);
        this.setDir();
    }
    
}
</script>
<style lang="scss" scoped>
.message-wrapper{
    transition: 0.1s ease all;
    display: flex;
    flex-direction: column;
    width: 100%;
    direction: ltr;
    text-align: left;

    .msg-avatar{
        width: 35px;
        margin-right: 5px;
        
        .avatar{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

        }
    }

    &.owned{
        direction: rtl;
        text-align: right;
    }
    
    .msg-info{
        line-height: 0.5rem;
        .msg-time{
            // position: absolute;
            // top: -18px;
            // right: 0;
            line-height: 0.9rem;
        }
    }
    
    
    .msg-text{
        position: relative;        
        font-size: 13px;
        padding: 4px 12px 4px 14px;
        min-width: 130px;
        max-width: 75%;
        background-color: #efefef;
        border-radius: 6px 17px 17px 6px;

        &.media-content{
            background: unset!important;
            padding: 0!important;
            
            @media (min-width: 960px){
                max-width: 350px;
            }
        }

        .save-img-btn{
            position: absolute;
            top: 40%;
            right: -15px;
            z-index: 2;

            transform: translateY(-50%);

        }

        &.active{
            background-color: #cacaca;
        }
        .time-text{
            opacity: 0.7;
            margin-right: 0;
        }
        
        
        .msg-img{
            border-radius: 15px;
            overflow: hidden;
            background-color: #efefef;
            border: 1px solid #e9e9e9;
            max-width: 85%;
            cursor: pointer!important;
            // box-shadow: 0 3px 0px 0px;
        }


        &.owned{
            padding: 4px 14px 4px 12px;
            border-radius: 17px 6px 6px 17px;

            .time-text{
                margin-right: 6px;
                margin-left: 0;
            }
                
            .save-img-btn{
                left: -15px;
                right: unset;
            }
        }
        &.full-radius{
            border-radius: 17px!important;
        }
        .show-more{
            text-decoration: underline;
            &:active{
                opacity: 0.75;
            }
        }
    }
    
    @media (min-width: 960px){
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important;
        .msg-text{
            align-items: center!important;
            font-size: 0.9rem!important;
            padding: 7px 14px 7px 17px;

            &.owned{
                padding: 7px 17px 7px 14px;
            }
        }
        .msg-avatar{
            width: 40px;
            .avatar{
                width: 40px;
                height: 40px;
            }
        }
    }
}
</style>
<style lang="scss">
    .message-wrapper{
        .msg-text{
            a{
                color: #fa6824!important;
                text-decoration: underline;
                font-weight: 500;
            }
            &.owned{
                a{
                    color: #fff!important;
                }
            }
        }

        div.video-wrapper.ltr-dir, div.audio-wrapper.ltr-dir{
            &, *, div{
                direction: ltr!important;
            }
            border-radius: 10px;
            overflow: hidden;
        }

    }
    .audio-wrapper{
        box-shadow: 0 1px 4px rgba(0,0,0, .15);
    }
</style>