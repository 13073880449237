export default {
    auth: {
        student: [
            {
                icon: 'mdi-home-outline',
                activeIcon: 'mdi-home',
                name: 'home',
                text: 'Dashboard',
                to: {name: 'home'}
            },
            {
                icon: 'mdi-email-outline',
                activeIcon: 'mdi-email',
                name: 'inbox',
                text: 'Inbox',
                to: {name: 'inbox'},
                chip: 'unreadInboxCount',
            },
            {
                icon: 'mdi-calendar-month-outline', /* school */
                activeIcon: 'mdi-calendar-month',
                name: 'calendar',
                text: 'Calendar',
                to: {name: 'calendar'}
            },
            {
                icon: 'mdi-book-play-outline', /* school */ /* play-box-multiple-outline */
                activeIcon: 'mdi-book-play',
                name: 'my-courses',
                text: 'My Courses',
                to: {name: 'my-courses'}
            },
            {
                icon: 'mdi-view-grid-outline', /* school */
                activeIcon: 'mdi-view-grid',
                name: 'courses',
                text: 'Course Catalog',
                to: {name: 'courses'}
            },
            {
                icon: 'mdi-wallet-outline',
                activeIcon: 'mdi-wallet',
                name: 'wallet',
                text: 'Account Wallet',
                to: {name: 'wallet'},
                hide: 'walletDisabled'
            },
            {
                icon: 'mdi-file-document-multiple-outline',
                activeIcon: 'mdi-file-document-multiple',
                name: 'class-reports',
                text: 'Class Reports',
                to: {name: 'class-reports'}
            },
            {
                icon: 'mdi-cash-check',
                name: 'transactions',
                text: 'Transactions History',
                to: {name: 'transactions-history'},
                hide: 'walletDisabled'
            },
            {
                icon: 'mdi-account-supervisor-circle-outline',
                activeIcon: 'mdi-account-supervisor-circle',
                name: 'childs',
                text: 'My Family',
                to: {name: 'childs'},
                hide: 'walletDisabled'
            },
            {
                icon: 'mdi-share-outline',
                activeIcon: 'mdi-share-circle',
                name: 'referral',
                text: 'Refer A Friend',
                to: {name: 'referral'},
                hide: 'walletDisabled'
            },
            /* {
                icon: 'mdi-information-outline',
                activeIcon: 'mdi-information',
                name: 'about',
                text: 'About Us',
                href: process.env.VUE_APP_WELCOME_WEBSITE_URL
            }, */
            {
                icon: 'mdi-help',
                name: 'help',
                text: 'Contact Us',
                to: {name: 'help'}
            },
            {
                icon: 'mdi-cog-outline',
                activeIcon: 'mdi-cog',
                name: 'settings',
                text: 'Settings',
                to: {name: 'settings'}
            }
        ],
        teacher: [
            {
                icon: 'mdi-home-outline',
                activeIcon: 'mdi-home',
                name: 'home',
                text: 'Dashboard',
                to: {name: 'home'}
            },
            {
                icon: 'mdi-email-outline',
                activeIcon: 'mdi-email',
                name: 'inbox',
                text: 'Inbox',
                to: {name: 'inbox'},
                chip: 'unreadInboxCount',
            },
            {
                icon: 'mdi-view-day-outline',
                activeIcon: 'mdi-view-day',
                name: 'coming-classes',
                text: 'Coming Classes',
                to: {name: 'coming-classes'}
            },
            {
                icon: 'mdi-history',
                activeIcon: 'mdi-history',
                name: 'classes-history',
                text: 'Classes History',
                to: {name: 'classes-history'}
            },
            {
                icon: 'mdi-calendar-month-outline', /* school */
                activeIcon: 'mdi-calendar-month',
                name: 'calendar',
                text: 'Calendar',
                to: {name: 'calendar'}
            },
            {
                icon: 'mdi-file-document-multiple-outline',
                activeIcon: 'mdi-file-document-multiple',
                name: 'class-reports',
                text: 'Class Reports',
                to: {name: 'class-reports'}
            },
            {
                icon: 'mdi-clock-outline',
                activeIcon: 'mdi-clock',
                name: 'teacher-schedule',
                text: 'Working Hours',
                to: {name: 'teacher-schedule'}
            },
            {
                icon: 'mdi-file-clock-outline',
                activeIcon: 'mdi-file-clock',
                name: 'salary-report',
                text: 'Salary Report',
                hide: 'walletDisabled',
                to: {name: 'salary-report'}
            },
            {
                icon: 'mdi-file-clock-outline',
                activeIcon: 'mdi-file-clock',
                name: 'previous-salary',
                text: 'Previous Salary',
                to: {name: 'previous-salary'}
            },
            /* {
                icon: 'mdi-account-group-outline',
                activeIcon: 'mdi-account-group',
                name: 'my-students',
                text: 'My Students',
                to: {name: 'my-students'}
            }, */
            {
                icon: 'mdi-help',
                name: 'help',
                text: 'Contact Us',
                to: {name: 'help'}
            },
            {
                icon: 'mdi-cog-outline',
                activeIcon: 'mdi-cog',
                name: 'settings',
                text: 'Settings',
                to: {name: 'settings'}
            }
        ]
    },
    guest: [
        /* {
            icon: 'mdi-home-outline',
            name: 'Home',
            to: {name: 'welcome'}
        }, */
        {
            icon: 'mdi-account-plus-outline',
            name: 'Sign Up',
            to: {name: 'register'}
        },
        {
            icon: 'mdi-login-variant',
            name: 'Sign In',
            to: {name: 'login'}
        },
        {
            icon: 'mdi-information-outline',
            name: 'About Us',
            href: process.env.VUE_APP_WELCOME_WEBSITE_URL
        },
        {
            icon: 'mdi-help',
            name: 'Contact Us',
            to: {name: 'help'}
        }
    ]
}