<template>
    <div class="page">
        <v-container :class="{'px-0': phoneOnly}">
            <v-card :flat="phoneOnly" class="px-lg-3 px-1 py-1 py-lg-3" :class="{'transparent': phoneOnly, 'soft-shadow': pcOnly}" style="min-height: 50vh">
                <div class="d-flex flex-column flex-lg-row justify-space-between px-4 pt-3">
                    <div>
                        <v-card-title style="line-height: normal" class="px-0 pt-0">
                            <v-icon color="primary" size="25" class="me-2">mdi-book-play</v-icon>
                            My Courses
                        </v-card-title>
                        <!-- <v-card-subtitle class="px-0 pt-0">This list contains courses which you enrolled</v-card-subtitle> -->
                    </div>
                    <div>
                        <v-text-field :disabled="!myCourses || !myCourses.length" max-width="300" type="search" :append-icon="searchQuery ? 'mdi-close' : 'mdi-magnify'" @click:append="searchQuery = ''" hide-details outlined label="Search" placeholder="Search in Courses" dense v-model="searchQuery" :style="pcOnly ? 'width: 280px;' : ''"/>
                    </div>
                </div>
                <v-card-text>
                    <v-row v-if="myCourses && myCourses.length" :key="0">
                        <v-col v-for="course in visibleCourses" :key="course.id" :cols="12" :md="6" :lg="4" :xl="3">
                            <course-card enrolled :course="course" style="height: 100%"/>
                        </v-col>
                    </v-row>
                    <div v-else :key="1" class="my-2">
                        <v-alert type="info" color="orange" text>
                            <div class="strong-text--text">You haven't enrolled any courses yet...</div>
                            <v-btn :to="{name: 'courses'}" class="mt-2" depressed color="primary">
                                <span>Course Catalog</span>
                                <v-icon right size="20">mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-alert>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import CourseCard from '@/components/custom/course-card'

export default {
  name: "MyCourses",
  components: {
    CourseCard
  },
  data(){
      return {
          searchQuery: '',
      }
  },
  computed: {
      myCourses(){
          return this.user.my_courses;
      },
      visibleCourses(){
            const query = this.searchQuery.toLowerCase();
            let courses = this.myCourses;
            if (query){
                return courses.filter(course=>course.name.toLowerCase().search(query) !== -1 || course.category_name.toLowerCase().search(query) !== -1)
            }
            return courses;
      },
      ...GlobalComputed
  },
  methods: {
      ...GlobalMethods
  }
};
</script>
