<template>
    <div class="page">
        <v-container>
            <v-card flat v-if="user" class="pa-md-3 mb-4" :class="{'soft-shadow': pcOnly}">
                <v-card-text class="text-center py-lg-10">
                    <h1 class="strong-text--text">Refer a friend, and Earn up to <b class="primary--text">$8</b> balance.</h1>
                    <p class="mx-auto text--text my-4" style="max-width: 600px;">
                        Refer a friend, Earn %10 reward of his first recharge and he will get the same (up to a maximum of $8)
                    </p>
                    
                    <div class="d-flex justify-center my-9">
                        <v-text-field ref="referralLinkInput" readonly :value="referralLink" append-icon="mdi-content-copy" @click:append="copyUrl" outlined hide-details class="e-link ltr-dir" @click="copyUrl" style="max-width: 600px"></v-text-field>
                    </div>
                    <!-- <v-divider class="my-6"></v-divider> -->
                    <div class="d-flex justify-center align-center">
                        <v-btn as="a" :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`" target="_system" fab tile depressed width="50" height="50" color="indigo darken-1" dark class="mx-1 rounded">
                            <v-icon size="28">mdi-facebook</v-icon>
                        </v-btn>
                        <v-btn as="a" :href="`http://twitter.com/share?text=${shareMsgText}&url=${encodeURIComponent(referralLink)}&hashtags=${hashtags}`" target="_system" fab tile depressed width="50" height="50" color="blue" dark class="mx-1 rounded">
                            <v-icon size="25">mdi-twitter</v-icon>
                        </v-btn>
                        <v-btn as="a" :href="`https://wa.me?text=${shareMsg}`" target="_system" fab tile depressed width="50" height="50" color="green" dark class="mx-1 rounded">
                            <v-icon size="30">mdi-whatsapp</v-icon>
                        </v-btn>

                        <v-btn as="a" :href="`fb-messenger://share?link=${shareMsg}`" target="_system" fab tile depressed width="50" height="50" color="blue" dark class="mx-1 rounded">
                            <v-icon size="26">mdi-facebook-messenger</v-icon>
                            <!-- <svg fill="currentColor" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c-6.627 0-12 4.975-12 11.111 0 3.497 1.745 6.616 4.472 8.652v4.237l4.086-2.242c1.09.301 2.246.464 3.442.464 6.627 0 12-4.974 12-11.111 0-6.136-5.373-11.111-12-11.111zm1.193 14.963l-3.056-3.259-5.963 3.259 6.559-6.963 3.13 3.259 5.889-3.259-6.559 6.963z"/></svg> -->
                        </v-btn>

                        <v-btn as="a" :href="`https://telegram.me/share/url?url=${encodeURIComponent(referralLink)}&text=${shareMsgText}`" target="_system" fab tile depressed width="50" height="50" color="light-blue darken-2" dark class="mx-1 rounded">
                            <svg fill="currentColor" width="24px" height="24px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><path id="telegram-1" d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"/></svg>
                        </v-btn>
                        
                        <v-btn fab tile depressed width="50" height="50" color="text" dark class="mx-1 rounded" @click="copyUrl">
                            <v-icon size="26">mdi-content-copy</v-icon>
                            <!-- <svg fill="currentColor" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c-6.627 0-12 4.975-12 11.111 0 3.497 1.745 6.616 4.472 8.652v4.237l4.086-2.242c1.09.301 2.246.464 3.442.464 6.627 0 12-4.974 12-11.111 0-6.136-5.373-11.111-12-11.111zm1.193 14.963l-3.056-3.259-5.963 3.259 6.559-6.963 3.13 3.259 5.889-3.259-6.559 6.963z"/></svg> -->
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'


export default {
    name: "Referral",
    components: {
    },
    data(){
        return {
            
        }
    },
    computed: {
        ...GlobalComputed,
        referralLink(){
            return this.user.referral_link;
        },
        shareMsgText(){
            const msg = `Start your Quran, Arabic, Islamic studies Learning journey now with Riwaq Al Quran institute, get the best teachers, the most affordable fees, availability 24/7\n\nRegister now here to get 10% reward of your 1st recharge.\n`;
            return encodeURIComponent(msg);
        },
        hashtags(){
            return `Riwaq_Al_Azhar`;
        },
        shareMsg(){
            const url = this.referralLink;
            return `${this.shareMsgText}${encodeURIComponent(url)}`;
        },
    },
    methods: {
        ...GlobalMethods,

        copyUrl(){
            const el = this.$refs.referralLinkInput.$el.querySelector('input');
            el.select();
            document.execCommand('copy');
            this.showMsg('Referral Link Copied', 'success', false, false, 2000);
        },
    },
    mounted(){
        if (this.appData && !this.appData.enable_app_w){
            this.$router.push('/not-found');
        }
    }
};
</script>

<style lang="scss">
h1{
    line-height: 1.1;
}
</style>