<template>
    <v-card v-show="!dismiss" class="soft-shadow mb-6 pa-3 pa-lg-4">
        <div class="d-flex flex-no-wrap flex-column flex-lg-row">
            <div style="overflow: hidden; " :style="pcOnly ? 'height: 190px; min-width: 190px' : ''">
                <img :src="`/img/ramadan/${img}`" style="border-radius: 10px;" :style="pcOnly ? 'height: 190px' : 'width: 100%; height: auto;' " />
            </div>
            <div class="ms-lg-10" style="max-width: 900px">
                <v-card-title>
                    <h2 class="font-weight-medium ramadanprimary--text">Ramadan Kareem!</h2>
                </v-card-title>
                <div v-if="phoneOnly && !showMore">
                    <v-btn color="muted" small text @click="showMore = true" block>Show More..</v-btn>
                </div>
                <v-card-text v-if="pcOnly || showMore">
                    <div class="medium-text mt-3" style="line-height: 1.75; white-space: pre-line;">
                        {{appData.ramadan_message}}
                    </div>
                </v-card-text>
            </div>

            <v-btn absolute top left icon dark @click="dismiss = true" style="opacity: 0.6; margin: 5px">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    props: {},
    data(){
        return {
            img: '',
            showMore: false,
            dismiss: false,
        }
    },
    computed: {
        ...GlobalComputed,

    },
    methods: {
        ...GlobalMethods,
        
        randomlySetImage(){
            let imgs = ['1.gif', '5.gif', '1.gif', '3.gif', '5.gif', '6.gif'];
            this.img = imgs[Math.floor(Math.random()*imgs.length)];
        }
    },
    created(){
        this.randomlySetImage();
    }
}
</script>

<style lang="scss" scoped>

</style>