export default {
    state: {

    },
    mutations: {
        UPDATE_NOTIFICATIONS(state, notifications){
            Object.assign(state.user, {notifications});
        },
        SET_NOTIFICATIONS_COUNT(state, count){
            state.user.notifications_count =  count;
        },
    },
    actions: {
        updateNotifications({commit}){
            let url = '/notifications';
            return window.axios.get(url).then(repsonse=>{
                commit('UPDATE_NOTIFICATIONS', repsonse.data.data);
            });
            
        },
        markAllAsRead({commit}){

            let url = '/notifications/read';
            return window.axios.post(url).then(()=>{
                commit('SET_NOTIFICATIONS_COUNT', 0);
            });
            
        },
    }
}