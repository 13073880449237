<template>
    <div class="page">
        <v-container :class="{'px-0': phoneOnly}">
            <!-- <h1 class="mb-2">Courses</h1>
            <p>Hello, welcome to Riwaq Al Quran Portal</p> -->

            <v-card :flat="phoneOnly" class="px-lg-3 px-1 py-1 py-lg-3" :class="{'transparent': phoneOnly, 'soft-shadow': pcOnly}" style="min-height: 50vh">

                <div class="d-flex flex-column flex-lg-row justify-space-between px-4 pt-3">
                    <div>
                        <v-card-title style="line-height: normal" class="px-0 pt-0">
                            <v-icon color="primary" size="28" class="me-2">mdi-view-module</v-icon> <!-- play-box-multiple -->
                            Course Catalog
                        </v-card-title>
                        <!-- <v-card-subtitle class="px-0 pt-0">This list contains courses which you enrolled</v-card-subtitle> -->
                    </div>
                    <div class="d-flex flex-column flex-lg-row mt-2 mt-lg-0">
                        <v-text-field :disabled="loading || !courses || !courses.length" max-width="300" type="search" :append-icon="searchQuery ? 'mdi-close' : 'mdi-magnify'" @click:append="searchQuery = ''" hide-details outlined label="Search" placeholder="Search in Courses" dense v-model="searchQuery" :style="pcOnly ? 'width: 280px;' : ''"/>
                        <v-select :disabled="loading || !courses || !courses.length" :items="categoriesItems" max-width="300" :append-icon="selectedCategory ? 'mdi-close' : 'mdi-view-grid-outline'" @click:append="selectedCategory = ''" hide-details outlined label="Category" placeholder="Filter by category" dense v-model="selectedCategory" :style="pcOnly ? 'width: 280px;' : ''" class="ms-lg-4 mt-4 mt-lg-0" />
                    </div>
                </div>
                <v-card-text>
                    <!-- <transition name="fade" mode="out-in"> -->
                        <v-row v-if="courses && courses.length" :key="0">
                            <v-col v-for="course in visibleCourses" :key="course.id" :cols="12" :md="6" :lg="4" :xl="3">
                                <course-card :course="course" style="height: 100%"/>
                            </v-col>
                        </v-row>
                        <v-row class="courses-loading" v-else-if="loading" :key="1">
                            <v-col v-for="i in 4" :cols="12" :md="6" :lg="4" :xl="3" :key="'loading-' + i" class="pa-2">
                                <v-skeleton-loader type="image, article" class="bordered"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <div v-else-if="courses && courses.length === 0" :key="2" class="my-2">
                            <v-alert type="info" color="muted" text>
                                <strong>No Results</strong>
                                <div>We didn't get any courses to view, try reloading page...</div>
                            </v-alert>
                        </div>
                    <!-- </transition> -->
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

import CourseCard from '@/components/custom/course-card'

export default {
    name: "Courses",
    components: {
        CourseCard
    },
    data(){
        return {
            searchQuery: '',
            loading: false,
            selectedCategory: 'all',
            
            /* mockCourses: [
                {id: 1, name: 'Understanding the Quran', category: 'Quran and Tajweed', description: ' This course is offered online and at the institute by teachers who specialize in the verses of the Quran and are capable of teaching the basic rules of Tasfeer.', image: 'https://i0.wp.com/riwaqalazhar.com/wp-content/uploads/2020/06/understanding.jpg?w=850&ssl=1'},
                {id: 2, name: 'Online Arabic Course', category: 'Arabic Language', description: 'Do you want to become a fluent speaker of the Arabic language and be able to read and write Arabic easily? If you want to learn Arabic to study and understand the Quran, Sunnah, and the whole Islamic and Arabic heritage or you merely want to learn a new and exciting language that will give you access to 26 countries', image: 'https://i2.wp.com/riwaqalazhar.com/wp-content/uploads/2020/06/Arabic-Course.jpg?resize=1024%2C576&ssl=1'},
                {id: 3, name: 'Understanding the Quran', category: 'Quran and Tajweed', description: ' This course is offered online and at the institute by teachers who specialize in the verses of the Quran and are capable of teaching the basic rules of Tasfeer.', image: 'https://i0.wp.com/riwaqalazhar.com/wp-content/uploads/2020/06/understanding.jpg?w=850&ssl=1'},
                {id: 4, name: 'Online Arabic Course', category: 'Arabic Language', description: 'Do you want to become a fluent speaker of the Arabic language and be able to read and write Arabic easily? If you want to learn Arabic to study and understand the Quran, Sunnah, and the whole Islamic and Arabic heritage or you merely want to learn a new and exciting language that will give you access to 26 countries', image: 'https://i2.wp.com/riwaqalazhar.com/wp-content/uploads/2020/06/Arabic-Course.jpg?resize=1024%2C576&ssl=1'},
                {id: 5, name: 'Understanding the Quran', category: 'Quran and Tajweed', description: ' This course is offered online and at the institute by teachers who specialize in the verses of the Quran and are capable of teaching the basic rules of Tasfeer.', image: 'https://i0.wp.com/riwaqalazhar.com/wp-content/uploads/2020/06/understanding.jpg?w=850&ssl=1'},
                {id: 6, name: 'Online Arabic Course', category: 'Arabic Language', description: 'Do you want to become a fluent speaker of the Arabic language and be able to read and write Arabic easily? If you want to learn Arabic to study and understand the Quran, Sunnah, and the whole Islamic and Arabic heritage or you merely want to learn a new and exciting language that will give you access to 26 countries', image: 'https://i2.wp.com/riwaqalazhar.com/wp-content/uploads/2020/06/Arabic-Course.jpg?resize=1024%2C576&ssl=1'},
                {id: 7, name: 'Understanding the Quran', category: 'Quran and Tajweed', description: ' This course is offered online and at the institute by teachers who specialize in the verses of the Quran and are capable of teaching the basic rules of Tasfeer.', image: 'https://i0.wp.com/riwaqalazhar.com/wp-content/uploads/2020/06/understanding.jpg?w=850&ssl=1'},
                {id: 8, name: 'Online Arabic Course', category: 'Arabic Language', description: 'Do you want to become a fluent speaker of the Arabic language and be able to read and write Arabic easily? If you want to learn Arabic to study and understand the Quran, Sunnah, and the whole Islamic and Arabic heritage or you merely want to learn a new and exciting language that will give you access to 26 countries', image: 'https://i2.wp.com/riwaqalazhar.com/wp-content/uploads/2020/06/Arabic-Course.jpg?resize=1024%2C576&ssl=1'},
            ] */
        }
    },
    computed: {
        courses(){
            return this.$store.state.coursesModule.courses;
        },
        visibleCourses(){
            const query = this.searchQuery.toLowerCase();
            let courses = this.courses;
            if (this.selectedCategory && this.selectedCategory !== 'all'){
                courses = courses.filter(course=>course.category_id === this.selectedCategory);
            }

            if (query){
                return courses.filter(course=>course.name.toLowerCase().search(query) !== -1 || course.category_name.toLowerCase().search(query) !== -1)
            }

            return courses;
        },
        categories(){
            let categories = [];
            if (this.courses){
                this.courses.forEach(course=>{
                    // if not exists in categories
                    if (!categories.some(c=>c.id === course.category_id)){
                        categories.push({id: course.category_id, name: course.category_name});
                    }
                });
            }
            return categories;
        },
        categoriesItems(){
            const categoriesItems = this.categories.map(category=>{
                return {text: category.name, value: category.id};
            });
            return [
                {text: 'All', value: 'all'},
                ...categoriesItems
            ]
        },
        ...GlobalComputed
    },
    watch: {
        loading(loading){
            this.setLinearLoading(loading);
        },
    },
    methods: {
        loadData(){
            if (!this.loading){
                this.loading = true;
                this.$store.dispatch('loadCourses').then(()=>{
                    this.loading = false;
                }).catch(()=>{
                    this.loading = false;
                    this.showMsg('Error occurred during fetching data', 'error');
                })
            }
        },
        ...GlobalMethods
    },
    mounted(){
        this.scrollToTop();
        if (!this.courses || this.courses.length === 0){
            this.loadData();
        }
    }
};
</script>

<style lang="scss">
.courses-loading{
    .v-skeleton-loader__image{
        height: 160px;
    }
}
</style>