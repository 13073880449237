<template>
    <v-bottom-navigation v-if="isAuthed && phoneOnly" :height="height" app grow :background-color="surface ? 'white' : color" :dark="!surface" elevation="6" :class="{'navigation-primary-theme': !surface, 'dense': dense}" >
        <template v-for="button in buttons">
            <v-btn v-haptic :color="color" text v-ripple="false" :key="button.value" :value="button.value" :to="button.to || {name: button.value}" :style="`${bottomSafeArea ? 'padding-bottom: 25px' : ''}`">
                <span v-if="!dense" class="label">{{button.text}}</span>
                <v-badge dot :value="!!button.chip && self[button.chip]" :content="self[button.chip]" overlap :color="button.chipColor ? button.chipColor : 'ebadge'" right class="white--text">
                    <v-icon :style="surface || $route.name === button.value ? '' : 'opacity: 0.65'" :color="surface ? ($route.name === button.value ? color : 'text') : 'white'">{{$route.name === button.value || button.filled ? button.icon : `${button.icon}-outline`}}</v-icon>
                </v-badge>
            </v-btn>
        </template>
    </v-bottom-navigation>
</template>

<script>

import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'

export default {
    data(){
        return {
            self: this,
            value: 'home',
        }
    },
    computed: {
        ...GlobalComputed,
        color(){
            return this.dense ? 'secondary' : 'primary';
        },
        dense(){
            return this.platform === 'web';
        },
        height(){
            return this.dense ? 50 : (this.bottomSafeArea ? 90 : 60);
        },
        bottomSafeArea(){
            return this.platform === 'ios';
        },
        studentButtons(){
            let items = [
                {value: 'home', text: 'Home', icon: 'mdi-home'},
                {value: 'my-courses', text: 'My Courses', icon: 'mdi-book-play'},
                {value: 'calendar', text: 'Calendar', icon: 'mdi-calendar-month'},
                {value: 'wallet', text: 'Wallet', icon: 'mdi-wallet', hide:  !this.appData.enable_app_w},
                {value: 'inbox', text: 'Inbox', icon: 'mdi-email', chip: 'unreadInboxCount'},
                {value: 'help', text: 'Help', icon: 'mdi-help-circle'},
            ];
            return items
        },
        teacherButtons(){
            let items = [
                {value: 'home', text: 'Home', icon: 'mdi-home'},
                {value: 'coming-classes', text: 'Classes', icon: 'mdi-view-day'},
                {value: 'calendar', text: 'Calendar', icon: 'mdi-calendar-month'},
                {value: 'class-reports', text: 'Reports', icon: 'mdi-file-document-multiple'},
                {value: 'inbox', text: 'Inbox', icon: 'mdi-email', chip: 'unreadInboxCount'},
                {value: 'salary-report', text: 'Salary', icon: 'mdi-file-clock', hide:  !this.appData.enable_app_w},
                // {value: 'help', text: 'Help', icon: 'mdi-help-circle'},
            ];
            return items
        },
        buttons(){
            let buttons = this.isTeacher ? this.teacherButtons : this.studentButtons;
            const self = this;
            return buttons.filter(button=>{
                if (button.hide){
                    return false;
                }
                return true;
            })
        },
        surface(){
            // return this.isDark || !this.webPhone;
            return true; // for now, no primaryTheme
        },
    },
    methods: {
        ...GlobalMethods
    },
    mounted(){
        this.value = this.$route.name;
    }
}
</script>

<style lang="scss" scoped>
    .v-bottom-navigation{
        transition: all 0.2s ease-in-out, color 0s, background 0s, border-color 0s;
        &.theme--dark{
            border-top: 1px solid rgba(#fff, 0.1)!important;
        }
        &.dense{
            .v-btn.v-btn--active{
                border-top: 2px solid;
            }
        }
        .v-btn{
            // opacity: 0.75!important;
            &:hover:before{
                opacity: 0!important;
            }
            &.v-btn--active {
                &::before, &:focus::before, &:hover::before{
                    // opacity: 0.07!important;
                    opacity: 0!important;
                }
            }
            // &:active, &:hover, &.v-btn--active{
            //     opacity: 1!important;
            // }
            min-width: 40px!important;
            .label{
                font-size: 9px;
                margin-top: 6px;
            }
            &.v-btn--active{
                // border-bottom: 3px solid!important;
            }
        }

        &.navigation-primary-theme{
            box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px -4px 8px 1px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)!important;
        }
    }
</style>