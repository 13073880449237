export default {
    state: {
        childs: null,
    },
    mutations: {
        SET_CHILDS(state, payload){
            state.childs = payload.data;
        },
        ADD_CHILDS(state, payload){
            state.childs.push(...payload.data);
        },
    },
    actions: {
        loadChilds({commit}){
            let url = '/childs';
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    commit('SET_CHILDS', {data: response.data.data});
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        getChild({commit}, payload){
            let url = '/childs/' + payload.id;
            
            return new Promise((resolve, reject)=>{
                window.axios.get(url).then(response=>{
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
        newChild({commit}, payload){
            let url = '/childs';
            
            return window.axios.post(url, payload).then(response=>{
                commit('SET_CHILDS', {data: response.data.data});
            }).catch(error=>{
                
            });
        },
        transferCreditsToChild({commit}, payload){
            let url = '/childs/transfer';
            
            return new Promise((resolve, reject)=>{
                window.axios.post(url, {amount: payload.amount, child_id: payload.child_id}).then(response=>{
                    const data = response.data;
                    commit('UPDATE_USER_CREDITS', data.parent_credits);
                    commit('SET_CHILDS', {data: data.childs});
                    resolve(response);
                }).catch(error=>{
                    reject(error);
                })
            });
        },
    }
}