export default {

    changePassword({commit}, payload){
        let url = `/password`;
        return new Promise((resolve, reject)=>{
            window.axios.post(url, {password: payload.password}).then(response=>{
                commit('SET_USER', response.data.user);
                
                let token = response.data.token;
                window.localStorage.setItem('tK', token);
                window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

                resolve();
            }).catch(error=>{
                reject(error);
            })
        });
    },
}