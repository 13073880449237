<template>
    <div class="infinity-wrapper">
        <slot></slot>
    </div>
</template>
<script>
export default {
    data(){
        return {
            reachedBottom: null,
        }
    },
    methods: {
        checkBottom() {
            const visible = document.documentElement.clientHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const scrollY = window.scrollY;
            const bottomOfPage = visible + scrollY >= (pageHeight - 100);
            return bottomOfPage || (pageHeight - 100) < visible
        },
    },
    watch: {
        reachedBottom(reached){
            if (reached){
                this.$emit('load');
            }
        }
    },
    created() {
        window.addEventListener('scroll', () => {
            this.reachedBottom = this.checkBottom()
        });
    }
}
</script>
<style lang="scss" scoped>
    .infinity-wrapper{
        height: 100%;
        max-height: 100%;
        overflow-x: visible!important;
        // overflow-y: scroll;
    }
</style>