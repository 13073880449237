import Welcome from "../views/Welcome.vue";
import Home from "../views/Home/Home.vue";
import Inbox from "../views/Inbox/Inbox.vue";
import Calendar from "../views/Calendar.vue";
import ComingClasses from "../views/ComingClasses.vue";
import ClassReports from "../views/ClassReports.vue";
import ClassesHistory from "../views/ClassesHistory.vue";
import Courses from "../views/Courses.vue";
import Course from "../views/Course.vue";
import Settings from "../views/Settings.vue";

import MyCourses from "../views/MyCourses.vue";
import Wallet from "../views/Wallet.vue";
import Referral from "../views/Referral.vue";
import Childs from "../views/Childs.vue";

import TransactionsHistory from "../views/TransactionsHistory.vue";
import SalaryReport from "../views/Salary/SalaryReport.vue";
import PreviousSalary from "../views/Salary/PreviousSalary.vue";

import TeacherSchedule from "../views/TeacherSchedule.vue";
// import MyStudents from "../views/MyStudents.vue";

const routes = [
    {
        path: '/',
        name: 'welcome',
        component: Welcome,
        meta :{
            requiresAuth: false,
            requiresGuest: true,
            footer: {
                show: true,
                dark: true,
                notFixed: true,
            },
            whiteBackground: true,
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta :{
            title: 'Dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/courses',
        name: 'courses',
        component: Courses,
        meta :{
            title: 'Courses',
            requiresAuth: true,
            studentOnly: true,
        }
    },
    {
        path: '/courses/:id',
        name: 'course',
        component: Course,
        meta :{
            title: 'Course Page',
            requiresAuth: true,
            studentOnly: true,
        }
    },
    {
        path: '/inbox',
        name: 'inbox',
        component: Inbox,
        meta :{
            title: 'Inbox',
            requiresAuth: true
        }
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: Calendar,
        meta :{
            title: 'Calendar',
            requiresAuth: true
        }
    },
    {
        path: '/classes/coming',
        name: 'coming-classes',
        component: ComingClasses,
        meta :{
            title: 'Coming Classes',
            teacherOnly: true,
        }
    },
    {
        path: '/class-reports',
        name: 'class-reports',
        component: ClassReports,
        meta :{
            title: 'Class Reports',
            // teacherOnly: true,
        }
    },
    {
        path: '/classes/history',
        name: 'classes-history',
        component: ClassesHistory,
        meta :{
            title: 'Classes History',
            teacherOnly: true,
        }
    },
    {
        path: '/my-schedule',
        name: 'teacher-schedule',
        component: TeacherSchedule,
        meta :{
            title: 'My Schedule',
            requiresAuth: true,
            teacherOnly: true,
        }
    },
    /* {
        path: '/my-students',
        name: 'my-students',
        component: MyStudents,
        meta :{
            title: 'My Students',
            requiresAuth: true,
            teacherOnly: true,
        }
    }, */
    {
        path: '/my-courses',
        name: 'my-courses',
        component: MyCourses,
        meta :{
            title: 'My Courses',
            requiresAuth: true,
            studentOnly: true,
        }
    },
    {
        path: '/wallet',
        name: 'wallet',
        component: Wallet,
        meta :{
            title: 'Wallet',
            requiresAuth: true,
            studentOnly: true,
        }
    },
    {
        path: '/referral',
        name: 'referral',
        component: Referral,
        meta :{
            title: 'Referral',
            requiresAuth: true,
            studentOnly: true,
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta :{
            title: 'Settings',
            requiresAuth: true,
        }
    },
    {
        path: '/childs',
        name: 'childs',
        component: Childs,
        meta :{
            title: 'My Children',
            requiresAuth: true,
            studentOnly: true,
        }
    },
    {
        path: '/transactions-history',
        name: 'transactions-history',
        component: TransactionsHistory,
        meta :{
            title: 'Transactions History',
            requiresAuth: true,
            studentOnly: true,
        }
    },
    {
        path: '/salary-report',
        name: 'salary-report',
        component: SalaryReport,
        meta :{
            title: 'Salary Report',
            requiresAuth: true,
            teacherOnly: true,
        }
    },
    {
        path: '/previous-salary',
        name: 'previous-salary',
        component: PreviousSalary,
        meta :{
            title: 'Previous Salary Report',
            requiresAuth: true,
            teacherOnly: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import("../views/Login.vue"),
        meta :{
            title: 'Sign In',
            requiresAuth: false,
            requiresGuest: true,
        }
    },
    {
        path: '/login/teachers',
        name: 'login-teachers',
        component: () => import("../views/Login.vue"),
        meta :{
            title: 'Teachers Sign In',
            requiresAuth: false,
            requiresGuest: true,
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import("../views/Register.vue"),
        meta :{
            title: 'Sign Up',
            requiresAuth: false,
            requiresGuest: true,
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import( '../views/ResetPassword.vue'),
        meta :{
        title: 'Reset Password',
        requiresAuth: false,
        requiresGuest: true,
        }
    },
    {
    path: '/confirm',
    name: 'confirm',
    component: () => import( '../views/Confirm.vue'),
    meta :{
        title: 'Email Confirmation',
        requiresAuth: true,
    }
    },
    {
        path: "/help",
        name: "help",
        component: () => import("../views/Help.vue"),
        meta: {
            title: 'Contact Us',
        }
    },
    {
        path: '*',
        name: 'not-found',
        meta: {
            title: 'Page not found',
            backIcon: true,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
    }
  ];


  export default routes;